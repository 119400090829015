/**
 * Round to specified precisions
 *
 * @param   {number} value     val to round
 * @param   {number} precision number of decimals to round to
 * @returns {number}           rounded number
 */
 export function round(value, precision){
    const mult = 10 ** precision
    return Math.round(mult * value) / mult
}

/**
 * Create a range between two numbers with a set resolution
 *
 * @param   {number[]} range      start and end value for the range  
 * @param   {number}   resolution increment for the range
 * @param   {!number}  dec        optional number of decimals to round to
 * @returns {number[]}
 */
export function mapRange(range, resolution, dec = null){
    const start = range[0]
    const end = range[range.length - 1]
    const x = []
    // Offset end by half a step to handle floating point error
    for (let i = start; i <= end + resolution/2; i += resolution){ x.push(dec ? round(i, dec) : i) }
    return x
}

/**
 * Map a function to a new range or set of intervals using linear interp
 *
 * @param   {number[]}         xs              array of x values 
 * @param   {number[]}         ys              array of y values
 * @param   {number}           resolution      to map the function to
 * @param   {object}           options
 * @param   {number}           [options.start] start value of new range
 * @param   {number}           [options.end]   end value of new range
 * @param   {number}           [options.dec]   number of decimals to round x values to
 * @returns {{ 
 *      x: number[], 
 *      y: number[] 
 * }}
 */
export function linearInterp(xs, ys, resolution, options = {}){
    const { start, end, dec } = options
    const rangeStart = start ?? xs[0]
    const rangeEnd = end ?? xs[Math.min(xs.length -1, ys.length - 1)]

    const x = []
    const y = []
    let n = 0
    let m = (ys[1] - ys[0]) / (xs[1] - xs[0]) // Initial slope

    // Go until slightly past the range end to handle FPE
    for (let i = rangeStart; i < rangeEnd + resolution/2; i += resolution){        
        /* eslint no-inner-declarations: off */
        // If the x < xs[n] we interpolate from xs[n] to xs[n + 1]
        // If x > xs[n] we increment n and try again
        function interp(x_i){
            if(x_i < xs[n] + resolution/2 || !xs[n + 2] || !ys[n+2]){
                return m * (x_i - xs[n]) + ys[n]
            } else {
                n += 1
                m = (ys[n + 1] - ys[n]) / (xs[n + 1] - xs[n]) // New slope
                return interp(x_i)
            }
        } 

        const x_i = dec ? round(i, dec) : i
        x.push(x_i) // Push the x value
        y.push(interp(x_i))
    }
    return { x, y }
}

/**
 * Map a curve to a new range at a target resolution
 * Credit to https://github.com/morganherlocker/cubic-spline
 *
 * @param   {number[]}         xs              array of numbers
 * @param   {number[]}         ys              array of numbers
 * @param   {number}           resolution      to map curve over
 * @param   {object}           options
 * @param   {number}           [options.start] value of mapped curve; can be different than original range
 * @param   {number}           [options.end]   value of mapped curve; can be different than original range
 * @param   {number}           [options.dec]   number of decimals to round x values to
 * @returns {{ 
 *      x: number[], 
 *      y: number[] 
 * }}
 */
export function splineInterp(xs, ys, resolution, options = {}){
    const { start, end, dec } = options
    
    // Choose shortest of two arrays
    const endIdx = Math.min(xs.length -1, ys.length - 1)

    // Makes sure range endpoints fall within range of xs after resolution rounding
    const rangeStart = start ?? Math.ceil(xs[0]/resolution)*resolution
    const rangeEnd = end ?? Math.floor(xs[endIdx]/resolution)*resolution
    
    // Handle different length arrays
    const minLength = Math.min(xs.length, ys.length)
    const spline = new Spline(xs.slice(0, minLength), ys.slice(0, minLength))
    
    const x = mapRange([rangeStart, rangeEnd], resolution, dec)    
    const y = x.map((i) => spline.at(i))
    
    return { x, y }
}

export class Spline{
    constructor(xs, ys){
        this.xs = xs
        this.ys = ys
        this.ks = this.getNaturalKs(new Float64Array(this.xs.length))
    }

    getNaturalKs(ks){
        const n = this.xs.length - 1
        const A = zerosMat(n + 1, n + 2)

        for (
            let i = 1;
            i < n;
            i++ // rows
        ){
            A[i][i - 1] = 1 / (this.xs[i] - this.xs[i - 1])
            A[i][i] = 2 * (1 / (this.xs[i] - this.xs[i - 1]) + 1 / (this.xs[i + 1] - this.xs[i]))
            A[i][i + 1] = 1 / (this.xs[i + 1] - this.xs[i])
            A[i][n + 1] =
                3 *
                ((this.ys[i] - this.ys[i - 1]) /
                    ((this.xs[i] - this.xs[i - 1]) * (this.xs[i] - this.xs[i - 1])) +
                    (this.ys[i + 1] - this.ys[i]) /
                        ((this.xs[i + 1] - this.xs[i]) * (this.xs[i + 1] - this.xs[i])))
        }

        A[0][0] = 2 / (this.xs[1] - this.xs[0])
        A[0][1] = 1 / (this.xs[1] - this.xs[0])
        A[0][n + 1] =
            (3 * (this.ys[1] - this.ys[0])) / ((this.xs[1] - this.xs[0]) * (this.xs[1] - this.xs[0]))

        A[n][n - 1] = 1 / (this.xs[n] - this.xs[n - 1])
        A[n][n] = 2 / (this.xs[n] - this.xs[n - 1])
        A[n][n + 1] =
            (3 * (this.ys[n] - this.ys[n - 1])) /
            ((this.xs[n] - this.xs[n - 1]) * (this.xs[n] - this.xs[n - 1]))

        return solve(A, ks)
    }

    // inspired by https://stackoverflow.com/a/40850313/4417327
    getIndexBefore(target){
        let low = 0
        let high = this.xs.length
        let mid = 0
        while (low < high){
            mid = Math.floor((low + high) / 2)
            if (this.xs[mid] < target && mid !== low){
                low = mid
            } else if (this.xs[mid] >= target && mid !== high){
                high = mid
            } else {
                high = low
            }
        }
        return low + 1
    }

    at(x){
        let i = this.getIndexBefore(x)
        const t = (x - this.xs[i - 1]) / (this.xs[i] - this.xs[i - 1])
        const a = this.ks[i - 1] * (this.xs[i] - this.xs[i - 1]) - (this.ys[i] - this.ys[i - 1])
        const b = -this.ks[i] * (this.xs[i] - this.xs[i - 1]) + (this.ys[i] - this.ys[i - 1])
        const q = (1 - t) * this.ys[i - 1] + t * this.ys[i] + t * (1 - t) * (a * (1 - t) + b * t)
        return q
    }
}

function solve(A, ks){
    const m = A.length
    let h = 0
    let k = 0
    while (h < m && k <= m){
        let i_max = 0
        let max = -Infinity
        for (let i = h; i < m; i++){
            const v = Math.abs(A[i][k])
            if (v > max){
                i_max = i
                max = v
            }
        }

        if (A[i_max][k] === 0){
            k++
        } else {
            swapRows(A, h, i_max)
            for (let i = h + 1; i < m; i++){
                const f = A[i][k] / A[h][k]
                A[i][k] = 0
                for (let j = k + 1; j <= m; j++) A[i][j] -= A[h][j] * f
            }
            h++
            k++
        }
    }

    for (
        let i = m - 1;
        i >= 0;
        i-- // rows = columns
    ){
        var v = 0
        if (A[i][i]){
            v = A[i][m] / A[i][i]
        }
        ks[i] = v
        for (
            let j = i - 1;
            j >= 0;
            j-- // rows
        ){
            A[j][m] -= A[j][i] * v
            A[j][i] = 0
        }
    }
    return ks
}

function zerosMat(r, c){
    const A = []
    for (let i = 0; i < r; i++) A.push(new Float64Array(c))
    return A
}

function swapRows(m, k, l){
    let p = m[k]
    m[k] = m[l]
    m[l] = p
}

/**
 * Create a polyfit for a 3 point domain
 *
 * @param   {Point} p1 first point 
 * @param   {Point} p2 second point 
 * @param   {Point} p2 third point 
 * @returns {poly}     polyfit function for the 3 points
 */
export function poly3([x1, y1], [x2, y2], [x3, y3]){
    const a = x1 * (y3 - y2) + x2 * (y1-y3) + x3 * (y2-y1) * (x1 - x2) * (x1 - x3) * (x2 - x3)
    const b = y2 - y1 * x2 - a * (x1 + x2)
    const c = y1 - a * x1**2 - b * x1

    /**
     * s
     *
     * @param   {number} x point to fit
     * @returns {number}   value at x
     */
    const poly = (x) => a * x**2 + b * x + c
    return poly
}
