<template>
	<div class="create-spring">
		<!--  -->
		<!--  -->
		<!--Display Body -->
		<div v-show="!showPlot" key="'create-spring-plot-1" ref="tablediv" class="create-spring__table flex-column">
			<!-- Table -->
			<DisplayTable :bodyList="dataList" :header="headerRow" :setup="setup" @click="headerClick" :activeHeaderItem="activeHeader" minHeight="42rem" />
			<!-- Header UI Panel -->
			<button ref="headerpanel" class="create-spring__header-panel" :style="{left : headerPanelLeft}">
				<div class="multi-button-vert-cont margin--full">
					<TextInputMD name="Column Name" margin="0 0 .5rem 0" :value="headerRow[activeHeader]" @input="formatColName" />
					<ButtonStandard name="Delete Column" :flex="true" outline="true" @click="deleteColumn" />
					<ButtonStandard name="Shift Left" :flex="true" @click="shiftColumn(false)" />
					<ButtonStandard name="Shift Right" :flex="true" @click="shiftColumn(true)" />
				</div>
			</button>
		</div>
		<div v-show="showPlot" key="'create-spring-plot-2" class="create-spring__plot-cont">
			<canvas ref="maincanvas"></canvas>
		</div>
		<span ref="fontsize" :style="{fontSize: '1rem', height: 0, display: 'none'}"></span>
		<!--  -->
		<!--  -->
		<!--  -->
		<!-- Control Panel First Row -->
		<div class="flex-row padding--top-bottom flex-space">
			<!--  -->
			<!--  -->
			<!-- CSV File Loading -->
			<div class="flex-row">
				<label class="file-label" for="create-spring__file-input">
					{{ filename }}
					<input @input="setFilename" ref="file" id="create-spring__file-input" class="file-input" type="file" accept=".csv">
				</label>
				<ButtonStandard name="Reset CSV" @click="loadCSV" :left="true" :clear="true" />
			</div>
			<!--  -->
			<!--  -->
			<!-- Right Buttons -->
			<div class="multi-button-cont">
				<!--  -->
				<!--  -->
				<!-- Process button with log output display -->
				<ButtonStandard name="Process" @click="processData" :btnstyle="{width: '8.75rem'}" />
				<button ref="processlog" class="create-spring__interp-panel__cont" style="width: 3rem; margin-left: -.25rem">
					<span class="create-spring__interp-panel__arrow">
						<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
							<path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" />
							<path d="M0 0h48v48h-48z" fill="none" /></svg>
					</span>
					<div class="create-spring__interp-panel">
						<div class="margin--full">
							<p v-for="(msg,index) in messages" :key="'springlogoutput'+index" class="create-spring__notification" :class="{'error' : !msg[1]}">{{ msg[0] }}</p>
						</div>
					</div>
				</button>
				<!--  -->
				<!--  -->
				<!-- Data Modification Panel -->
				<button ref="modpanel" class="create-spring__interp-panel__cont">
					<span class="create-spring__interp-panel__label">Modify Data</span>
					<div class="create-spring__interp-panel">
						<!-- Interpolation Inputs -->
						<div class="flex-row margin--full">
							<ListSelector :itemList="headerRow" margin="1.5rem .5rem 0 0" :flex="true" label="Lower Ref. Column" v-model="interpC1" :error="lowerRefError" @submit="$refs.modpanel.focus()" />
							<ListSelector :itemList="headerRow" margin="1.5rem 0 0 .5rem" :flex="true" label="Upper Ref. Column" v-model="interpC2" :error="upperRefError" @submit="$refs.modpanel.focus()" />
						</div>
						<div class=" margin--full">
							<TextInputMD name="Interpolation Percent" v-model="interpPercent" :error="interpPercentError" />
							<TextInputMD name="New Column Name" v-model="newColumnName" />
							<ButtonStandard name="Interpolate New Column" @click="interpolate" :flex="true" />
						</div>
						<!-- Option to convert Units -->
						<div v-if="canConvert" class="margin--full border--top padding--top">
							<ButtonStandard name="Convert Units from In/lb" @click="convertUnits" :flex="true" :clear="true" />
						</div>
					</div>
				</button>
			</div>
		</div>
		<!--  -->
		<!--  -->
		<!-- Second Button Row -->
		<div class="flex-row flex-space padding--top-bottom border--top">
			<div class="flex-1"></div>
			<!-- Plot Swap Button -->
			<ButtonStandard name="Show Plot" @click="showPlot = !showPlot" :btnstyle="plotBtn" :outline="true">
				<div style="margin: auto">{{ showPlot ? 'Show Table' : 'Show Plot'}}</div>
			</ButtonStandard>
			<!--  -->
			<!--  -->
			<!-- Save Button Panel -->
			<button ref="savepanel" class="create-spring__interp-panel__cont">
				<span class="create-spring__interp-panel__label">Save</span>
				<div class="create-spring__interp-panel padding" @focusout="saveMessage = ''">
					<RadioSelector v-if="claims.team_id || claims.app_admin" :optionArray="databaseOptions" label="Database:" @click="savingDatabase = $event" />
					<AutocompleteList inputLabel="Brand" :itemList="brandList" v-model="brand" :error="brandErr" @submit="$refs.savepanel.focus()" :hideDefault="true" />
					<TextInputMD name="Model" v-model="modelName" :error="modelNameErr" />
					<TextInputMD name="Model Year" v-model="modelYear" :error="modelYearErr" />
					<div class="flex-row">
						<TextInputMD name="Length" margin="0rem .5rem 1.5rem .2rem" v-model="shockLength" :error="shockLengthErr" />
						<TextInputMD name="Stroke" margin="0rem .2rem 1.5rem .5rem" v-model="shockStroke" :error="shockStrokeErr" />
					</div>
					<div class="flex-row">
						<TextInputMD name="Base Pressure" margin="0rem .5rem 1rem .2rem" v-model="basePressure" :error="basePressureErr" />
						<ListSelector :itemList="pressureUnitList" margin="1rem .2rem 1rem .5rem" width="7rem" v-model="pressureUnits" :error="pressureUnitsErr" :maxNumItems="3" @submit="$refs.savepanel.focus()" />
					</div>
					<!-- Save Button with Overwrite Check -->
					<p v-if="saveMessage" class="margin--bottom error">{{saveMessage}}</p>
					<div class="save-button-cont">
						<ButtonStandard name="Save to Database" :flex="true" @click="saveSpring" :error="saveError">
							<template v-slot:icon-slot>
								<IconValidate :invalid="isSaved === 2" :valid="isSaved === 1" :loading="isSaving" />
							</template>
						</ButtonStandard>
						<transition name="btn-slide">
							<div v-show="saveButtonState === 1" class="save-button-slide__cont">
								<ButtonStandard name="Overwrite?" flex="true" outline="true" error="true">
									<span class="flex-1" @click="saveSpring(true)">Overwrite?</span>
									<button class="save-button-slide__cancel" @click="saveButtonState = 0">
										<IconValidate :invalid="isSaved === 0 && !isSaving || isSaved === 2" :valid="isSaved === 1" :loading="isSaving" />
									</button>
								</ButtonStandard>
							</div>
						</transition>
					</div>
				</div>
			</button>
		</div>
	</div>
</template>
<script>
import DisplayTable from "@/components/functional/DisplayTable.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ListSelector from "@/components/functional/ListSelector.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import IconValidate from "@/components/icons/IconValidate.vue";
import AutocompleteList from "@/components/levapp/AutocompleteList.vue";

import Chart from "chart.js";
import Papa from "papaparse";
import { mapGetters, mapState } from "vuex";
import { fs_checkSpringExist, fs_saveSpring } from "@/firebaseConfig.js";

export default {
	name: 'CreateSpring',
	components: {
		DisplayTable,
		ButtonStandard,
		TextInputMD,
		ListSelector,
		IconValidate,
		AutocompleteList,
		RadioSelector
	},

	data() {
		return {
			filename: 'Load File',

			setup: {
				name: 'bike-db-table',
				liveHeader: true,
				useSelectors: false,
				altColor: true,
				headerState: false,
			},

			panelButton: {
				width: '100%',
				marginBottom: '1rem',
			},

			messages: [],

			headerRow: [],
			dataList: [],

			//Interpolation Parameters
			interpPercent: .5,
			showInterp: false,
			interpPercentError: false,
			newColumnName: 'col',
			interpC1: 1,
			interpC2: 2,
			lowerRefError: false,
			upperRefError: false,

			//Header Parameters
			headerPanelLeft: '',
			activeHeader: '',
			headerInput: '',

			//Conversion Parameters
			canConvert: true,

			//Save Parameters
			brand: '',
			modelName: '',
			modelYear: '',
			shockLength: '',
			shockStroke: '',
			basePressure: '',
			pressureUnits: 0,
			pressureUnitList: ['psi', 'bar', 'N/A'],
			psi_to_bar: 0.0689476,

			saveMessage: '',
			saveError: false,
			saveButtonState: 0,
			saveButtonTimer: '',

			isSaving: false,
			isSaved: false,
			saveTimer: '',

			brandErr: false,
			modelYearErr: false,
			modelNameErr: false,
			shockLengthErr: false,
			shockStrokeErr: false,
			basePressureErr: false,
			pressureUnitsErr: false,

			savingDatabase: 1,

			//Plot Parameters
			showPlot: false,
			plotBtn: {
				width: '12rem',
				marginRight: '1rem'
			},

			//Initialized chart object
			chart: { update: () => {}, },
			datasets: [],
		}
	},

	watch: {
		dataList: {
			deep: true,
			handler() { this.updateChartData(); },
		},
	},

	mounted() {
		this.updateChartData();

		this.remSize = parseInt(window.getComputedStyle(this.$refs.fontsize).fontSize);

		//Initialize chart object
		this.chart = new Chart(this.$refs.maincanvas, {
			type: 'line',
			data: { datasets: this.datasets, },
			options: this.chartOptions,
		});
	},

	computed: {
		...mapGetters('stateViewLev', ['get_fs_database']),
		...mapState('stateBikeData', ['colors']),
		...mapState('stateViewLev', ['user', 'claims', 'publicSpringList']),

		brandList: function() {
			return [...new Set(this.publicSpringList.map(e => String(e.brand)))];
		},

		databaseOptions: function() {
			const options = [
				['Public', false, false],
				['Private', false, true],
				['Team', false, true],
			];

			if (this.claims.app_admin) {
				options[0][2] = true;
				options[2][2] = this.claims.team_id ? true : false;
			}

			options[this.savingDatabase][1] = true;

			return options;
		},

		fs_location: function() { return this.get_fs_database(this.savingDatabase); },

		chartOptions: function() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					duration: 0
				},
				legend: {
					display: true,
				},
				elements: {
					point: {
						radius: 0,
						hitRadius: 7,
					},
				},
				hover: {
					mode: 'nearest'
				},
				tooltips: {
					mode: 'nearest',
					intersect: true,
					titleFontSize: this.remSize * 1.2,
					titleFontFamily: 'Roboto',
					bodyFontSize: this.remSize * 1.3,
					bodyFontFamily: 'Roboto',
					callbacks: {
						title: function(tooltipItem, data) {

							const title = `Column: ${data.datasets[tooltipItem[0].datasetIndex]['label']}`
							return title ? title : '';
						},
						label: function(tooltipItem) {
							// console.log(tooltipItem)
							tooltipItem.title = 'Some Item'
							return tooltipItem.xLabel + 'mm   ' + tooltipItem.yLabel + 'N';
						},
					},
				},

				scales: {
					xAxes: [{
						type: 'linear',
						display: true,
						scaleLabel: {
							fontSize: this.remSize * 1.2,
							display: true,
							labelString: 'Shock Displacement (mm)'
						},
						ticks: {
							fontSize: this.remSize * 1.2,
							autoSkip: false,
							stepSize: 1,
							maxTicksLimit: 30,
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							fontSize: this.remSize * 1.2,
							display: true,
							labelString: 'Force (N)'
						},
						ticks: {
							fontSize: this.remSize * 1.2,
						}
					}],
				}
			}
		},
	},

	methods: {
		updateChartData: function() {
			this.datasets.splice(0, this.datasets.length); //Clear array

			this.headerRow.forEach((name, index) => {
				if (index === 0) { return; }

				const data = this.dataList.map((item) => {
					return {
						x: item[0],
						y: item[index]
					};
				});

				this.datasets.push({
					data: data,
					label: name,
					fill: false,
					pointRadius: 0,
					borderColor: this.colors[index],
					backgroundColor: this.colors[index],
				});
			});

			this.chart.update() //Update chart object
		},

		resetPanel: function() {
			this.activeHeader = '';
			// this.$refs.headerpanel.blur();
			document.activeElement.blur();
		},

		setFilename: function() {
			this.resetPanel();
			if (this.$refs.file.files.length > 0) {
				this.filename = this.$refs.file.files[0].name;
				this.loadCSV();
			} else {
				this.filename = 'Load File'
			}
		},

		formatColName: function(value) {
			if (value.startsWith('.')) {
				this.$set(this.headerRow, this.activeHeader, '0' + value);
			} else {
				this.$set(this.headerRow, this.activeHeader, value);
			}
		},

		headerClick: function(val) {
			if (this.activeHeader === val) {
				this.resetPanel();
				return;
			}
			this.activeHeader = val;
			const tableWidth = this.$refs.tablediv.clientWidth;
			const panelWidth = this.$refs.headerpanel.clientWidth;
			const buttonWidth = tableWidth / this.headerRow.length;
			let panelLeft = val * buttonWidth;
			if (panelWidth > tableWidth - panelLeft) {
				panelLeft = tableWidth - panelWidth - 2;
				panelLeft = panelLeft - buttonWidth * (this.headerRow.length - 1 - val)
			}

			this.headerPanelLeft = panelLeft + 'px';
			this.$refs.headerpanel.focus();
		},

		deleteColumn: function() {
			const index = this.activeHeader;

			this.headerRow.splice(index, 1)
			this.dataList.forEach((row) => {
				row.splice(index, 1)
			});

			this.resetPanel(); //Temp reset panel

		},

		shiftColumn: function(forward) {
			let idx1 = this.activeHeader; //Selected column
			let idx2 = forward ? idx1 + 1 : idx1 - 1; //New position

			//Break if first or last row and wrong dir
			if (idx1 === 0 && !forward) { return; }
			if (idx2 > this.headerRow.length - 1 && forward) { return; }

			this.resetPanel(); //Temp reset panel
			this.headerClick(idx2); //Move panel to new location

			//Swap header row
			[this.headerRow[idx1], this.headerRow[idx2]] = [this.headerRow[idx2], this.headerRow[idx1]];
			this.dataList.forEach((row) => {
				[row[idx1], row[idx2]] = [row[idx2], row[idx1]];
			});
		},

		convertUnits: function() {
			if (this.dataList.length < 1) { return; }

			const lb_to_N = 4.4482189159;
			const in_to_mm = 25.4;

			this.dataList = this.dataList.map((row) => {
				const disp = Math.round(100 * row[0] * in_to_mm) / 100;
				row = row.map((item) => { return Math.round(100 * item * lb_to_N) / 100; });
				row[0] = disp;
				return row;
			});
			this.canConvert = false;
		},

		processData: function() {
			this.messages = []; //Clear log messages

			if (this.dataList.length < 1) { return; }
			this.canConvert = false; //Disable unit conversion after processing
			let message = '';
			const dataList_cv = []; //New array with values checked and validated
			const deletedRows = [];
			this.dataList.forEach((row, index) => {
				//Parse all values into floats and ignore any rows with non-numeric data
				let isNonNumerical = false;
				row = row.map((val) => {
					val = parseFloat(val);
					if (isNaN(val)) { isNonNumerical = true; }
					return val;
				});
				if (isNonNumerical) { deletedRows.push(index); return; }

				//Round displacements and check for oversampling
				//	Ignore all dupicate displacements; will eliminate oversampling
				row[0] = Math.round(10 * row[0]) / 10; //Round to .1mm
				if (index !== 0 && this.dataList[index - 1][0] === this.dataList[index][0]) {
					deletedRows.push(index);
					return;
				}
				dataList_cv.push(row);
			});
			//Set log messages for deleleted rows
			message = deletedRows.length > 0 ? 'Deleted rows ' + deletedRows + ' containing oversampled, non-numeric, or blank data.' : 'Confirmed no non-numeric data.'
			this.messages.push([message, true]);
			// --------------------------------------------------------------------------
			// --------------------------------------------------------------------------

			//New array with interpoleted data for .1mm shock spacing
			const dataList_iv = [];
			const interpWarning = [];
			//If the displacement does not start at zero insert row. 
			if (dataList_cv[0][0] !== 0) {
				const zeroRow = dataList_cv[0].map((e) => e);
				zeroRow[0] = 0;
				dataList_cv.unshift(zeroRow);
				message = 'Warning: Missing 0mm dispacement row. Created from first input row.'
				this.messages.push([message, false])
			}
			//Run for all rows except the last
			let range = this.range(dataList_cv.length - 1);
			range.forEach((i) => {
				const row = dataList_cv[i];
				const nextRow = dataList_cv[i + 1];
				dataList_iv.push(row); //Save starting row

				const d_shock = Math.round(10 * (nextRow[0] - row[0])) / 10;
				if (d_shock === .1) { return; }

				//Otherwise need to interpolate rows between two rows
				//	forEach for num interpolated rows
				//	nested map to operate on each value in row
				const numDivs = Math.round(d_shock / .1);
				const numInterpolatedRows = this.range(numDivs);
				numInterpolatedRows.shift(); //Will be 1 less interpreted rows than there is divs
				numInterpolatedRows.forEach((j) => {
					const interpRow = row.map((val, index) => {
						return j * (nextRow[index] - val) / numDivs + val;
					});
					interpRow[0] = Math.round(10 * interpRow[0]) / 10;
					dataList_iv.push(interpRow);
				});
				//Keep track of large interpolations
				if (d_shock > 1) { interpWarning.push(i); }

			});
			//Get original last row
			dataList_iv.push(dataList_cv[dataList_cv.length - 1]);

			//Set messages for changes made
			if (dataList_iv.length > dataList_cv.length) {
				message = 'Interpolated rows created where shock displacement was more than .1mm';
				this.messages.push([message, true]);
			}

			if (interpWarning.length > 0) {
				message = 'Warning: Large row to row interpolations at rows: ' + interpWarning;
				this.messages.push([message, false]);
			}

			//Check that there is significant shock travel
			//	This will prevent accidental inch units
			if (dataList_iv[dataList_iv.length - 1][0] < 15) {
				message = 'Warning: Maximum shock displacement is very small. Check that units are in mm and not inches. Units can be converted after loading a CSV.'
				this.messages.push([message, false]);
			}

			if (new Set(this.headerRow).size !== this.headerRow.length ||
				this.headerRow.findIndex(item => item === "") >= 0) {
				message = 'Error: Each column must have a unique and non-empty name for the volume spacer configuration';
				this.messages.push([message, false]);
			}

			this.dataList = dataList_iv;
			this.$refs.processlog.focus();
		},

		interpolate: function() {
			//Validate percentage. Can be decimal or percent
			this.interpPercentError = false;
			let percent = parseFloat(this.interpPercent);
			percent = percent > 1 ? percent / 100 : percent;
			this.interpPercent = percent;
			if (!percent || percent > 99 || percent < 0) {
				this.interpPercentError = true;
				return;
			}

			//Validate column references. Cannot be zero or equal to each other
			this.lowerRefError = this.interpC1 === 0 ? true : false;
			this.upperRefError = this.interpC2 === 0 ? true : false;
			//Validate column selects
			if (this.interpC1 === this.interpC2) {
				this.lowerRefError = true;
				this.upperRefError = true;
			}
			if (this.lowerRefError || this.upperRefError) { return; }

			//Order indexes and create local copy
			if (this.interpC1 > this.interpC2) {
				const temp_C = this.interpC2;
				this.interpC2 = this.interpC1;
				this.interpC1 = temp_C;
			}

			const idx1 = this.interpC1;
			const idx2 = this.interpC2;

			this.dataList = this.dataList.map((item) => {
				//Simple linear interpolation
				const newColumnVal = (item[idx2] - item[idx1]) * percent + item[idx1];
				item.splice(idx2, 0, Math.round(newColumnVal * 100) / 100);
				return item;
			});
			this.headerRow.splice(idx2, 0, this.newColumnName)
			this.interpC2++; //Increment to keep same selection
		},

		loadCSV: async function() {
			this.resetPanel();
			this.clearSaveInputs();
			this.canConvert = true;
			Papa.parse(this.$refs.file.files[0], {
				// worker: true,
				dynamicTyping: true,
				skipEmptyLines: 'greedy', //Kill all whitespace lines
				complete: (results) => {
					this.dataList = results.data;
					this.headerRow = this.dataList.shift();
					this.headerRow[0] = 'd_shock';
				}
			});
		},

		saveSpring: async function(overwrite = false) {
			//Check spring dataset
			if (!this.validateData() ||
				!this.validateSaveInputs() ||
				this.isSaving) { return; }

			//Set spinner and clear status
			this.isSaving = true;
			this.isSaved = 0;
			clearTimeout(this.btnTimer);

			const data = {};

			//Set data in object format
			this.dataList.forEach((row) => {
				const dataRow = row.map(val => parseFloat(val));
				let key = String(Math.round(10 * row[0]) / 10);
				dataRow.shift();
				data[key] = dataRow;
			});

			const maxTravel = this.dataList[this.dataList.length - 1][0];

			const spacerList = [...this.headerRow];
			spacerList.shift();

			const spring = {
				brand: this.brand,
				modelName: this.modelName,
				modelYear: this.modelYear,
				shockStroke: this.shockStroke,
				shockLength: this.shockLength,
				volumeSpacers: spacerList,
				spacerIndex: Math.floor(spacerList.length / 2), //Default spacer
			}

			const checkExist = await fs_checkSpringExist(spring, this.fs_location);
			if (checkExist.error) {
				this.saveMessage = 'Issues saving to database. Please try again.';
				this.isSaved = 2;
				this.saveTimer = setTimeout(this.clearSaveButton, 1500);
				return;
			}

			//If it does not exist then set to true by default
			overwrite = checkExist.exists ? overwrite : true;

			//Convert all pressure to bar. For coil spring basePressure will be 0. 
			const p_units = this.pressureUnitList[this.pressureUnits];
			let parsedPressure = p_units === 'psi' ? this.basePressure * this.psi_to_bar : this.basePressure;
			parsedPressure = p_units === 'N/A' ? 0 : parsedPressure;

			if (overwrite) {
				spring.maxTravel = maxTravel;
				spring.basePressure = parsedPressure;
				spring.data = data;
				const saveRes = await fs_saveSpring(spring, checkExist.exists, checkExist.id, this.fs_location)

				if (saveRes.error) {
					this.saveMessage = 'Issues saving to database. Please try again.';
					this.isSaved = 2;
					this.saveTimer = setTimeout(this.clearSaveButton, 1500);
					return;
				}

				this.isSaved = 1;
				this.saveTimer = setTimeout(this.clearSaveButton, 1500);
				this.clearSaveInputs();
				this.$refs.savepanel.focus();
			} else {
				this.saveButtonState = 1;
				this.isSaving = false;
				clearTimeout(this.btnTimer);
				this.btnTimer = setTimeout(() => this.saveButtonState = 0, 3000);
				this.$refs.savepanel.focus();
			}
		},

		clearSaveButton: function() {
			this.isSaving = false;
			this.isSaved = 0;
			this.saveButtonState = 0;
			clearTimeout(this.btnTimer);
		},

		clearSaveInputs: function() {
			this.brand = '';
			this.modelName = '';
			this.modelYear = '';
			this.shockLength = '';
			this.shockStroke = '';
			this.basePressure = '';
			this.pressureUnits = 0;
		},

		//Check dataset before saving
		//	Returns true for succesfully validation, false for errors
		validateData: function() {
			if (this.dataList.length < 1) {
				this.saveMessage = 'Error: No data loaded';
				return;
			}

			let dataError = false;
			let message = '';

			let prevRow = [];
			this.dataList.forEach((row, index) => {
				if (dataError) { return; } //Skip loop once first error is found

				//Hand row zero independently 
				if (index === 0) {
					//Check for non-zero starting value
					if (row[0] > .04 || row[0] < -.04) {
						dataError = true;
						message = 'Error: First row must start at zero. Please process data.';
					}
					//Check for non-numeric or empty data
					row.forEach((val) => {
						val = parseFloat(val);
						if (isNaN(parseFloat(val))) {
							dataError = true;
							message = 'Error: Non numeric data detected. Please process data';
						}
					});
					//Save row for next iteration
					prevRow = row;
					return;
				}

				//Check for incorrect shock increments (must be .1mm)
				if (Math.round(10 * (row[0] - prevRow[0])) / 10 !== .1) {
					dataError = true;
					message = 'Error: Invalid shock travel incremenents. Please process data.';
				}
				//Check for non-numeric or empty data
				row.forEach((val) => {
					val = parseFloat(val);
					if (isNaN(parseFloat(val))) {
						dataError = true;
						message = 'Error: Non numeric data detected. Please process data';
					}
				});
				prevRow = row;
			});

			//Check that there is significant shock travel
			//	This will prevent accidental inch units
			const minTravel = 5;
			if (this.dataList[this.dataList.length - 1][0] < minTravel) {
				message = 'Warning: Maximum shock displacement is very small. Check that units are in mm and not inches. Units can be converted after loading a CSV.'
				dataError = true;
			}

			//Check header row for valid names
			if (new Set(this.headerRow).size !== this.headerRow.length ||
				this.headerRow.findIndex(item => item === "") >= 0) {
				message = 'Error: Each column must have a unique and non-empty name for the volume spacer configuration. Changes can be made by clicking the column header.';
				dataError = true;
			}

			if (dataError) {
				this.saveMessage = message;
				return false;
			} else {
				return true;
			}
		},

		//Validate all set properties before saving
		validateSaveInputs: function() {
			this.brandErr = false;
			this.modelYearErr = false;
			this.modelNameErr = false;
			this.shockLengthErr = false;
			this.shockStrokeErr = false;
			this.basePressureErr = false;
			this.pressureUnitsErr = false;

			let isError = false;
			if (this.brand.length < 2) {
				this.brandErr = true;
				this.saveMessage = 'Error: All shock properties must be entered and valid.'
				isError = true;
			}

			this.modelYear = parseInt(this.modelYear);
			if (this.modelYear < 1990 || this.modelYear > 9999 || isNaN(this.modelYear)) {
				this.modelYear = '';
				this.modelYearErr = true;
				this.saveMessage = 'Error: Model year must be a valid 4 digit year.'
				isError = true;
			}

			if (this.modelName.length < 2) {
				this.modelNameErr = true;
				this.saveMessage = 'Error: All shock properties must be entered and valid.'
				isError = true;
			}

			this.shockStroke = parseFloat(this.shockStroke);
			if (isNaN(this.shockStroke)) {
				this.shockStroke = '';
				this.shockStrokeErr = true;
				this.saveMessage = 'Error: All shock properties must be entered and valid.'
				isError = true;
			}

			this.shockLength = parseFloat(this.shockLength);
			if (isNaN(this.shockLength)) {
				this.shockLength = '';
				this.shockLengthErr = true;
				this.saveMessage = 'Error: All shock properties must be entered and valid.'
				isError = true;
			}

			this.basePressure = parseFloat(this.basePressure);
			if (isNaN(this.basePressure)) {
				this.basePressure = '';
				this.basePressureErr = true;
				this.saveMessage = 'Error: All shock properties must be entered and valid.'
				isError = true;
			}

			if (this.pressureUnitList[this.pressureUnits].toLowerCase() === 'psi' &&
				this.basePressure < 3.4 && !isError ||
				this.pressureUnitList[this.pressureUnits].toLowerCase() === 'bar' &&
				this.basePressure > 65 && !isError) {

				this.basePressureErr = true;
				this.pressureUnitsErr = true;
				this.saveMessage = 'Error: Shock base pressure and units are incompatible. Please confirm selection.'
				isError = true;
			}

			return !isError;
		},

		//Utility function to create an integer range
		range: function(length) {
			return Array.from({ length: length }, (x, i) => i);
		},
	},
}
</script>
<style>
.create-spring {}

.create-spring__table {
	display: flex;
	flex-direction: column;
	max-height: 45rem;
	position: relative;
}

.create-spring__plot-cont {
	height: 45rem;
	overflow: hidden;
}

.create-spring__header-panel {
	display: flex;
	position: absolute;
	top: 2.9rem;
	width: 15rem;

	z-index: 2;
	border: .1rem solid var(--color-bg-secondary-light);
	border-radius: 0 0 .3rem .3rem;

	transform: scale(0);
}


.create-spring__header-panel:focus-within,
.create-spring__header-panel:focus {
	transform: scale(1);
}

.create-spring__interp-panel__cont {
	height: 3rem;
	width: 12rem;
	position: relative;

	color: var(--color-font-primary);
	background-color: var(--color-primary);
	border: none;
	border-radius: .3rem;

	transition: .3s;
}

.create-spring__interp-panel__cont:hover,
.create-spring__interp-panel__cont:focus-within {
	box-shadow: 0 0 0 3px var(--color-primary-light);
}

.create-spring__interp-panel__label {
	display: inline-block;
	padding: 0 .5rem;

	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: .1rem;
	line-height: 3rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
}

.create-spring__interp-panel__arrow {
	display: block;
	height: 2.5rem;
	width: 2.5rem;
	margin: .25rem;

	background: transparent;

	fill: var(--color-font-primary);

	transition: .5s ease;
	transform: rotate(180deg);
}

.create-spring__interp-panel {
	position: absolute;
	right: 0;
	bottom: calc(100% + .5rem);
	width: 25rem;
	min-height: 5rem;
	z-index: 2;
	transform: scale(0);

	border: .1rem solid var(--color-primary);
	color: var(--color-font-bg);
	background-color: var(--color-bg);
	border-radius: .3rem;

	transition: .2s;
}

.create-spring__interp-panel__cont:focus-within>.create-spring__interp-panel {
	transform: scale(1);
}

.create-spring__interp-panel__cont:focus-within .create-spring__interp-panel__arrow {
	transform: rotate(0deg);
}

.create-spring__notification {
	display: block;
	min-height: 2rem;
	max-width: 40rem;
	margin: .5rem 0;
	padding-bottom: .5rem;

	border-bottom: .1rem solid var(--color-bg-secondary-light);

	line-height: 2rem;
	text-align: left;
	word-wrap: break-word;
}
</style>