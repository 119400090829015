<template>
	<TabContainer :tabs="tabSetup" :name="'left-sidebar'" @event-tab-click="selectTabEvent" :border="border">
		<template v-slot:slot-0>
			<TabLinkage @focus-load="$emit('focus-load')" />
		</template>
		<template v-slot:slot-1>
			<TabGeometry @focus-load="$emit('focus-load')" />
		</template>
		<template v-slot:slot-2>
			<TabFlipChip @focus-load="$emit('focus-load')" />
		</template>
		<template v-slot:slot-3>
			<TabSettings />
		</template>
	</TabContainer>
</template>
<script>
import TabContainer from "@/components/functional/TabContainer.vue";
import TabLinkage from "@/components/levapp/TabLinkage.vue";
import TabGeometry from "@/components/levapp/TabGeometry.vue";
import TabFlipChip from "@/components/levapp/TabFlipChip.vue";
import TabSettings from "@/components/levapp/TabSettings.vue";

export default {
	name: "LeftSidebar",
	components: {
		TabContainer,
		TabLinkage,
		TabGeometry,
		TabFlipChip,
		TabSettings,
	},

	data() {
		return {
			tabSetup: [{
					name: 'Layout',
					checked: true,
				},
				{
					name: 'Geometry',
					checked: false,
				},
				{
					name: 'Modify',
					checked: false,
				},
				{
					name: 'Settings',
					checked: false,
				},
			],

			border: {
				borderRight: '.1rem solid var(--color-bg-hover)'
			}
		}; //endReturn
	},

	methods: {
		selectTabEvent: function(index) {
			let setVal = true;
			// const setVal = !this.tabSetup[index]['checked']
			this.tabSetup.forEach(function(item) {
				item.checked = false;
			});
			this.tabSetup[index]['checked'] = setVal;
		},
	},
};
</script>
<style>
</style>