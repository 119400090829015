<template>
	<div ref="list" class="autocomplete-list" @focusin="handleFocus" @keydown.down.prevent="selectNext" @keydown.up.prevent="selectPrev" @keydown.enter="handleEnter" :style="{margin: margin}">
		<input :data-test="test" ref="listinput" type="text" class="autocomplete__input" required autocomplete="off" @input="handleInput" @focus="inputFocus" :value="value" @blur="handleBlur">
		<label class="autocomplete__label autocomplete__freeze-label">{{ inputLabel }}
		</label>
		<!--  -->
		<div ref="autolist" class="autocomplete-result-list"  tabindex="0">
			<div class="autocomplete-result-list__items" :style="{maxHeight: maxNumItems ? maxNumItems*2.5 + 'rem' : '15rem'}">
				<!-- <transition-group name="slide-out"> -->
				<button ref="buttonlist" v-for="(result, index) in filteredList" :key="'autocomplete-list-'+index" class="autocomplete-result" @keydown.enter.stop @click="selectItem($event, result)" @touchend="selectItem($event, result)">
					{{ result }}
				</button>
				<!-- </transition-group> -->
				<button v-if="filteredList.length < 1 && !hideDefault" class="autocomplete-result">
					No results found
				</button>
			</div>
		</div>
		<div class="autocomplete-clear" @click="clearInput">
			<IconClose />
		</div>
	</div>
</template>
<script>
import IconClose from "@/components/icons/IconClose.vue"

export default {
	name: "AutocompleteFull",
	components: {
		IconClose
	},

	props: {
		itemList: Array,
		ariaLabel: String,
		value: String,
		inputLabel: String,
		maxNumItems: Number,
		margin: String,
		hideDefault: Boolean,
		test: String,
	},

	data() {
		return {
			results: [], //Adds classes based on state

			valueAtFocus: '', //Initial value

			focusedItem: -1,
		}
	},

	computed: {
		filteredList: function() {
			if (this.value.length < 0) {
				return [];
			} else {
				return this.itemList.filter(item => {
					return item.toLowerCase().includes(this.value.toLowerCase())
				}).sort();
			}
		}
	},

	methods: {
		//On list select emit a submit event
		selectItem: function(event, item) {
			this.$emit('input', item.trim());
			this.$emit('submit', item.trim());
			event.target.blur();
			this.$refs.autolist.blur();
		},

		//Pass input event to parent
		handleInput: function(event) {
			this.$emit('input', event.target.value.trim());
		},

		//Record initial value at focus
		//	When focusing out we can compare and 
		//	Decide whether or not to emit a submit
		handleFocus: function() {
			this.valueAtFocus = this.value;
		},

		inputFocus: function() {
			this.focusedItem = -1;
		},

		//Emit a submit only if value has changed
		handleBlur: function() {
			if (this.valueAtFocus !== this.value) {
				this.$emit('input', this.value.trim());
				this.$emit('submit', this.value.trim());
			}
		},

		handleEnter: function() {
			if (this.valueAtFocus !== this.value) {
				if (this.filteredList.length === 1) {
					this.$emit('input', this.filteredList[0].trim());
					this.$emit('submit', this.filteredList[0].trim());
					return;
				}
				this.$emit('input', this.value.trim());
				this.$emit('submit', this.value.trim());
			}
			event.target.blur();
			this.$refs.autolist.blur();
		},

		selectNext: function() {
			if (this.focusedItem < this.filteredList.length - 1) {
				this.focusedItem++;
			}
			this.$refs.buttonlist[this.focusedItem].focus();
		},

		selectPrev: function() {
			if (this.focusedItem !== 0) {
				this.focusedItem--;
			}
			this.$refs.buttonlist[this.focusedItem].focus();
		},

		clearInput: function() {
			this.$emit('input', '');
			this.$emit('submit', '');
		}
	},

};
</script>
<style>
.autocomplete-list {
	display: block;
	height: 4rem;
	flex: 1;
	position: relative;

	margin: 1.5rem 0;
}

.autocomplete-list:first-child {
	margin-top: 1rem;
}

.autocomplete-list:last-child {
	margin-bottom: 1rem;
}

.autocomplete__input {
	appearance: none;

	height: 3rem;
	width: 100%;
	position: relative;
	z-index: 1;

	padding: .2rem 1.0rem;
	margin-top: 1rem;

	background-color: transparent;
	border: none;
	border: 0.1rem solid var(--color-bg-secondary-light);
	border-radius: .3rem;

	line-height: 3rem;
	/*transition: .3s;*/
}

.autocomplete-clear {
	position: absolute;
	top: 1.2rem;
	right: .3rem;
	height: 3rem;
	width: 2.7rem;
	padding: .7rem;

	background-color: transparent;
	fill: var(--color-bg-secondary-light);
	cursor: pointer;
	z-index: 1;

	transition: .2s;
}

.autocomplete-clear:hover {
	transform: scale(1.1);
	fill: var(--color-font-bg);
}

.autocomplete-clear:active {
	transform: scale(1);
	fill: var(--color-bg-secondary-light);
}

.autocomplete-list:focus-within>.autocomplete__input {
	/*outline: none;*/
	padding-top: .3rem;
	padding-left: 1.1rem;
	border-radius: 0;
	border: none;
	border-bottom: .7px dotted var(--color-primary);

	z-index: 11;
}

.autocomplete-list:focus-within>.autocomplete-clear {
	z-index: 11;
}

.autocomplete__label {
	color: var(--font-bg);
	background-color: transparent;
	left: .5rem;
	padding: 0 .3rem;
	position: absolute;
	top: 1.75rem;
	transition: .15s;
}

.autocomplete-list:focus-within>.autocomplete__label,
.autocomplete__input:valid+.autocomplete__label,
.autocomplete__freeze-label {
	color: var(--color-primary);
	font-size: 1.1rem;
	font-weight: 700;
	top: -.5rem;
	left: 0rem;
	background: transparent;
	z-index: 2;
}

.autocomplete-list:not(:focus-within)>.autocomplete__label {
	color: var(--color-font-bg);
}

.autocomplete-result-list {
	visibility: hidden;
	box-sizing: border-box;
	width: 100%;
	position: absolute;
	z-index: 10 !important;
	margin: 0;
	padding: 0;
	/*padding: .1px;*/
	overflow: hidden;
	top: 1rem;

	border: .1rem solid var(--color-primary);
	border-radius: .3rem;
	background: transparent;
	list-style: none;

	box-shadow: 0;
	transition: box-shadow .3s;
}

.autocomplete-result-list__items {
	width: 100%;
	margin-top: 3rem;
	max-height: 15rem;
	border-radius: .15rem;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--color-bg);
}

.autocomplete-list:focus-within .autocomplete-result-list {
	visibility: visible;
	box-shadow: 0 0 0 3px var(--color-primary-light);
}

/* Single result item */
.autocomplete-result {
	display: block;
	width: 100%;
	height: 2.5rem;
	padding: .5rem .5rem .5rem 1rem;

	background: transparent;
	border: none;
	cursor: default;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	text-align: left;
}

.autocomplete-result:hover,
.autocomplete-result:focus {
	background-color: var(--color-bg-cell-highlight);
}
</style>