<template>
	<div class="load-bike-chip_cont">
		<button data-test="load-bike-chip" ref="loadbikechip" class="load-bike-chip" @click="showPanel = !showPanel">
			<div class="load-bike-chip-bg bg-gradient">
			</div>
		</button>
		<div class="load-bike-chip__icon card-shadow no-select">
			<svg class="load-bike__svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
				<path d="m 50 10 l 0 80 m -40 -40 l 80 0" />
			</svg>
		</div>
		<transition name="scale">
			<div data-test="load-bike-panel" v-if="showPanel" class="load-bike-chip__context-menu" v-click-outside="() => showPanel = false">
				<LoadBike />
			</div>
		</transition>
	</div>
</template>
<script>
import LoadBike from "@/components/levapp/LoadBike.vue";
import vClickOutside from "v-click-outside";

export default {
	name: 'LoadBikeChip',
	props: {},

	components: {
		LoadBike,
	},

	directives: {
		clickOutside: vClickOutside.directive
	},

	data() {
		return {
			showPanel: false,
		}
	}
}
</script>
<style>
.load-bike-chip_cont {
	position: relative;
	height: 4rem;
}

.load-bike-chip-bg {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	transition: all .2s linear;
}

.load-bike-chip {
	box-sizing: border-box;
	width: 4rem;
	height: 4rem;
	margin: .25rem 1rem;
	margin-left: .5rem;

	border: none;
	border-radius: 2rem;
	background-color: var(--color-bg);

	transition: transform .2s, box-shadow .2s;
}

.load-bike-chip:hover,
.load-bike-chip:focus-within,
.load-bike-chip:active {
	border: .75rem solid var(--color-primary);
	box-shadow: 0 0 0 3px var(--color-primary-light);
	transition: all .2s linear;
}

.load-bike-chip:hover>.load-bike-chip-bg,
.load-bike-chip:focus-within>.load-bike-chip-bg,
.load-bike-chip:active>.load-bike-chip-bg {
	display: none;
}

.load-bike-chip__icon {
	position: absolute;
	width: 2rem;
	height: 2rem;
	top: -.2rem;
	right: .5rem;

	background-color: white;
	border: .15rem solid var(--color-primary);
	border-radius: .75rem;

	line-height: 1.7rem;
	font-size: 2rem;
	font-weight: 700;

	transition: .1s linear;
}

.load-bike-chip:hover>.load-bike-chip__icon,
.load-bike-chip:focus>.load-bike-chip__icon {
	transform: scale(1.1);
	transition: .2s linear;
}

.load-bike__svg {
	background-color: transparent;
	stroke: var(--color-font-bg);
	stroke-width: 15;
	margin: .1rem;
}

.load-bike-chip__menu-pos {
	height: 0;
	width: 0;
	position: absolute;
	bottom: 2.5rem;
	left: 0;

}

.load-bike-chip__context-menu {
	width: 25rem;
	height: 32rem;
	position: absolute;
	bottom: 5.5rem;
	z-index: 3;

	background-color: var(--color-bg);
	border-radius: .5rem;
	border: .1rem solid var(--color-primary);
	cursor: auto;

	display: flex;
	flex-direction: column;
}
</style>