<template>
    <div class="margin--bottom" style="padding: .5rem">
        <div class="flex-row flex-space" >
            <p class="bike-setup__info-item">Travel: <span>{{ displayTravel }}</span>mm</p>
            <p class="bike-setup__info-item">Stroke: <span>{{ displayShockTravel }}</span>mm</p>
        </div>
        <div class="flex-row flex-space" style="margin-bottom: .5rem">
            <p class="bike-setup__info-item">{{ displaySagType }}<span>{{ displaySag }}</span>%</p>
            <p class="bike-setup__info-item">Rate: <span>{{ displayRate[0] }}</span>{{ displayRate[1] }}</p>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
    name: 'OptimizeInfo',
	data() {
		return {
			nameList: ['Sag %', 'Sag %', 'Spring Rate'],
			typeList: ['Shock Sag', 'Wheel Sag', 'Set Rate'],

			weightErr: false,
			rateErr: false,
			heightErr: false,

			changeRef: '',

			lb_to_N: 4.44822,
			kg_to_N: 9.80665,

			in_to_mm: 25.4,

			psi_to_bar: 0.0689476,
			lb_in_to_N_mm: 0.175126835,
		}
	},

	computed: {
        ...mapState('stateBikeData', ['selectedBikeIndex', 'bikeViewArray']),
		...mapState('stateViewLev', ['completeSpringList', 'isMetric']),
        ...mapState('stateOptimize', ['springSettings']),

		springData: function() { return this.bikeViewArray[this.selectedBikeIndex]?.springData ?? {} },
		isSpring: function() { return (Object.keys(this.springData)?.length >= 3) ? true : false; },

		//Coil spring will either have a base pressure of zero or will be a simple coil type
		isCoil: function() {
			if (this.springType === 1) { return true; }
			if (this.springData?.basePressure && this.springData.basePressure === 0) {
				return true;
			} else {
				return false
			}
		},

		//Loaded spring or simplified coil
		springType: function() { return this.springSettings.springType; },

		//Set rateType
		rateType: function() { return this.springSettings.rateType; },

		//Show either shock or wheel sag depending on how the rate is driver
		displaySagType: function() { return this.rateType !== 0 ? 'Shock Sag: ' : 'Wheel Sag: ' },

		displaySag: function() {
			return isNaN(100 * this.springSettings.displaySag) ? '-' : Math.round(10 * 100 * this.springSettings.displaySag) / 10;
		},

		//Display the set rate in appropriate units
		displayRate: function() {
			if (this.isCoil && this.springSettings.coilRate == '-') { return '-'; }
			if (!this.isCoil && this.springSettings.airRate == '-') { return '-'; }

			const rate = this.isCoil ? this.springSettings.coilRate : this.springSettings.airRate;

			//For coil
			if (this.isMetric && this.isCoil) {
				return [Math.round(10 * rate) / 10 || '-', 'N/mm'];
			} else if (this.isCoil) {
				return [Math.round(rate / this.lb_in_to_N_mm) || '-', 'lb/in'];
			}

			//For air
			if (this.isMetric) {
				return [Math.round(100 * rate) / 100 || '-', 'bar'];
			} else {
				return [Math.round(10 * rate / this.psi_to_bar) || '-' / 10, 'psi'];
			}
		},

		displayTravel: function() {
			if (isNaN(parseFloat(this.springSettings.wheelTravel))) { return '-'; }

			return Math.round(10 * this.springSettings.wheelTravel) / 10;
		},

		displayShockTravel: function() {
			if (isNaN(parseFloat(this.springSettings.shockTravel))) { return '-'; }
			if(this.springSettings.curveTravel > this.springSettings.shockTravel){
				return `${this.springSettings.curveTravel}/${this.springSettings.shockTravel}`
			}
			return this.springSettings.shockTravel;
		}
	},
}
</script>
