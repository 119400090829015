<template>
	<div role="list" class="tab-data-tools">
		<CollapsibleContainer :sections="containerContent" containerName="datatools-input-tab" @event-cc-click="selectCCEvent">
			<template v-slot:slot-0>
				<div v-if="notElectron" style="padding: 0 1rem">
					<span class="category-label">Load Local File</span>
					<div class="flex-row margin--bottom">
						<label class="file-label flex-1" for="load-local-file">
							{{filename}}
							<input @input="setFilename" ref="file" id="load-local-file" class="file-input" type="file" accept=".syn" data-test="local-file-input">
						</label>
						<ButtonStandard :flex="true" name="Load" :btnstyle="loadButtonStyle" @click="loadLocal" test="load-local-btn" />
					</div>
					<transition name="slide-out">
						<div class="tab-data-tools__msg padding--top" v-if="loadMessage" data-test="load-local-message">{{ loadMessage }}</div>
					</transition>
					<div class="border--bottom margin--top padding--bottom">
						<ButtonStandard v-if="isBikesLoaded" name="Save to Local File" :flex="true" :outline="true" @click="localDownload" test="save-local-btn" />
					</div>
				</div>
				<div v-if="isBikesLoaded" class="margin--top" style="padding: 0 1rem">
					<ButtonStandard name="Download Results CSV" @click="exportCSV" :flex="true" test="download-results-btn" />
					<div class="flex-row border--top margin--top">
						<TextInputMD name="Send To Email:" type="email" :error="emailErr" v-model="sendEmail" @enter="shareBike" />
						<ButtonStandard name="Send" @click="shareBike" :btnstyle="{margin: '2rem 0 1.5rem .5rem'}" :outline="true">
							<template v-slot:icon-slot>
								<IconValidate v-if="isSending" :valid="sendingStatus === 1" :invalid="sendingStatus === 2" :loading="isSending" />
							</template>
						</ButtonStandard>
					</div>
					<transition name="slide-out">
						<div class="tab-data-tools__msg" v-if="sendResponseMsg">{{sendResponseMsg}}</div>
					</transition>
				</div>
				<div v-if="notElectron && !isBikesLoaded" class="padding--half">
					<p>No bikes loaded</p>
				</div>
			</template>
			<!--  -->
			<template v-slot:slot-1>
				<ButtonStandard name="Create Spring" @click="showSpring = true" :flex="true" />
			</template>
			<!--  -->
			<template v-slot:slot-2>
				<Optimize />
			</template>
		</CollapsibleContainer>
		<!--  -->
		<!--  -->
		<!--  -->
		<ModalWindow :show="showSpring" @close="showSpring = !showSpring" maxWidth="90rem" minWidth="75rem">
			<template v-slot:body>
				<CreateSpring />
			</template>
		</ModalWindow>
	</div>
</template>
<script>
import ModalWindow from "@/components/ModalWindow.vue";
import CreateSpring from "@/components/levapp/CreateSpring.vue";
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import IconValidate from "@/components/icons/IconValidate.vue";
import Optimize from "@/components/levapp/Optimize.vue";


import { validateBikeObj } from "@/modules/validateFile.js";
import { mapGetters, mapActions, mapState } from "vuex";
import { saveAs } from "file-saver";
import { sendRequest } from "@/modules/sendRequest.js";

export default {
	name: "TabDataTools",
	components: {
		CollapsibleContainer,
		ButtonStandard,
		TextInputMD,
		ModalWindow,
		CreateSpring,
		IconValidate,
		Optimize,
	},

	data() {
		return {
			containerContent: [{
					name: 'Data Import/Export',
					checked: false,
					noHighlight: true,
				},
				{
					name: 'Spring Tools',
					checked: false,
					noHighlight: true,
					style: 'padding: 1rem'
				},
				{
					name: 'Optimizer',
					checked: true,
					noHighlight: true,
				}
			],

			notElectron: !process.env.IS_ELECTRON,
			showSpring: false,

			//Sending Variables
			sendEmail: '',
			emailErr: false,
			sendResponseMsg: '',
			messageTimeout: '',
			sendingStatus: 0,
			isSending: false,

			loadMessage: '',
			loadMessageTimeout: '',
			loadFile: '',
			filename: '',
			loadButtonStyle: {
				maxWidth: '7rem',
				marginLeft: '.5rem'
			}
		}; //endReturn
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['claims', 'userInfo']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },

		bikeColorStyle: function() {
			const color = this.getData('bikeViewArray', this.currentBikeIndex).color
			return {
				'--color-primary': color,
				'--color-primary-light': color + '33',
				// border: 'none',
			};
		},
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData', 'setError']),
		...mapActions('stateBikeData', ['loadDBBike']),

		//Manage collapsible container state
		selectCCEvent: function(index) {
			// this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		//Set filename for locally loaded file
		setFilename: function() {
			if (this.$refs.file.files.length > 0) {
				this.filename = this.$refs.file.files[0].name;
				this.loadFile = this.$refs.file.files[0];
				this.$refs.file.value = '';
			} else {
				this.filename = 'Load File';
				this.loadFile = '';
				this.$refs.file.value = '';
			}
		},

		loadLocal: function() {
			if (!this.loadFile) {
				this.setLoadMessage('No file selected');
				return;
			}

			let bikeObj = {};

			const reader = new FileReader();
			reader.onload = (e) => {
				try {
					bikeObj = JSON.parse(e.target.result)
					bikeObj = validateBikeObj(bikeObj)
					if (bikeObj === false) { throw "invalid" }
					if (bikeObj.database === 3) { bikeObj.database = 1; } //Don't load local database options for web
					this.loadDBBike(bikeObj);
				} catch {
					this.setLoadMessage('Invalid or corrupted file.')
				}

				this.filename = 'Load File';
				this.loadFile = '';
			}

			reader.readAsText(this.loadFile)
		},

		localDownload: function() {
			const bikeBlob = new Blob([JSON.stringify(this.bikeData)], { type: "application/json" });
			const filename = this.bikeData.brand + '_' + this.bikeData.modelYear +
				'_' + this.bikeData.modelName + '_v' + this.bikeData.version;

			saveAs(bikeBlob, filename.replace(/[ ,.]/g, '_') + '.syn')
		},

		exportCSV: function() {
			const kinData = this.getData('bikeViewArray', this.currentBikeIndex).kinematicData;
			const keyList = Object.keys(kinData).filter(key => key !== 'forces');
			if (keyList.length > 0) {
				let filename = this.bikeData.modelYear + '_' + this.bikeData.brand + '_' + this.bikeData.modelName + '_kin_data.csv';

				//Initialize string
				let csvString = ''
				//Add keys into header row
				keyList.forEach((item) => {
					csvString += item + ',';
				})
				csvString = csvString.slice(0, -1) + '\n';

				//Loop down array and create CSV rows
				for (let i = 0; i < kinData[keyList[0]].length; i++) {
					let csvRow = ''
					keyList.forEach((item) => {
						csvRow += kinData[item][i] + ',';
					})
					csvRow = csvRow.slice(0, -1) + '\n';
					csvString += csvRow;
				}

				//Save file
				const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
				saveAs(blob, filename);
			} else {
				this.setError('No solved data available')
			}
		},

		setLoadMessage: function(message) {
			clearTimeout(this.messageTimeout)
			this.loadMessage = message;
			this.loadMessageTimeout = setTimeout(() => {
				this.loadMessage = '';
			}, 3000);
		},

		//Send bike functions----------------
		setSendMessage: function(message) {
			clearTimeout(this.messageTimeout)
			this.sendResponseMsg = message;
			this.messageTimeout = setTimeout(() => {
				this.sendResponseMsg = '';
			}, 5000);
		},

		shareBike: async function() {
			if (!/^.+@.+\..+$/.test(this.sendEmail)) {
				this.emailErr = true;
				return;
			}

			if (this.isSending) { return; }

			// Allow team admins to set a soft block on send permissions
			if (this.userInfo.team_block) {
				this.setSendMessage('You do not have permissions to send files externally. Contact your team administrator.')
			}

			this.isSending = true;

			this.emailErr = false;
			const data = {
				bike: this.bikeData,
				to_email: this.sendEmail,
			}

			const res = await sendRequest(data, process.env.VUE_APP_UTILITY_URL + '/bike-send')

			if ('error' in res) {
				this.setSendMessage(res.error.message);
				this.sendingStatus = 2;
			} else {
				this.sendingStatus = 1;
			}

			setTimeout(this.clearSendStatus, 1200);
		},

		clearSendStatus: function() {
			this.isSending = false;
			this.sendingStatus = 0;
		},
	}
}
</script>
<style>
.tab-data-tools__msg {
	margin: 0 .5rem;
	margin-bottom: 1rem;
	text-align: left;
}
</style>