<template>
	<div ref="bikesetup" class="bike-setup">
		<div class="bike-setup__info-cont">
			<div class="flex-row flex-space" style="margin-bottom: .5rem">
				<p class="bike-setup__info-item">Travel: <span>{{ displayTravel }}</span>mm</p>
				<p class="bike-setup__info-item">Stroke: <span>{{ displayShockTravel }}</span>mm</p>
			</div>
			<div class="flex-row flex-space" style="margin-bottom: .5rem">
				<p class="bike-setup__info-item">{{ displaySagType }}<span>{{ displaySag }}</span>%</p>
				<p class="bike-setup__info-item">Rate: <span>{{ displayRate[0] }}</span>{{ displayRate[1] }}</p>
			</div>
			<div class="flex-row flex-space" style="margin: 1rem 0 1.5rem 0">
				<ButtonStandard @click="changeState" :flex="true" :outline="true" test="bike-chip-panel-change-spring">
					<span class="bike-setup__spring-label">{{ springType === 0 ? springName : 'Simple Coil' }}</span>
				</ButtonStandard>
			</div>
		</div>
		<div class="bike-setup__spacer"></div>
		<div class="flex-row">
			<ListSelector :itemList="['Measured', 'Simple Coil']" :flex="true" margin="1rem .5rem .7rem .2rem" :maxNumItems="4" :value="springType" @input="setSpringType" label="Spring Type" />
			<ListSelector v-if="springType === 0 && isSpring" :itemList="springData.volumeSpacers" :flex="true" margin="1rem .2rem .7rem .5rem" :maxNumItems="4" :value="springData.spacerIndex" @input="setSpacer" label="Config" />
			<div v-else class="flex-1" style="margin-left: .7rem"></div>
		</div>
		<div class="flex-row">
			<TextInputMD :name="nameList[rateType]" margin="0rem .5rem .7rem .2rem" :value="rate" @submit="setRate" test="bike-chip-panel-set-rate" />
			<ListSelector :itemList="typeList" margin="1rem .2rem .7rem .5rem" :flex="true" :maxNumItems="4" :value="rateType" @input="setRateType" />
		</div>
		<div class="flex-row">
			<TextInputMD name="Rider Height" margin="0rem .5rem .7rem .2rem" :flex="true" :value="riderHeight" @submit="setHeight" />
			<TextInputMD name="Rider Weight" margin="0rem .2rem .7rem .5rem" :flex="true" :error="weightErr" :value="riderWeight" @submit="setWeight" />
		</div>
	</div>
</template>
<script>
import TextInputMD from "@/components/functional/TextInputMD.vue";
import ListSelector from "@/components/functional/ListSelector.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: 'BikeSetup',

	props: {
		bikeIndex: Number,
	},

	components: {
		ButtonStandard,
		TextInputMD,
		ListSelector
	},

	data() {
		return {
			nameList: ['Sag %', 'Sag %', 'Spring Rate'],
			typeList: ['Shock Sag', 'Wheel Sag', 'Set Rate'],

			weightErr: false,
			rateErr: false,
			heightErr: false,

			changeRef: '',

			lb_to_N: 4.44822,
			kg_to_N: 9.80665,

			in_to_mm: 25.4,

			psi_to_bar: 0.0689476,
			lb_in_to_N_mm: 0.175126835,
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['completeSpringList', 'isMetric']),

		bikeData: function() { return this.getBikeData(this.bikeIndex) },
		springData: function() { return this.getData('bikeViewArray')[this.bikeIndex].springData },
		springSettings: function() { return this.getData('bikeViewArray')[this.bikeIndex].springSettings },
		isSpring: function() { return (Object.keys(this.springData).length >= 3) ? true : false; },

		//Coil spring will either have a base pressure of zero or will be a simple coil type
		isCoil: function() {
			if (this.springType === 1) { return true; }
			if ('basePressure' in this.springData && this.springData.basePressure === 0) {
				return true;
			} else {
				return false
			}
		},

		//Return either loaded spring name or default text
		springName: function() {
			let name = 'Load Spring';
			if (this.isSpring) {
				name = this.springData.modelYear + ' ' + this.springData.brand + ' ' + this.springData.modelName;
			}
			if (name.length > 21) {
				name = this.springData.modelYear + ' ' + this.springData.modelName;
			}
			if (name.length > 21) {
				name = this.springData.modelName;
			}

			return name;
		},

		//Loaded spring or simplified coil
		springType: function() { return this.springSettings.springType; },

		//Set rateType
		rateType: function() { return this.springSettings.rateType; },

		//Display the appropriate rate in user units
		rate: function() {
			let rate = '';

			if (this.rateType === 2) {
				rate = this.isCoil ? this.springSettings['coilRate'] : this.springSettings['airRate'];

				//Check for coils
				if (this.isMetric && this.isCoil) { return Math.round(10 * rate) / 10 + 'N/mm'; }
				if (this.isCoil) { return Math.round(rate / this.lb_in_to_N_mm) + 'lb/in'; }

				//Check for air
				if (this.isMetric) { return Math.round(100 * rate) / 100 + 'bar'; }
				return Math.round(10 * rate / this.psi_to_bar) / 10 + 'psi';
			}

			return this.rateType === 1 ? this.springSettings['wheelSag'] : this.springSettings['shockSag']

		},

		//Get rider weight in Newtons and display in user units
		riderWeight: function() {
			const weight_N = this.bikeData.geometry.riderWeight[this.bikeData.selectedSize];
			if (this.isMetric) {
				return Math.round(100 * weight_N / this.kg_to_N) / 100 + 'kg';
			} else {
				return Math.round(100 * weight_N / this.lb_to_N) / 100 + 'lbs';
			}
		},

		//Get rider height in mm and displace in user units
		riderHeight: function() {
			const height_mm = this.bikeData.geometry.riderHeight[this.bikeData.selectedSize];
			if (this.isMetric) {
				return Math.round(height_mm) / 10 + 'cm';
			} else {
				return Math.round(100 * height_mm / this.in_to_mm) / 100 + 'in';
			}
		},

		//Show either shock or wheel sag depending on how the rate is driver
		displaySagType: function() { return this.rateType !== 0 ? 'Shock Sag: ' : 'Wheel Sag: ' },

		displaySag: function() {
			return isNaN(100 * this.springSettings.displaySag) ? '-' : Math.round(10 * 100 * this.springSettings.displaySag) / 10;
		},

		//Display the set rate in appropriate units
		displayRate: function() {
			if (this.isCoil && this.springSettings.coilRate == '-') { return '-'; }
			if (!this.isCoil && this.springSettings.airRate == '-') { return '-'; }

			const rate = this.isCoil ? this.springSettings.coilRate : this.springSettings.airRate;

			//For coil
			if (this.isMetric && this.isCoil) {
				return [Math.round(10 * rate) / 10, 'N/mm'];
			} else if (this.isCoil) {
				return [Math.round(rate / this.lb_in_to_N_mm), 'lb/in'];
			}

			//For air
			if (this.isMetric) {
				return [Math.round(100 * rate) / 100, 'bar'];
			} else {
				return [Math.round(10 * rate / this.psi_to_bar) / 10, 'psi'];
			}
		},

		displayTravel: function() {
			if (isNaN(parseFloat(this.springSettings.wheelTravel))) { return '-'; }

			return Math.round(10 * this.springSettings.wheelTravel) / 10;
		},

		displayShockTravel: function() {
			if (isNaN(parseFloat(this.springSettings.shockTravel))) { return '-'; }

			return this.springSettings.shockTravel;
		}
	},

	methods: {
		...mapActions('stateBikeData', ['setData']),
		//Manage switch between loading spring and settings
		changeState: function() {
			this.$emit('change-state');
		},

		//Either use a loaded spring or a simplified coil
		setSpringType: function(value) {
			this.setData({
				commit: 'PUTBIKEVIEWDATA',
				index: this.bikeIndex,
				item: 'springSettings',
				subItem: 'springType',
				value: value,
			});

			//Create a simplified coil spring if required
			if (value === 1) {
				const stroke = this.bikeData.solverInfo.shockStroke;
				const coilIndices = Array.from({ length: stroke * 10 + 1 },
					(x, i) => i / 10);
				const data = {};
				coilIndices.forEach((item) => {
					data[String(item)] = [0];
				});

				const spring = {
					data: data,
					maxTravel: stroke,
					spacerIndex: 0,
				};

				this.setData({
					commit: 'PUTBIKEVIEWDATA',
					index: this.bikeIndex,
					item: 'simpleCoil',
					value: spring,
				});
			}
		},

		//Set volume spacer configuration
		setSpacer: function(value) {
			this.setData({
				commit: 'PUTBIKEVIEWDATA',
				index: this.bikeIndex,
				item: 'springData',
				subItem: 'spacerIndex',
				value: value,
			});
		},

		setWeight: function(value) {
			const weight = this.parseWeight(value);
			if (weight === 'error') { return; }

			const size = this.bikeData.selectedSize;
			this.setData({
				item: 'geometry',
				index: this.bikeIndex,
				value: weight,
				row: 'riderWeight', //Which geometry item is being changed
				column: size, //Which column of the geometry item array to put the value in
				commit: 'PUTBIKEPOINTDATA',
			});
		},

		setHeight: function(value) {
			const height = this.parseHeight(value);
			if (height === 'error') { return; }

			const size = this.bikeData.selectedSize;
			this.setData({
				item: 'geometry',
				index: this.bikeIndex,
				value: height,
				row: 'riderHeight', //Which geometry item is being changed
				column: size, //Which column of the geometry item array to put the value in
				commit: 'PUTBIKEPOINTDATA',
			});
		},

		setRateType: function(value) {
			this.setData({
				commit: 'PUTBIKEVIEWDATA',
				index: this.bikeIndex,
				item: 'springSettings',
				subItem: 'rateType',
				value: value,
			});
		},

		setRate: function(value) {
			if (this.rateType === 2) {
				const rate = this.parseRate(value);
				if (rate === 'error') { return; }

				const subItem = this.isCoil ? 'coilRate' : 'airRate';
				if (rate !== this.springSettings[subItem]) {
					this.setData({
						commit: 'PUTBIKEVIEWDATA',
						index: this.bikeIndex,
						item: 'springSettings',
						subItem: subItem,
						value: rate,
					});
				}
			} else {
				const sag = this.parseSag(value);
				if (sag === 'error') { return; }
				const subItem = this.rateType === 1 ? 'wheelSag' : 'shockSag';
				if (sag !== this.rate) {
					this.setData({
						commit: 'PUTBIKEVIEWDATA',
						index: this.bikeIndex,
						item: 'springSettings',
						subItem: subItem,
						value: sag,
					});
				}
			}

		},

		//Parse input and return weight in Newtons
		parseWeight: function(riderWeight) {
			this.weightErr = false;

			if (riderWeight === '' || isNaN(parseFloat(riderWeight))) {
				this.weightErr = true;
				return 'error';
			}

			if (riderWeight.toLowerCase().includes('kg')) {
				return parseFloat(riderWeight) * this.kg_to_N;
			}

			if (riderWeight.toLowerCase().includes('lb')) {
				return parseFloat(riderWeight) * this.lb_to_N;
			}

			//If units are not specified then guess from input
			riderWeight = parseFloat(riderWeight);
			if (riderWeight <= 0) {
				this.weightErr = true;
				return 'error';
			}

			riderWeight = riderWeight <= 110 ? riderWeight + 'kg' : riderWeight + 'lb';

			return this.parseWeight(riderWeight);
		},

		//Parse input and return height in mm
		parseHeight: function(height) {
			this.heightErr = false;

			if (height === '' || isNaN(parseFloat(height))) {
				this.heightErr = true;
				return 'error';
			}

			if (height.toLowerCase().includes('mm')) {
				return parseFloat(height);
			}

			if (height.toLowerCase().includes('cm')) {
				return parseFloat(height) * 10
			}

			if (height.toLowerCase().includes('m')) {
				return parseFloat(height) * 1000;
			}

			if (height.toLowerCase().includes('in')) {
				return parseFloat(height) * this.in_to_mm;
			}

			//Handle feet and inches. Must be separated by single quote
			if (height.toLowerCase().split('\'').length === 2) {
				const us_height = height.toLowerCase().split('\'');
				let feet = parseFloat(us_height[0]) * 12 * this.in_to_mm;
				let inch = parseFloat(us_height[1]) * this.in_to_mm;
				if (!isNaN(feet + inch)) {
					return feet + inch;
				}
			}

			height = parseFloat(height);
			if (height <= 0) {
				this.heightErr = true;
				return 'error';
			}

			//Let's play the unit guessing game
			let suffix = ''
			if (height >= 300) { suffix = 'mm'; }
			if (height >= 84 && height < 300) { suffix = 'cm'; }
			if (height >= 7 && height < 84) { suffix = 'in'; }
			if (height < 7) {
				height = height * 12 + 'in';
			}

			return this.parseHeight(height + suffix);
		},

		//Parse rate into a pressure in bar, or a spring rate in N/mm
		parseRate: function(rate) {
			this.rateErr = false;

			if (rate === '' || isNaN(parseFloat(rate))) {
				this.rateErr = true;
				return 'error';
			}

			if (this.isCoil) {
				//For coils we look for N/mm and lb/in
				if (rate.toLowerCase().includes('n/')) { return parseFloat(rate); }

				if (rate.toLowerCase().includes('lb')) { return parseFloat(rate) * this.lb_in_to_N_mm; }

				rate = parseFloat(rate);
				rate = rate <= 150 ? rate + 'N/mm' : rate + 'lb/in';
				return (this.parseRate(rate));
			}

			//For air check for psi and bar
			if (rate.toLowerCase().includes('psi')) { return parseFloat(rate) * this.psi_to_bar; }
			if (rate.toLowerCase().includes('bar')) { return parseFloat(rate); }

			rate = parseFloat(rate);
			rate = rate <= 25 ? rate + 'bar' : rate + 'psi';
			return (this.parseRate(rate));
		},

		//Validate sag percent
		parseSag: function(sag) {
			this.rateErr = false;

			sag = parseFloat(sag);

			if (isNaN(sag) || sag < 0 || sag > 100) {
				this.rateErr = true;
				return 'error';
			}
			//Convert to percent
			if (sag >= 1) { return sag / 100; }

			return sag
		},
	}
}
</script>
<style>
.bike-setup {
	flex: 1;
	margin: 1rem;
}

.bike-setup__info-cont {
	height: 10.5rem;
}

.bike-setup__spacer {
	padding-top: 1.5rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	margin-bottom: 1.9rem;
}

.bike-setup__info-item {
	height: 3rem;
	line-height: 3rem;
	font-size: 1.2rem;
	font-weight: 500;
	margin-right: .25rem;
}

.bike-setup__info-item>span {
	font-size: 1.5rem;
	font-weight: 500;
	margin-right: 1px;
}

.bike-setup__spring-label {
	display: block;
	width: 100%;
	font-size: 1.2rem;
	padding: 0 .5rem;
	text-align: center;
}
</style>