<template>
	<div class="bikeplot">
		<div ref="bikeplot__tooltip" v-tooltip="tooltip" class="bikeplot__tooltip"></div>
		<div v-resize:debounce="onResize" ref="konvaBikeWindow" tabindex="0" class="canvas-cont" @keydown="handleKeypress" @keyup="handleKeyup">
			<v-stage v-if="isBikesLoaded" :config="configKonva()" ref="stage" @wheel="onZoom">
				<v-layer ref="layer_img" :config="configImgLayer()">
					<v-image ref="konvaImage" :config="configImg()" @dragmove="imgDrag" />
				</v-layer>
				<!--  -->
				<!--  -->
				<!--  -->
				<v-layer :config="configLayer()" ref="layer_frame">
					<!-- Wheelbase and Ground Lines -->
					<v-line ref="wbLine" :config="configPathLine([p_wheelbase1, p_wheelbase2])" />
					<v-line v-if="getViewData('coordinateSystem') === 0" :config="configPathLine([
					[frontWheelPos[0] + f_tire_radius, frontWheelPos[1] + f_tire_radius],
					[frontWheelPos[0] - f_tire_radius, frontWheelPos[1] + f_tire_radius]
					])" />
					<!-- Front wheel path -->
					<v-line :config="configPathLine([pointsCanvas[19], frontWheelPos])" />
					<!-- Front Triangle Group -->
					<v-group>
						<!-- Flip chip lines -->
						<template v-for="(item, index) in frameChipLines()">
							<v-circle :key="'konva-frame-chip-point-' + index" :config="configFramePoint(item.point)" @mouseover="tooltipEnter($event)" @mouseout="tooltipLeave" />
							<v-line :config="configChipLines(item.line)" :key="'frame-chip-line-'+index" />
						</template>
						<!-- Main frame elements -->
						<v-line :config="configSTLine()" />
						<v-line :config="configFrame()" />
						<v-circle v-for="(point, index) in computeGeoPoints" :key="'konva-frame-point-' + index" :config="configFramePoint(point)" @mouseover="tooltipEnter($event)" @mouseout="tooltipLeave" />
						<v-line v-if="showRider" :config="configRider()" />
					</v-group>
					<!-- Front and Rear Wheels and Wheel Points -->
					<v-circle :config="configWheel(frontWheelPos, f_tire_radius)" ref="frontWheel" />
					<v-circle :config="configWheel(pointsCanvas[1], r_tire_radius)" ref="rearWheel" />
					<v-circle :config="configPoint(frontWheelPos, 19)" @mouseover="tooltipEnter($event, 19)" @mouseout="tooltipLeave" />
					<!-- Chainring -->
					<v-circle ref="chainring" :config="configSprocket(pointsCanvas[0], drivetrainInfo.chainringSize)" />
					<!-- Upper and Lower Shock Points -->
					<v-path :config="configShock('upper_sd')" ref="shock_upper" />
					<v-path :config="configShock('lower_sd')" ref="shock_lower" />
					<!-- BB Points -->
					<v-circle ref="origin" :config="configPoint(originPoint, 0, true)" @mouseover="tooltipEnter($event, 'origin')" @mouseout="tooltipLeave" />
					<v-circle ref="bb_point" :config="configPoint(pointsCanvas[0], 0)" @mouseover="tooltipEnter($event, 0)" @mouseout="tooltipLeave" />
					<!-- Sliders -->
					<v-line v-if="solverInfo.sliderRef === 2" :config="configSliderLink()" />
					<template v-if="solverInfo.sliderRef === 2">
						<v-circle v-for="pointNum in [6,7]" :config="configPoint(pointsCanvas[pointNum], pointNum)" :key="'konva-slider' + '-point-' + pointNum" @mouseover="tooltipEnter($event, pointNum)" @mouseout="tooltipLeave" @dragmove="pointDrag($event, pointNum)" @dragend="pointDragEnd($event, pointNum)" @dragstart="pointDragStart($event, pointNum)" />
					</template>
				</v-layer>
				<!--  -->
				<!--  -->
				<!--  -->
				<v-layer :config="configLayer()" ref="layer_linkage">
					<!-- Sprocket Objects -->
					<v-group ref="cassette" :config="configCassette(pointsCanvas[1])">
						<v-circle v-for="(item, index) in drivetrainInfo.cassetteSize" :config="configSprocket(pointsCanvas[1], drivetrainInfo.cassetteSize[index])" :key="'konva-cassette-sprocket-' + index" />
					</v-group>
					<v-circle ref="idler" :config="configSprocket(pointsCanvas[11], drivetrainInfo.idlerSize, usesIdler)" />
					<!-- Rear Wheel Path -->
					<v-line :config="configPathLine(path_rearWheel)" />
					<!-- Link groups -->
					<v-group v-for="(item) in linkListCalc" :ref="'groupLink'+item.linkNum" :key="'konva-group-link-' + item.linkNum" :config="item.groupConfig">
						<!-- Linkage Flip Chip Lines -->
						<v-line v-for="(line, lineNum) in item.chipLines" :config="configChipLines(line, 'black')" :key="'chip-line-'+lineNum" />
						<v-circle v-for="(point, pointIdx) in item.defaultPoints" :key="'konva-frame-point-' + pointIdx" :config="configFramePoint(point, 'black')" @mouseover="tooltipEnter($event)" @mouseout="tooltipLeave" />
						<!-- Link perimeter -->
						<v-line :config="item.config" :key="'konva-bike-link-' + item.linkNum" />
						<!-- Blocks for slider elements -->
						<v-rect ref="slider1" v-if="item.linkNum === 2 && solverInfo.sliderRef === 1" :config="configSliderPoint(1)" />
						<v-rect ref="slider2" v-if="item.linkNum === 3 && solverInfo.sliderRef === 2" :config="configSliderPoint(0)" />
						<!-- Linkage points -->
						<v-circle v-for="pointNum in item.points" :config="configPoint(pointsCanvas[pointNum], pointNum)" :key="'konva-link-' + item.linkNum + '-point-' + pointNum" @mouseover="tooltipEnter($event, pointNum)" @mouseout="tooltipLeave" @dragmove="pointDrag($event, pointNum)" @dragend="pointDragEnd($event, pointNum)" @dragstart="pointDragStart($event, pointNum)" @mousedown="selectedPoint = pointNum > 1 ? pointNum : selectedPoint" />
					</v-group>
					<!-- Points for drivetrain and shock -->
					<v-circle v-if="solverInfo.shock1AttachedTo === 0" :config="configPoint(pointsCanvas[2], 2)" @mouseover="tooltipEnter($event, 2)" @mouseout="tooltipLeave" @dragmove="pointDrag($event, 2)" @dragend="pointDragEnd($event, 2)" @dragstart="pointDragStart($event, 2)" @mousedown="selectedPoint = 2" />
					<v-circle v-if="solverInfo.shock2AttachedTo === 0" :config="configPoint(pointsCanvas[3], 3)" @mouseover="tooltipEnter($event, 3)" @mouseout="tooltipLeave" @dragmove="pointDrag($event, 3)" @dragend="pointDragEnd($event, 3)" @dragstart="pointDragStart($event, 3)" @mousedown="selectedPoint = 3" />
					<v-circle v-if="solverInfo.idlerAttachedTo === 0 && usesIdler" :config="configPoint(pointsCanvas[11], 11)" @mouseover="tooltipEnter($event, 11)" @mouseout="tooltipLeave" @dragmove="pointDrag($event, 11)" @dragend="pointDragEnd($event, 11)" @dragstart="pointDragStart($event, 11)" @mousedown="selectedPoint = 11" />
				</v-layer>
			</v-stage>
		</div>
		<div v-if="isBikesLoaded" class="bikeplot__plot-controls">
			<RangeSlider :width="'100px'" @input="moveLinkage" :value="sliderPos" />
			<RangeSlider :width="'100px'" @input="moveFork" ref="frontSlider" :value="frontSliderPos" />
		</div>
		<BikePlotInfoPanel v-if=" this.width > 350 && isBikesLoaded && !isMobile" :pos="sliderPos/100" />
		<img v-show="false" v-if="showImage()" ref="bikeImage" :src="bikeImageURL" alt="reference image" @load="imageLoad">
		<div id="bike-plot-font-size" :style="{fontSize: '1rem', height: 0}"></div>
	</div>
</template>
<script>
const debug = false;

import BikePlotInfoPanel from "@/components/levapp/BikePlotInfoPanel.vue";
import RangeSlider from '@/components/functional/RangeSlider.vue';
import resize from "vue-resize-directive";
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from "vuex";
import { fourBarSolve, grahamScan, commonTan, theta_x, pointToLine, magnitude, modPoint } from "@/modules/computeLinkage.js";
import { bodyPoints } from "@/modules/computeCOG.js";
import { sinD, cosD, copy, round } from "@/modules/utility.js";

export default {
	name: "BikePlot",

	directives: {
		resize,
	},

	components: {
		RangeSlider,
		BikePlotInfoPanel
	},

	data() {
		return {
			tooltip: {
				content: ``,
				classes: 'tooltip-plot',
				delay: { show: 0, hide: 0 },
				placement: 'auto',
				boundariesElement: '.spa-cont',
				trigger: 'manual',
				show: false,
			},

			tooltipContent: {
				heading: '',
				text: '',
			},

			//Stage size
			width: 300,
			height: 300,

			//Bike image properties
			image: null,
			imageHeight: 0,
			imageWidth: 0,

			plotWidthUnits: 2500, //Initial width of plot area in mm
			snapRound: 100, //Rounding of values to be put into store
			pointRadius: 5, //Radius of draggable points
			scale: 1, //Initial scale

			sliderPos: 0,
			frontSliderPos: 0,

			p_wheelbase1: [0, 0], //Wheelbase tangent points
			p_wheelbase2: [0, 0],
			p_wheelbase3: [0, 0], //Front wheelbase tangent points
			p_wheelbase4: [0, 0],

			//Save path of rear wheel for display
			path_rearWheel: [
				[0, 0]
			],

			//Track font size for tooltips
			fontSize: 12,
			fontScale: 1,

			pointColor: this.$colorPrimary,
			frameColor: '#9B999E',
			drivetrainColor: '#575658',
			pathColor: 'grey',

			selectedPoint: 5,
			solvingJog: false,

			startDragPoint: [],
			startMovePoint: [],
			isPressed: false,

			dragStartPosition: [],

			showRider: false,
		};
	},

	mounted() {
		//Get window size on mounting
		const font = window.getComputedStyle(document.getElementById('bike-plot-font-size')).fontSize;
		this.fontSize = 1.25 * parseInt(font);
		this.fontScale = this.fontSize / 12;
		this.onResize();
	},

	watch: {
		//Default image to null and wait for succesful load
		'$store.state.stateViewLev.bikeImageURL'() { this.image = null; },
		'$store.state.stateViewLev.coordinateSystem'() { this.refreshPlot(); },
		sliderRef: { handler() { this.refreshPlot(); } },
		shockState: { handler() { this.refreshPlot(); } },
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapGetters('stateViewLev', ['getViewData']),
		...mapState('stateViewLev', ['bikeImageScale', 'bikeImageScale', 'bikeImageURL', 'showForceTooltips',
			'bikeImageRotation', 'showBikeImage', 'imgx', 'imgy', 'plotMoveRes', 'isMobile'
		]),
		...mapState('stateBikeData', ['bikeViewArray']),

		//Basic bike data
		currentBikeIndex: function() { return this.getData('selectedBikeIndex'); },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length; },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false; },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex); },
		kinData: function() { return this.bikeViewArray[this.currentBikeIndex].kinematicData; },

		//Collect chip data
		currentChipIndex: function() { return this.bikeData.solverInfo.selectedChip; },
		chipData: function() {
			// For invalid solutions we use the default chip data for display
			if (this.bikeData.solverInfo.chipSolution.error) {
				return this.bikeData.solverInfo.chipConfigs[0];
			} else {
				return this.bikeData.solverInfo.chipConfigs[this.currentChipIndex];
			}
		},
		chipSolution: function() { return this.bikeData.solverInfo.chipSolution; },

		//Break out some bike properties for brevity
		solverInfo: function() { return this.getBikeData(this.currentBikeIndex).solverInfo; },
		drivetrainInfo: function() { return this.getBikeData(this.currentBikeIndex).drivetrainInfo; },
		usesIdler: function() { return this.isBikesLoaded ? (this.bikeData.solverInfo.usesIdler ? true : false) : true },
		bikeColor: function() { return this.getData('bikeViewArray')[this.currentBikeIndex].color },
		sliderRef: function() { return this.isBikesLoaded ? this.bikeData.solverInfo.sliderRef : {} },
		shockState: function() { return this.isBikesLoaded ? this.solverInfo.shockMirror + this.solverInfo.shockFlip : '' },
		shockStroke: function() { return this.solverInfo.shockStroke + this.chipData.shockStroke; },

		//List of points to tab through
		tabbingPoints: function() {
			const tabPoints = [2, 3, 4]
			if (this.solverInfo.linkageType >= 1) { tabPoints.push(5, 6, 7); }
			if (this.solverInfo.linkageType === 2) { tabPoints.push(8, 9, 10); }
			if (this.solverInfo.usesIdler) { tabPoints.push(11); }
			if (this.solverInfo.usesBrakeArm) { tabPoints.push(12, 13); }

			return tabPoints;
		},

		//Bike points in canvas unit space
		pointsCanvas: function() {
			//Use chip points to display
			const pointsCanvas = this.chipSolution.points.map((item) => {
				return [this.unitX(item[0]), this.unitY(item[1])];
			});

			//Handle virtual points for slider
			if (this.solverInfo.sliderRef > 0) {
				const slider_angle = theta_x(pointsCanvas[7], pointsCanvas[6]) + Math.PI / 2;
				const new_p = [
					Math.cos(slider_angle) * 10000 + pointsCanvas[7][0],
					Math.sin(slider_angle) * 10000 + pointsCanvas[7][1],
				];
				if (this.solverInfo.sliderRef === 1) { pointsCanvas[6] = new_p; }
				if (this.solverInfo.sliderRef === 2) { pointsCanvas[7] = new_p; }
			}

			return pointsCanvas;
		},

		//Origin point in canvas space
		originPoint: function() { return [this.unitX(0), this.unitY(0)] },

		//Returns the non-shifted point 6 and 7 for display when using sliders
		sliderDisplayPoints: function() {
			return [
				[this.unitX(this.chipSolution.points[6][0]), this.unitY(this.chipSolution.points[6][1])],
				[this.unitX(this.chipSolution.points[7][0]), this.unitY(this.chipSolution.points[7][1])],
			];
		},

		//Returns rotation point number with associated link numbers [linkNum, rotatePoint]
		rotateList: function() {
			const rotateList = [
				[1, 4]
			];
			if (this.solverInfo.linkageType === 1) { rotateList.push([2, 5], [3, 7]); }
			if (this.solverInfo.linkageType === 2) { rotateList.push([2, 5], [3, 7], [4, 8], [5, 9]); }
			if (this.solverInfo.usesBrakeArm) { rotateList.push([6, 1], [7, 12]); }

			return rotateList;
		},

		//Attachments for each link in format [linkAttachedTo, pointToBeAttached]
		attachments: function() {
			const attachments = [
				[this.solverInfo.bbAttachedTo, 0],
				[this.solverInfo.wheelAttachedTo, 1],
				[this.solverInfo.shock1AttachedTo, 2],
				[this.solverInfo.shock2AttachedTo, 3]
			];

			if (this.solverInfo.linkageType === 2) {
				attachments.push([this.solverInfo.link4AttachedTo, 8], [this.solverInfo.link5AttachedTo, 10]);
			}

			if (this.solverInfo.usesIdler) {
				attachments.push([this.solverInfo.idlerAttachedTo, 11])
			}

			if (this.solverInfo.usesBrakeArm) {
				attachments.push([this.solverInfo.brakeAttachedTo, 13]);
			}

			if (this.solverInfo.usesDAQ) {
				attachments.push([this.solverInfo.DAQ1AttachedTo, 14], [this.solverInfo.DAQ2AttachedTo, 15]);
			}

			this.refreshPlot('attach move'); //Refresh when link attachments change

			return attachments;
		},

		//Ratio between mm and canvas (mm*number = canvasUnits)
		ratio: function() { return this.width / this.plotWidthUnits; },

		//Compute the various frame points 
		computeGeoPoints: function() {
			//Create shorthand input variables for convenience
			const geo = copy(this.bikeData.geometry);
			const size = this.bikeData.selectedSize;

			//Augment measurements with chip offsets
			const chipList = ['stemLength', 'stemSpacers', 'barHeight', 'lowerHeadset', 'hta', 'reach', 'forkLength', 'offset']

			chipList.forEach(key => {
				geo[key][size] += this.chipData[key]
			});

			const cos_hta = cosD(geo.hta[size]);
			const sin_hta = sinD(geo.hta[size]);

			const fw = this.chipSolution.points[19]; //Save front wheel points for chip
			// const fw = this.chipData.points[19]; //Save front wheel points

			let htTop = [geo.reach[size] + geo.bbOffset[size], geo.stack[size]];

			let htBottom = [];
			htBottom[0] = htTop[0] + cos_hta * geo.htl[size];
			htBottom[1] = htTop[1] - sin_hta * geo.htl[size];

			let lcsBottom = [];
			lcsBottom[0] = htBottom[0] + cos_hta * geo.lowerHeadset[size];
			lcsBottom[1] = htBottom[1] - sin_hta * geo.lowerHeadset[size];

			let forkBottom = [];
			forkBottom[0] = lcsBottom[0] + cos_hta * geo.forkLength[size];
			forkBottom[1] = lcsBottom[1] - sin_hta * geo.forkLength[size];

			let saddle = [];
			saddle[0] = geo.bbOffset[size] - cosD(geo.sta_eff[size]) * geo.nsh[size];
			saddle[1] = sinD(geo.sta_eff[size]) * geo.nsh[size];

			//NOTE we will consider the distance from saddle to top of ST on actual line
			//This will introduce some error as actual and effective STA differ
			let stTop = [];
			stTop[0] = saddle[0] + cosD(geo.sta_act[size]) * (geo.nsh[size] - geo.stl[size]);
			stTop[1] = saddle[1] - sinD(geo.sta_act[size]) * (geo.nsh[size] - geo.stl[size]);

			let stMid = [];
			stMid[0] = stTop[0] + cosD(geo.sta_act[size]) * (geo.insertion[size]);
			stMid[1] = stTop[1] - sinD(geo.sta_act[size]) * (geo.insertion[size]);

			const totalStack = geo.stemSpacers[size] + geo.upperHeadset[size] + geo.stemStack[size];

			let stemMid = []
			stemMid[0] = htTop[0] - cos_hta * totalStack;
			stemMid[1] = htTop[1] + sin_hta * totalStack;

			let barCenter = []
			barCenter[0] = stemMid[0] + cosD(90 - geo.hta[size]) * geo.stemLength[size];
			barCenter[1] = stemMid[1] + sinD(90 - geo.hta[size]) * geo.stemLength[size];

			let handPos = [barCenter[0], barCenter[1] + geo.barHeight[size]];

			const framePoints = {
				handPos,
				barCenter,
				stemMid,
				htTop,
				htBottom,
				lcsBottom,
				forkBottom,
				saddle,
				stTop,
				stMid,
				fw
			};

			// Add in rider body points for rider
			if (this.showRider) {
				const riderPoints = bodyPoints(geo.riderHeight[size], handPos);
				for (let key in riderPoints) { framePoints[key] = riderPoints[key]; }
			}

			for (let key in framePoints) {
				framePoints[key] = [this.unitX(framePoints[key][0]), this.unitY(framePoints[key][1])];
			}

			return framePoints;
		},

		//Returns an array containing the Konva configuration of each link
		linkListCalc: function() {
			let linkList = [1]; //Line 1 always exists
			if (this.solverInfo.linkageType === 1) { linkList.push(2, 3); }
			if (this.solverInfo.linkageType === 2) { linkList.push(2, 3, 4, 5); }
			if (this.solverInfo.usesBrakeArm) { linkList.push(6, 7); }

			return linkList.map((item) => { return this.configLink(item); });
		},

		//Track front wheel position from fork travel slider
		//	Note: the nextTick refresh on this is used to rebuild
		//		dragMove event. Changes here should be watched
		frontWheelPos: function() {
			//Must add change in HTA from chip configuration
			const hta = this.bikeData.geometry.hta[this.bikeData.selectedSize] + this.chipData['hta'];
			const travel = this.frontSliderPos / 100 * this.solverInfo.forkTravel;
			const d_x = cosD(hta) * travel;
			const d_y = sinD(hta) * travel;
			const point = this.chipSolution.points[19];

			this.refreshPlot('fw move');
			return [this.unitX(point[0] - d_x), this.unitY(point[1] + d_y)]
		},

		f_tire_radius: function() {
			let tireDiameter = this.bikeData.drivetrainInfo.tireDiameter_front;
			tireDiameter = this.chipData.frontWheel === 0 ? tireDiameter : this.chipData.frontWheel;
			return tireDiameter / 2 * this.ratio;
		},

		r_tire_radius: function() {
			let tireDiameter = this.bikeData.drivetrainInfo.tireDiameter;
			tireDiameter = this.chipData.rearWheel === 0 ? tireDiameter : this.chipData.rearWheel;
			return tireDiameter / 2 * this.ratio;
		},
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),
		...mapActions('stateViewLev', ['setViewData']),

		//Refresh movement state of the bike after data updates
		refreshPlot: function(debugMsg = '') {
			if (this.isBikesLoaded) {
				Vue.nextTick(() => {
					// DOM updated
					if (debug && debugMsg) { console.log(debugMsg); }
					this.moveLinkage(this.sliderPos);
				});
			}
		},

		//Commit keypress to vuex store
		commitKeypress: function(x_or_y, dir) {
			this.setData({
				item: 'points',
				value: this.bikeData.points[this.selectedPoint][x_or_y] + dir * this.plotMoveRes,
				row: this.selectedPoint,
				column: x_or_y,
				commit: 'PUTBIKEPOINTDATA',
				noRecord: true,
				// notReactive: true,
			});
		},

		//Handle keypresses for tabbing between points and arrow key movement
		handleKeypress: function(event) {
			if (event.key === 'q') {
				let idx = this.tabbingPoints.findIndex((elem) => elem === this.selectedPoint);
				if (typeof idx === 'undefined' || idx === this.tabbingPoints.length - 1) { idx = -1; }
				this.selectedPoint = this.tabbingPoints[idx + 1];
				return;
			}

			if (event.key === 'w') {
				let idx = this.tabbingPoints.findIndex((elem) => elem === this.selectedPoint);
				if (typeof idx === 'undefined' || idx === 0) { idx = this.tabbingPoints.length; }
				this.selectedPoint = this.tabbingPoints[idx - 1];
				return;
			}

			if (!this.isPressed) {
				this.isPressed = true;
				this.startMovePoint = [this.bikeData.points[this.selectedPoint][0], this.bikeData.points[this.selectedPoint][1]];
			}

			if (event.key === 'ArrowLeft') { this.commitKeypress(0, -1); }
			if (event.key === 'ArrowRight') { this.commitKeypress(0, 1); }
			if (event.key === 'ArrowDown') { this.commitKeypress(1, -1); }
			if (event.key === 'ArrowUp') { this.commitKeypress(1, 1); }
		},

		handleKeyup: function(event) {
			this.isPressed = false;
			if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {

				const index = this.selectedPoint;
				const point = [this.bikeData.points[index][0], this.bikeData.points[index][1]];
				this.setData({
					item: 'points',
					subItem: index,
					value: point,
					commit: 'PUTBIKEDATA',
					previousValue: this.startMovePoint,
				});

				if (this.getViewData('liveMode')) { this.sendAndSolve(); }
			}
		},

		imageLoad: function() {
			this.imageWidth = this.$refs.bikeImage.width;
			this.imageHeight = this.$refs.bikeImage.height;
			this.image = this.$refs.bikeImage;
		},

		showImage: function() {
			if (this.showBikeImage) {
				return true;
			} else {
				this.image = null;
				return false;
			}
		},

		imgDrag: function(event) {
			this.setViewData({
				item: 'imgx',
				value: event.target.position().x / this.ratio,
			});
			this.setViewData({
				item: 'imgy',
				value: event.target.position().y / this.ratio,
			});
		},

		//Convert from cartesian TO canvas coordinates
		unitX: function(val) { return val * this.ratio + this.width * 2 / 5; },
		unitY: function(val) { return this.height * 2 / 3 - val * this.ratio },

		//Convert from canvas TO cartesian coordinates
		canvX: function(val) { return (val - this.width * 2 / 5) / this.ratio; },
		canvY: function(val) { return (this.height * 2 / 3 - val) / this.ratio },

		onResize: function() {
			if (!(this.$refs.konvaBikeWindow)) { return; }
			this.altPoints = [];
			this.width = this.$refs.konvaBikeWindow.clientWidth;
			this.height = this.$refs.konvaBikeWindow.clientHeight;
			this.refreshPlot('resize move');
		},

		onZoom: function(e) {
			const stage = this.$refs.stage.getStage();
			const scaleBy = 1.02;
			e.evt.preventDefault();
			const oldScale = stage.scaleX();

			const mousePointTo = {
				x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
				y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
			};

			const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
			stage.scale({ x: newScale, y: newScale });

			const newPos = {
				x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
				y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
			};

			//Don't scale strokes
			const lineScale = e.evt.deltaY > 0 ? 1.02 : 1 / 1.02;
			stage.find('Shape').forEach((item) => {
				item.strokeWidth(item.strokeWidth() / lineScale);
			});

			//Don't scale movable points
			stage.find('.kinematic_point').forEach((item) => {
				item.radius((this.pointRadius / newScale));
			});

			//Don't scale frame points
			stage.find('.frame_point').forEach((item) => {
				item.radius((this.pointRadius / (1.5 * newScale)));
			});

			stage.position(newPos); //Move stage to pointer while zooming
			stage.batchDraw(); //Draw all
		},

		//Combine tooltip header and body text and show tooltip
		setTooltipText() {
			this.tooltip.content = this.tooltipContent.header + this.tooltipContent.text;
			this.$nextTick(() => {
				this.tooltip.show = true;
			});
		},

		//Mouse in handler for points
		tooltipEnter: function(event, pointNumber) {
			const linkNames = {
				2: 'Link ' + this.solverInfo.shock1AttachedTo,
				3: 'Link ' + this.solverInfo.shock2AttachedTo,
				4: 'Frame',
				5: 'Link 2',
				6: 'Link 2',
				7: 'Frame',
				8: 'Link ' + this.solverInfo.link4AttachedTo,
				9: 'Link 4',
				10: 'Link ' + this.solverInfo.link5AttachedTo,
			};

			for (const key in linkNames) { if (linkNames[key] === 'Link 0') { linkNames[key] = 'Frame'; } }

			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			//Scale point up on hover
			event.target.to({
				scaleX: 1.25,
				scaleY: 1.25,
				duration: 0.2
			});

			//Get event and origin position
			const origin = this.$refs.origin.getNode().getAbsolutePosition();
			let x = event.target.getAbsolutePosition().x;
			let y = event.target.getAbsolutePosition().y;

			//Position and show v-tooltip
			this.$refs.bikeplot__tooltip.style.left = x - 6 + 'px';
			this.$refs.bikeplot__tooltip.style.top = y - 6 + 'px';

			//Convert to mm units for display
			x = (x - origin.x) / this.ratio / scale;
			y = (-y + origin.y) / this.ratio / scale;

			this.tooltipContent.header = '<b>Point:</b>';
			if (typeof pointNumber !== 'undefined') {
				if (pointNumber === 'origin') {
					this.tooltipContent.header = '<b>Origin:</b>'
				} else {
					this.tooltipContent.header = `<b>Point: ${this.getData('pointNames')[pointNumber]}</b>`;
				}
			}

			this.tooltipContent.text = `<br>x: ${round(x,2)} &nbsp&nbsp y: ${round(y,2)}`

			//If force tooltips are enabled and data exists then plot
			if (this.showForceTooltips && this.kinData.forces && pointNumber > 1 && pointNumber < 11) {
				const posIdx = Math.round(this.sliderPos / 100 * this.shockStroke);
				const refNum = (pointNumber - 2) * 2;
				let x_force = round(this.kinData.forces[posIdx][refNum], 2);
				let y_force = round(this.kinData.forces[posIdx][refNum + 1], 2);

				//If wheel force is loaded then multiply
				if (this.kinData.wheel_force[this.kinData.wheel_force.length - 1] > 0) {
					x_force = round(x_force * this.kinData.wheel_force[posIdx], 1) + 'N';
					y_force = round(y_force * this.kinData.wheel_force[posIdx], 1) + 'N';
				}
				this.tooltipContent.text += `<br><p><b>Force: ${linkNames[pointNumber]}</b>` +
					`<br>x: ${x_force} &nbsp&nbsp y: ${y_force} </p>`;
			}
			this.setTooltipText();
		},

		//Mouse out handler for points
		tooltipLeave: function(event) {
			this.tooltip.show = false;

			//Scale point to normal on hover end
			event.target.to({
				scaleX: 1,
				scaleY: 1,
				duration: 0.2
			});
		},

		//Handle initiating point drag
		pointDragStart: function(event, index) {
			this.dragStartPosition = [event.target.position().x, event.target.position().y];
			this.startDragPoint = [this.bikeData.points[index][0], this.bikeData.points[index][1]];
		},

		//Show tooltip after dragging point
		pointDragEnd: function(event, index) {
			const point = [this.bikeData.points[index][0], this.bikeData.points[index][1]];
			this.setData({
				item: 'points',
				subItem: index,
				value: point,
				commit: 'PUTBIKEDATA',
				previousValue: this.startDragPoint,
			});

			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			const origin = this.$refs.origin.getNode().getAbsolutePosition();

			let x = event.target.getAbsolutePosition().x;
			let y = event.target.getAbsolutePosition().y;

			//Position and show v-tooltip
			this.$refs.bikeplot__tooltip.style.left = x - 6 + 'px';
			this.$refs.bikeplot__tooltip.style.top = y - 6 + 'px';

			x = (x - origin.x) / this.ratio / scale;
			y = (-y + origin.y) / this.ratio / scale;

			this.tooltipContent.text = `<br>x: ${round(x,2)} &nbsp&nbsp y: ${round(y,2)}`
			this.setTooltipText();

			if (this.getViewData('liveMode')) { this.sendAndSolve(); }
		},

		//Handle drag move of kinematic points
		pointDrag: function(event, index) {
			this.tooltip.show = false;

			const dx = (event.target.position().x - this.dragStartPosition[0]) / this.ratio;
			const dy = (event.target.position().y - this.dragStartPosition[1]) / this.ratio;
			this.dragStartPosition = [event.target.position().x, event.target.position().y];

			this.setData({
				item: 'points',
				// value: Math.round(this.snapRound * this.canvX(event.target.position().x)) / this.snapRound,
				value: this.bikeData.points[index][0] + dx,
				row: index,
				column: 0,
				commit: 'PUTBIKEPOINTDATA',
				notReactive: true,
				noRecord: true,
			});
			this.setData({
				item: 'points',
				// value: Math.round(this.snapRound * this.canvY(event.target.position().y)) / this.snapRound,
				value: this.bikeData.points[index][1] - dy,
				row: index,
				column: 1,
				commit: 'PUTBIKEPOINTDATA',
				noRecord: true,
				// notReactive: true,
			});

			//NO REFRESH NEEDED. Changed to the front wheel positon will trigger refresh
			// this.refreshPlot('drag move');
		},

		// Konva component configuration
		configKonva: function() {
			return {
				width: this.width,
				height: this.height,
				draggable: true,
			};
		},

		//Layer config. Rotation centre at bb
		configLayer: function() {
			return {
				offsetX: this.pointsCanvas[0][0],
				offsetY: this.pointsCanvas[0][1],
				x: this.pointsCanvas[0][0],
				y: this.pointsCanvas[0][1],
			};
		},

		configPoint: function(point_in, index, isOrigin = false) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			if (this.solverInfo.sliderRef === 1 && index === 6) {
				point_in = this.sliderDisplayPoints[0];
			}
			if (this.solverInfo.sliderRef === 2 && index === 7) {
				point_in = this.sliderDisplayPoints[1];
			}

			const fixedPoints = [0, 1, 19];

			const config = {
				name: 'kinematic_point',
				x: point_in[0],
				y: point_in[1],
				radius: this.pointRadius / scale,
				fill: isOrigin ? this.frameColor : this.pointColor,
				stroke: "black",
				strokeWidth: 2 / scale,
				draggable: fixedPoints.includes(index) ? false : true,
			};

			if (this.selectedPoint === index) { config.fill = this.frameColor }
			return config;
		},

		configFramePoint: function(point_in, color = this.frameColor) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}
			return {
				name: 'frame_point',
				x: point_in[0],
				y: point_in[1],
				radius: this.pointRadius / (1.5 * scale),
				fill: color,
				stroke: color,
				strokeWidth: 2 / scale,
			};
		},

		configWheel: function(point, radius) {
			return {
				x: point[0],
				y: point[1],
				radius: radius,
				fill: 'transparent',
				stroke: this.frameColor,
				strokeWidth: 7,
				draggable: false,
				listening: false,
			};
		},

		configFrame: function() {
			const geoPoints = this.computeGeoPoints;
			const pointArray = [
				geoPoints.handPos,
				geoPoints.barCenter,
				geoPoints.stemMid,
				geoPoints.htTop,
				geoPoints.stTop,
				geoPoints.stMid,
				this.pointsCanvas[0],
				geoPoints.htBottom,
				geoPoints.htTop,
				// geoPoints.htBottom,
				geoPoints.lcsBottom,
				geoPoints.forkBottom,
				this.pointsCanvas[19],
			];
			return {
				points: pointArray.flat(),
				fill: 'transparent',
				stroke: this.frameColor,
				strokeWidth: 4,
				closed: false,
				lineJoin: 'round',
				lineCap: 'round',
				offsetX: this.pointsCanvas[0][0],
				offsetY: this.pointsCanvas[0][1],
				position: {
					x: this.pointsCanvas[0][0],
					y: this.pointsCanvas[0][1],
				},
				listening: false,
			};
		},

		configRider: function() {
			const body = this.computeGeoPoints;

			console.log(body)
			const points = [
				body.foot_f,
				body.knee_f,
				body.hip,
				body.knee_r,
				body.foot_r,
				body.knee_r,
				body.hip,
				body.shoulder,
				body.elbow,
				body.handPos
			];

			return {
				points: points.flat(),
				fill: false,
				stroke: this.frameColor,
				strokeWidth: 4,
				closed: false,
				lineJoin: 'round',
				lineCap: 'round',
				offsetX: this.pointsCanvas[0][0],
				offsetY: this.pointsCanvas[0][1],
				position: {
					x: this.pointsCanvas[0][0],
					y: this.pointsCanvas[0][1],
				},
				listening: false,
			};
		},

		configSTLine: function() {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			const connPoints = [
				this.pointsCanvas[0][0],
				this.pointsCanvas[0][1],
				this.computeGeoPoints.saddle[0],
				this.computeGeoPoints.saddle[1],
				this.computeGeoPoints.stTop[0],
				this.computeGeoPoints.stTop[1],
			];

			return {
				points: connPoints,
				fill: 'transparent',
				stroke: 'grey',
				strokeWidth: 2 / scale,
				closed: false,
				lineJoin: 'round',
				dash: [2, 1],
				listening: false,
			};
		},

		//Setup properties for each konva link
		configLink: function(linkNumber) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			//Copy canvas points into new vector
			const displayPoints = copy(this.pointsCanvas);

			//Change display value for slider on link3 or frame
			if (this.solverInfo.sliderRef === 1) { displayPoints[6] = this.sliderDisplayPoints[0]; }
			if (this.solverInfo.sliderRef === 2) { displayPoints[7] = this.sliderDisplayPoints[1]; }

			//For slider on link3 put slider point at end of specified slider
			if (this.solverInfo.sliderRef === 1 && linkNumber === 2) {
				displayPoints[6] = displayPoints[7];
			}

			//Set base point set for each link number
			let idx1 = 4;
			let idx2 = 5;
			let pRot = 4;

			if (linkNumber === 2) { idx1 = 5, idx2 = 6, pRot = 5 }
			if (linkNumber === 3) { idx1 = 6, idx2 = 7, pRot = 7 }
			if (linkNumber === 4) { idx1 = 8, idx2 = 9, pRot = 8 }
			if (linkNumber === 5) { idx1 = 9, idx2 = 10, pRot = 9 }
			if (linkNumber === 6) { idx1 = 1, idx2 = 12, pRot = 1 }
			if (linkNumber === 7) { idx1 = 12, idx2 = 13, pRot = 12 }

			let linkPointIdxList = [idx1, idx2]; //Points to plot as draggable
			let link = [displayPoints[idx1], displayPoints[idx2]]; //Points contained in the link

			// Remove link1 to link2 connection for single pivot
			if (this.solverInfo.linkageType === 0 && linkNumber === 1) {
				linkPointIdxList.pop();
				link.pop();
			}

			if (linkNumber === 3 && this.solverInfo.sliderRef === 2) {
				link.pop();
				linkPointIdxList = [];
			}

			//Add attachement points to each link
			this.attachments.forEach((item) => {
				if (item[0] === linkNumber) {
					link.push(displayPoints[item[1]]);
					linkPointIdxList.push(item[1]);
				}
			});

			//Don't plot p6/7 on link2/3 for select slider configurations
			if (this.solverInfo.sliderRef === 1 && linkNumber === 2) {
				linkPointIdxList = linkPointIdxList.filter(e => { return e !== 6 })
				// Have link attached to both ends of slider
				link.push(this.secondSliderPoint(1));
			}
			if (this.solverInfo.sliderRef === 2 && linkNumber === 2) {
				linkPointIdxList = linkPointIdxList.filter(e => { return e !== 7 })
			}

			if (this.solverInfo.sliderRef === 2 && linkNumber === 3) {
				// Have link attached to both ends of slider
				link.push(this.secondSliderPoint(0));
			}

			const chipResults = this.chipLinePoints(linkNumber)

			//Graham scan to find perimeter then flatten 
			link = grahamScan(link.concat(chipResults.points)).flat();

			//Rainbow mode
			// let color = Konva.Util.getRandomColor();
			// let color = this.bikeColor;
			return {
				linkNum: linkNumber,
				points: linkPointIdxList, //Return all points in link
				defaultPoints: chipResults.points,
				chipLines: chipResults.lines,
				config: {
					points: link,
					fill: 'rgba(60, 4, 108, 0.61)',
					stroke: 'black',
					strokeWidth: 3 / scale,
					closed: true,
					lineJoin: 'round',
					visible: true,
					listening: false,
					// dash: [10, 2]
				},

				//Centre group on rotation point to allow easy linkage translation/rotation
				groupConfig: {
					offsetX: this.pointsCanvas[pRot][0],
					offsetY: this.pointsCanvas[pRot][1],
					position: {
						x: this.pointsCanvas[pRot][0],
						y: this.pointsCanvas[pRot][1],
					},
					rotation: 0,
				},
			};
		},

		//Find points for chips on each link
		chipLinePoints: function(linkNumber) {
			let idx1 = 4;
			let idx2 = 5;
			let pRot = 4;

			//For single pivots we cannot use p5 as link reference
			//Wheel has to be on swingarm so p1 can be used
			if (this.solverInfo.linkageType === 0) { idx2 = 1; }

			if (linkNumber === 2) { idx1 = 5, idx2 = 6, pRot = 5 }
			if (linkNumber === 3) { idx1 = 6, idx2 = 7, pRot = 7 }
			if (linkNumber === 4) { idx1 = 8, idx2 = 9, pRot = 8 }
			if (linkNumber === 5) { idx1 = 9, idx2 = 10, pRot = 9 }
			if (linkNumber === 6) { idx1 = 1, idx2 = 12, pRot = 1 }
			if (linkNumber === 7) { idx1 = 12, idx2 = 13, pRot = 12 }


			const extraLinkPoints = [];
			// Add attachement points to each link
			this.attachments.forEach((item) => {
				if (item[0] === linkNumber) { extraLinkPoints.push(item[1]); }
			});

			const points = this.bikeData.points;

			let p_start_nom = points[idx1];
			let p_end_nom = points[idx2];

			let p_start_mod = modPoint(points, idx1, 'secondary', this.chipData);
			let p_end_mod = modPoint(points, idx2, 'primary', this.chipData);

			let p_end_canv = this.pointsCanvas[idx2];
			let p_start_canv = this.pointsCanvas[idx1];

			//----------------------------------------------------------------
			//Reference points must be adjust for sliders due to virtual points
			if (linkNumber === 3 && this.solverInfo.sliderRef > 0) {
				p_start_mod = p_start_nom;
				p_end_mod = p_end_nom;

				p_start_canv = this.sliderDisplayPoints[0];
				p_end_canv = this.sliderDisplayPoints[1];
			}

			if (linkNumber === 2 && this.solverInfo.sliderRef === 1) {
				//For slider on link3 we must reference the virtual point to find changes
				const slider_angle = theta_x(points[7], points[6]) - Math.PI / 2;
				p_end_nom = [
					Math.cos(slider_angle) * 10000 / this.ratio + points[7][0],
					Math.sin(slider_angle) * 10000 / this.ratio + points[7][1],
				];

				p_end_mod = p_end_nom;
			}

			//----------------------------------------------------------------
			//Find positional changes per link and construct lines------------
			const p_start_nom_new = this.polarPoint(p_start_mod, p_end_mod, p_start_nom, p_start_canv, p_end_canv);
			const p_end_nom_new = this.polarPoint(p_start_mod, p_end_mod, p_end_nom, p_start_canv, p_end_canv);

			let chipIdx = [idx1]
			let chipPoints = [p_start_nom_new]; //Only the original points
			let chipLines = [
				[p_start_nom_new, p_start_canv]
			];

			//Only add the end point if it is not a single pivot (prevents dup. wheel point)
			if (this.solverInfo.linkageType > 0) {
				chipIdx.push(idx2);
				chipPoints.push(p_end_nom_new);
				chipLines.push([p_end_nom_new, p_end_canv]);
			}

			//Remove line for slider on frame
			if (linkNumber === 3 && this.solverInfo.sliderRef === 2) {
				chipLines.pop();
				chipPoints.pop();
				chipIdx.pop();
			}

			//Remove line for slider on link3
			if (linkNumber === 2 && this.solverInfo.sliderRef === 1) {
				chipLines.pop();
				chipPoints.pop();
				chipIdx.pop();
			}


			//Add all attachement points to link
			extraLinkPoints.forEach(p_num => {
				const p_nom = points[p_num];
				const p_nom_new = this.polarPoint(p_start_mod, p_end_mod, p_nom, p_start_canv, p_end_canv);

				chipIdx.push(p_num);
				chipPoints.push(p_nom_new);
				chipLines.push([p_nom_new, this.pointsCanvas[p_num]]);

			});

			return {
				lines: chipLines,
				points: chipPoints,
				pointIdx: chipIdx,
			};
		},

		//Find points for chips on frame
		frameChipLines: function() {
			const points = this.bikeData.points;
			const framePoints = [4]; //Frame always has p4
			if (this.solverInfo.linkageType > 0 && this.solverInfo.sliderRef !== 2) { framePoints.push(7); }

			//Add attachement points to each link
			this.attachments.forEach((item) => {
				if (item[0] === 0) { framePoints.push(item[1]); }
			});

			const chipLines = [];
			framePoints.forEach(p_num => {
				const p_start = [this.unitX(points[p_num][0]), this.unitY(points[p_num][1])];

				chipLines.push({
					line: [p_start, this.pointsCanvas[p_num]],
					point: p_start,
					pointIndex: p_num
				});
			});

			return chipLines;
		},

		//Find points with respect to critical linkage points using polar coordinates
		polarPoint: function(p1_old, p2_old, p3_old, p1_new, p2_new) {
			//Define p3 in polar coordinates with respect to p1 and p2
			const p3_polar = {
				r: magnitude(p1_old, p3_old) * this.ratio,
				t: theta_x(p1_old, p3_old) - theta_x(p1_old, p2_old)
			}

			//If the points are extremely close then return original point
			if (p3_polar.r < .0001) { return copy(p1_new); }

			//Get the new angle of line p1 to p2
			const d_theta = theta_x(p1_new, p2_new);

			return [
				p3_polar.r * Math.cos(-p3_polar.t + d_theta) + p1_new[0],
				p3_polar.r * Math.sin(-p3_polar.t + d_theta) + p1_new[1],
			]
		},

		//Create short lines for chip offsets
		configChipLines: function(points, color = this.frameColor) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			return {
				points: points.flat(),
				fill: 'transparent',
				stroke: color,
				strokeWidth: 5 / scale,
				closed: false,
				lineJoin: 'round',
				// dash: [2, 1],
				listening: false,
			};
		},

		secondSliderPoint: function(idx) {
			let idx2 = idx === 0 ? 1 : 0;

			const rad = 50 * this.ratio;
			const length = magnitude(this.sliderDisplayPoints[1], this.sliderDisplayPoints[0]);
			const ratio = rad / length;

			const x = (this.sliderDisplayPoints[idx2][0] - this.sliderDisplayPoints[idx][0]) * ratio + this.sliderDisplayPoints[idx][0];
			const y = (this.sliderDisplayPoints[idx2][1] - this.sliderDisplayPoints[idx][1]) * ratio + this.sliderDisplayPoints[idx][1];

			return [x, y];
		},

		//Show slider block for slider style linkages
		configSliderPoint: function(index) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}
			const otherPoint = index == 0 ? 1 : 0;
			//Angle slider along path
			const angle = theta_x(this.sliderDisplayPoints[index], this.sliderDisplayPoints[otherPoint]) * 180 / Math.PI;
			return {
				stroke: 'black',
				strokeWidth: 4 / scale,
				fill: 'grey',
				opacity: .9,
				width: 50 * this.ratio,
				height: 15 * this.ratio,
				offsetX: 0 * this.ratio,
				offsetY: 7.5 * this.ratio,
				x: this.sliderDisplayPoints[index][0],
				y: this.sliderDisplayPoints[index][1],
				rotation: angle,
				listening: false,
			}
		},

		//For slider on frame we show the slider length and two points on frame layer
		configSliderLink: function() {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}
			return {
				points: this.sliderDisplayPoints.flat(),
				closed: true,

				stroke: 'black',
				strokeWidth: 4 / scale,
				fill: 'grey',
				opacity: .9,

				offsetX: this.sliderDisplayPoints[0][0],
				offsetY: this.sliderDisplayPoints[0][1],
				x: this.sliderDisplayPoints[0][0],
				y: this.sliderDisplayPoints[0][1],
				listening: false,
			}
		},

		//Path movement line 
		configPathLine: function(points) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			return {
				points: points.flat(),
				fill: 'transparent',
				stroke: 'grey',
				strokeWidth: 2 / scale,
				closed: false,
				lineJoin: 'round',
				dash: [1, 1],
				listening: false,
			};
		},

		configSprocket: function(point, teethCount, init_vis = true) {
			let scale = 1;
			if (typeof this.$refs.stage !== 'undefined') {
				scale = this.$refs.stage.getStage().scaleX();
			}

			return {
				x: point[0],
				y: point[1],
				radius: 2.025 * teethCount * this.ratio,
				fill: "transparent",
				stroke: this.drivetrainColor,
				strokeWidth: 3 / scale,
				draggable: false,
				listening: false,
				visible: init_vis,
			};
		},

		configCassette: function(point) {
			return {
				offsetX: point[0],
				offsetY: point[1],
				x: point[0],
				y: point[1],
				draggable: false,
				listening: false,
			};
		},

		configShock: function(type) {
			let data = "";
			let isUpper = true //Connected to P3

			const stroke = this.shockStroke;

			//Negative addLength values break path so prevent them
			const addLength = this.solverInfo.shockExtLength > 0 ? this.solverInfo.shockExtLength : 0
			let scaleBodyLength = 1; //Scale body lenght for different stroke lengths

			if (type === 'lower_sd') {
				isUpper = false;
				data = `m -10 0 a 10 10 0 0 0 10 10 l 10 0 a 4.5 4.5 
					0 0 1 4.5 4.5 l ${85 + addLength} 
					0 l 0 -29 l -${85 + addLength} 0 
					a 4.5 4.5 0 0 1 -4.5 4.5 l -10 0 a 10 10 0 0 0 -10 10 z`;
			}
			if (type === 'upper_sd') {
				scaleBodyLength = stroke > 65 ? 1.08 : scaleBodyLength
				scaleBodyLength = stroke <= 65 ? 1 : scaleBodyLength
				scaleBodyLength = stroke <= 55 ? .92 : scaleBodyLength
				scaleBodyLength = stroke <= 45 ? .84 : scaleBodyLength
				data = `M 13.46,-27.32
					C 13.46,-27.32 13.46,-27.32 13.46,-27.32
					13.46,-27.32 13.46,-27.32 13.46,-27.32
					14.51,-26.56 14.84,-26.42 15.98,-26.38
					15.98,-26.38 19.85,-26.38 19.85,-26.38
					20.22,-26.40 20.53,-26.40 20.90,-26.38
					21.68,-26.13 23.92,-24.94 24.77,-24.51
					27.73,-23.03 28.86,-22.19 32.32,-22.18
					32.32,-22.18 82.76,-22.18 82.76,-22.18
					84.79,-22.18 84.21,-22.60 85.40,-22.86
					85.40,-22.86 88.74,-22.86 88.74,-22.86
					90.58,-22.88 90.14,-22.47 91.20,-22.21
					91.20,-22.21 94.89,-22.21 94.89,-22.21
					95.86,-22.18 97.49,-22.34 98.40,-22.61
					100.24,-23.16 99.80,-23.59 102.09,-23.55
					102.09,-23.55 111.06,-23.55 111.06,-23.55
					111.51,-23.59 112.03,-23.61 112.46,-23.55
					113.58,-23.13 115.56,-21.10 116.68,-20.32
					117.87,-19.50 119.12,-19.20 120.55,-19.03
					121.31,-19.18 122.90,-19.33 123.49,-19.03
					124.04,-18.46 124.05,-17.88 124.05,-17.25
					124.05,-17.25 124.05,-10.38 124.05,-10.38
					124.05,-10.38 124.05,16.92 124.05,16.92
					124.05,16.92 124.05,17.97 124.05,17.97
					123.79,19.24 122.80,19.20 121.78,19.21
					120.03,19.21 118.38,19.19 116.86,20.22
					115.79,20.95 113.57,23.11 112.64,23.55
					112.24,23.60 111.83,23.60 111.41,23.55
					111.41,23.55 101.92,23.55 101.92,23.55
					101.53,23.61 101.06,23.62 100.69,23.55
					99.53,23.29 98.91,22.40 96.65,22.38
					96.65,22.38 91.90,22.38 91.90,22.38
					89.59,22.38 90.35,23.05 88.21,23.08
					88.21,23.08 87.33,23.08 87.33,23.08
					84.68,23.08 85.24,22.41 83.46,22.40
					83.46,22.40 77.31,22.40 77.31,22.40
					77.31,22.40 65.01,22.40 65.01,22.40
					65.01,22.40 30.92,22.40 30.92,22.40
					30.31,22.38 29.94,22.36 29.34,22.40
					27.93,22.79 24.81,24.51 23.36,25.24
					22.05,25.90 21.36,26.41 19.85,26.58
					19.85,26.58 4.73,26.58 4.73,26.58
					4.73,26.58 2.62,26.58 2.62,26.58
					2.62,26.58 1.04,26.58 1.04,26.58
					1.04,26.58 -0.01,26.58 -0.01,26.58
					-0.01,26.58 -0.89,26.58 -0.89,26.58
					-0.89,26.58 -2.47,26.58 -2.47,26.58
					-3.51,26.79 -5.12,26.62 -6.13,26.58
					-7.09,26.18 -6.68,26.08 -7.92,26.07
					-7.92,26.07 -10.38,26.07 -10.38,26.07
					-12.04,26.07 -14.22,25.42 -15.40,24.21
					-15.75,23.85 -15.76,23.63 -15.97,23.34
					-16.23,23.01 -16.48,23.02 -16.70,22.66
					-17.05,22.08 -16.95,21.32 -17.12,20.69
					-17.27,20.15 -17.57,20.30 -17.69,18.68
					-17.69,18.68 -17.69,17.97 -17.69,17.97
					-17.69,17.97 -17.69,15.33 -17.69,15.33
					-17.69,15.33 -17.69,4.59 -17.69,4.59
					-17.69,4.59 -17.69,-14.78 -17.69,-14.78
					-17.69,-14.78 -17.19,-20.07 -17.19,-20.07
					-17.10,-20.65 -16.99,-22.04 -16.76,-22.50
					-16.54,-22.97 -16.29,-22.93 -16.05,-23.20
					-15.79,-23.49 -15.78,-23.80 -15.40,-24.19
					-14.53,-25.08 -13.87,-25.18 -12.86,-25.62
					-12.49,-25.77 -12.35,-25.91 -11.94,-26.13
					-11.94,-26.13 -10.38,-26.13 -10.38,-26.13
					-10.38,-26.13 -7.23,-26.13 -7.23,-26.13
					-7.23,-26.13 -5.81,-26.13 -5.81,-26.13
					-5.08,-26.46 -3.96,-26.44 -3.43,-27.15
					-3.30,-27.54 -3.37,-28.24 -3.43,-28.70
					-3.22,-29.77 -3.07,-30.84 -2.70,-31.87
					-2.25,-33.13 -1.08,-34.95 -1.55,-36.27
					-2.25,-38.26 -5.72,-39.48 -5.96,-42.26
					-6.04,-43.30 -5.23,-46.45 -4.69,-47.37
					-4.38,-47.91 -4.03,-48.27 -3.53,-48.64
					-2.52,-49.36 -0.56,-49.72 0.69,-50.11
					2.20,-50.57 4.21,-51.51 5.61,-52.26
					6.73,-52.85 8.40,-53.73 8.91,-54.94
					9.38,-56.06 8.91,-56.72 10.05,-58.99
					10.19,-59.27 10.41,-59.73 10.64,-59.99
					11.06,-60.31 12.61,-60.34 13.07,-59.99
					13.32,-59.89 13.41,-59.59 13.75,-59.41
					14.04,-59.32 14.60,-59.34 14.93,-59.41
					15.41,-59.34 16.78,-59.29 17.16,-59.41
					17.92,-59.73 17.46,-60.20 18.97,-60.22
					18.97,-60.22 21.95,-60.22 21.95,-60.22
					24.37,-60.19 26.63,-58.47 30.21,-58.72
					30.21,-58.72 61.15,-58.72 61.15,-58.72
					61.15,-58.72 63.08,-58.72 63.08,-58.72
					63.08,-58.72 65.89,-58.72 65.89,-58.72
					65.89,-58.72 67.82,-58.72 67.82,-58.72
					67.82,-58.72 72.92,-58.72 72.92,-58.72
					73.71,-58.98 74.36,-59.01 74.84,-58.27
					75.23,-57.65 75.20,-56.87 75.30,-56.17
					75.30,-56.17 75.30,-49.65 75.30,-49.65
					75.21,-49.21 75.15,-48.51 75.30,-47.94
					75.77,-47.89 76.23,-47.90 76.61,-47.94
					77.37,-47.88 79.93,-47.99 80.47,-47.94
					80.76,-47.64 80.95,-47.47 81.08,-47.17
					81.19,-46.89 81.18,-46.44 81.08,-46.13
					81.08,-46.13 81.08,-40.85 81.08,-40.85
					81.18,-40.37 81.23,-39.73 81.08,-39.08
					80.73,-39.15 80.54,-39.06 80.30,-39.08
					80.30,-39.08 76.96,-39.08 76.96,-39.08
					76.52,-38.91 75.82,-38.97 75.30,-38.63
					75.20,-38.34 75.21,-37.88 75.30,-37.50
					75.30,-37.50 75.30,-30.63 75.30,-30.63
					75.20,-28.94 75.08,-27.84 73.10,-28.04
					73.10,-28.04 67.47,-28.04 67.47,-28.04
					67.47,-28.04 63.25,-28.04 63.25,-28.04
					63.25,-28.04 51.13,-28.04 51.13,-28.04
					51.13,-28.04 29.16,-28.04 29.16,-28.04
					28.75,-28.17 28.33,-28.18 27.93,-28.04
					27.93,-28.04 23.01,-26.63 23.01,-26.63
					23.01,-26.63 18.97,-26.63 18.97,-26.63
					17.38,-26.61 17.91,-27.18 17.01,-27.32
					17.01,-27.32 13.46,-27.32 13.46,-27.32 Z`;
			}

			let mirror = 1;
			if (this.solverInfo.shockMirror) { mirror = -1; }

			isUpper = this.solverInfo.shockFlip ? !isUpper : isUpper;
			const shockType = isUpper ? 'upper' : 'lower';

			const pointIndex = isUpper ? 3 : 2;
			const pointRef = pointIndex === 3 ? 2 : 3;

			//Get initial angle of elements
			let shockAngle = theta_x(this.pointsCanvas[pointIndex], this.pointsCanvas[pointRef]) * 180 / Math.PI;
			shockAngle = isNaN(shockAngle) ? 0 : shockAngle;
			return {
				name: shockType,
				data: data,
				fill: 'black',
				stroke: 'black',
				opacity: .8,
				strokeWidth: 0,
				x: this.pointsCanvas[pointIndex][0],
				y: this.pointsCanvas[pointIndex][1],
				rotation: shockAngle,
				scaleX: scaleBodyLength * this.ratio,
				scaleY: mirror * this.ratio,
				listening: false,
			}
		},

		configImgLayer: function() {
			return {
				offsetX: -this.pointsCanvas[0][0],
				offsetY: -this.pointsCanvas[0][1],
				// x: this.pointsCanvas[0][0],
				// y: this.pointsCanvas[0][1],
			};
		},

		configImg: function() {
			return {
				image: this.image,
				draggable: true,
				x: this.imgx * this.ratio,
				y: this.imgy * this.ratio,
				width: this.imageWidth * this.bikeImageScale,
				height: this.imageHeight * this.bikeImageScale * 1.02,
				scaleX: this.ratio,
				scaleY: this.ratio,
				rotation: this.bikeImageRotation,
			}
		},

		moveLinkage: function(value) {
			this.sliderPos = value;
			let res = 1 * this.ratio; //Translate 1mm resolution into canvas space
			const path_rearWheel = [this.pointsCanvas[1]]; //Initialize rear wheel path vector
			let shockTravel = Math.round(value / 100 * this.shockStroke) * this.ratio;

			//SOLVE-----------------------------------------------------
			//----------------------------------------------------------
			//Take initial result to determine direction
			const initialResult = fourBarSolve(...this.pointsCanvas,
				this.solverInfo, res, .001, 0, false);

			//Calculate initial theta to get 1mm of shock travel
			let theta1 = initialResult[3] * initialResult[2]; //Initial d_theta
			let theta3 = initialResult[4] * initialResult[2]; //Altd_theta for folding linkage
			let loopDir = initialResult[5]; //Flag to use alt d_theta

			//Initialize storage vectors
			let tempPoints = copy(this.pointsCanvas);
			let result = [];

			//Insure loop runs once if travel request is zero. This will reset rotations
			if (value < 1) {
				theta1 = 0;
				theta3 = 0;
				loopDir = false;
				shockTravel = 1;
				res = 1;
			}

			let shockLength = [];
			let shockDir = this.solverInfo.usesPullShock ? -1 : 1;
			//Solve for all positions up to current
			for (let i = 0; i < shockTravel / res; i++) {
				const tempResult = fourBarSolve(...tempPoints, this.solverInfo,
					res, theta1, theta3, loopDir);
				shockLength[i] = magnitude(tempResult[1][2], tempResult[1][3]);

				// Cumulate results
				if (i === 0) {
					result = tempResult[0];
				} else {
					//Check for shock direction reversal
					if ((shockLength[i] - shockLength[i - 1]) * shockDir > 0) {
						this.sliderPos = (i / this.shockStroke) * 100;
						break;
					}
					tempResult[0].forEach((item, index) => {
						result[index][0] += item[0];
						result[index][1][0] += item[1][0];
						result[index][1][1] += item[1][1];
					});
				}

				theta1 = tempResult[3]; //Next d_theta
				theta3 = tempResult[4]; //Alternate d_theta for linkage folding
				loopDir = tempResult[5]; //Flag to use alternate d_theta

				//Check for NaN point values and break
				//	This will improve display perf. when invalid configurations are selected
				let breakNow = false;
				tempResult[1].forEach((item) => {
					if (isNaN(item[0]) || isNaN(item[1])) { breakNow = true; }
				})
				if (breakNow) {
					this.sliderPos = (i / this.shockStroke) * 100;
					break;
				}

				tempPoints = tempResult[1]; //Save points for next iteration
				path_rearWheel.push(tempPoints[1]); //Save intermediate position for path
			}

			//Assume a valid result
			let validResult = true;

			//Check for any NaN values
			result.flat().forEach((item) => {
				if (Number.isNaN(item)) { validResult = false; }
			});

			//SET KONVA POSITIONS---------------------------------------
			//----------------------------------------------------------

			//Set the rear wheel path points for plotting
			this.path_rearWheel = path_rearWheel;
			//If valid then set positions and rotations of all points
			if (validResult) {
				result.forEach((item, index) => {
					const linkRef = this.rotateList[index];
					const linkName = 'groupLink' + linkRef[0];

					// New link position is rotation point + offset from solver
					const x = this.pointsCanvas[linkRef[1]][0] + item[1][0];
					const y = this.pointsCanvas[linkRef[1]][1] + item[1][1];
					//Set position offset of link
					this.$refs[linkName][0].getNode().position({
						x: x,
						y: y,
					});
					//Set rotation of link group
					this.$refs[linkName][0].getNode().rotation(item[0] * 180 / Math.PI);

					//Alter the angle of the slider block if needed
					if (this.solverInfo.sliderRef === 2 && linkName === 'groupLink3') {
						const angle = theta_x(this.sliderDisplayPoints[1], this.sliderDisplayPoints[0]) * 180 / Math.PI;
						this.$refs.slider2[0].getNode().rotation(180 + angle - item[0] * 180 / Math.PI);
					}

				})

				//List of Vue references for attached components
				const itemRefs = ['rearWheel', 'chainring', 'cassette', 'idler'];
				const refPoints = [1, 0, 1, 11];

				if (this.solverInfo.usesIdler) {
					this.$refs['idler'].getNode().show();
				} else {
					this.$refs['idler'].getNode().hide();
				}

				//Move each accessory item to the approriate tempPoint
				itemRefs.forEach((item, index) => {
					this.$refs[item].getNode().position({
						x: tempPoints[refPoints[index]][0],
						y: tempPoints[refPoints[index]][1]
					})
				})

				//Check shock connections to determine orientation
				let connect1 = this.solverInfo.shockFlip ? 2 : 3;
				let connect2 = this.solverInfo.shockFlip ? 3 : 2;

				let shockAngle = theta_x(tempPoints[connect1], tempPoints[connect2]) * 180 / Math.PI;
				//Upper shock defaults to p3 unless flipped
				this.$refs.shock_upper.getNode().position({
					x: tempPoints[connect1][0],
					y: tempPoints[connect1][1]
				})
				//Lower defaults to p2 unless upper is flipped
				this.$refs.shock_lower.getNode().position({
					x: tempPoints[connect2][0],
					y: tempPoints[connect2][1]
				})
				this.$refs.shock_upper.getNode().rotation(shockAngle);
				this.$refs.shock_lower.getNode().rotation(180 + shockAngle);


				//MANAGE COORDINATE SYSTEM----------------------------------
				//----------------------------------------------------------
				//Rotate frame for horizontal mode
				this.$refs.layer_frame.getNode().rotation(0);
				this.$refs.layer_linkage.getNode().rotation(0);
				this.$refs.layer_frame.getNode().position({
					x: this.pointsCanvas[0][0],
					y: this.pointsCanvas[0][1],
				});
				this.$refs.layer_linkage.getNode().position({
					x: this.pointsCanvas[0][0],
					y: this.pointsCanvas[0][1],
				});

				this.p_wheelbase1 = [tempPoints[1][0] + this.r_tire_radius, tempPoints[1][1] + this.r_tire_radius];
				this.p_wheelbase2 = [tempPoints[1][0] - this.r_tire_radius, tempPoints[1][1] + this.r_tire_radius];

				if (this.getViewData('coordinateSystem') >= 1) {
					// For BB or ground ref the frame and linkage must be rotated
					const wbPoints = commonTan(tempPoints[1], this.frontWheelPos, this.r_tire_radius, this.f_tire_radius);
					const angle = theta_x(wbPoints[0], wbPoints[1]) * 180 / Math.PI;

					// Extend the wheelbase line 
					const wb_l = magnitude(wbPoints[0], wbPoints[1]);
					const uv_x = (wbPoints[1][0] - wbPoints[0][0]) / wb_l;
					const uv_y = (wbPoints[1][1] - wbPoints[0][1]) / wb_l;

					wbPoints[0] = [wbPoints[0][0] - uv_x * this.r_tire_radius, wbPoints[0][1] - uv_y * this.r_tire_radius]
					wbPoints[1] = [wbPoints[1][0] + uv_x * this.r_tire_radius, wbPoints[1][1] + uv_y * this.r_tire_radius]

					//Set wheelbase display points
					this.p_wheelbase1 = [wbPoints[0][0], wbPoints[0][1]];
					this.p_wheelbase2 = [wbPoints[1][0], wbPoints[1][1]];

					//Rotate frame and linkage layers to wb coordinate system
					this.$refs.layer_frame.getNode().rotation(-angle);
					this.$refs.layer_linkage.getNode().rotation(-angle);
					if (this.getViewData('coordinateSystem') === 2) {
						//For ground reference the bb must also be translated towards WB line
						const bbHeight = pointToLine(wbPoints[0], wbPoints[1], tempPoints[0]);
						const deltaBB = bbHeight - this.bikeData.geometry.bbHeight[this.bikeData.selectedSize] * this.ratio;
						this.$refs.layer_frame.getNode().position({
							x: this.pointsCanvas[0][0],
							y: this.pointsCanvas[0][1] - deltaBB,
						});
						this.$refs.layer_linkage.getNode().position({
							x: this.pointsCanvas[0][0],
							y: this.pointsCanvas[0][1] - deltaBB,
						});
					}
				}

				this.rebuildCount++;
				//Draw everything
				this.$refs.stage.getStage().batchDraw();
			}
		},

		moveFork: function(val) {
			this.frontSliderPos = val;
		},
	},
};
</script>
<style>
.bikeplot__tooltip {
	position: absolute;
	top: -50px;
	left: -50px;
	width: 12px;
	min-width: 12px;
	max-width: 12px;
	height: 12px;
	min-height: 12px;
	max-height: 12px;
	border-radius: 6px;

	border: none;
	/*opacity: 0;*/
	background: transparent;
	z-index: -1;
}

.bikeplot {
	/*width: 100%;*/
	/*height: 100%;*/
	background-color: var(--color-bg);
	position: relative;
}

.canvas-cont {
	width: 100%;
	height: calc(100%);
	border: .1rem solid var(--color-bg-secondary-light);
	border-radius: .5rem;
	overflow: hidden;

	transition: box-shadow .1s linear;
}

.canvas-cont:focus {
	box-shadow: 0 0 0 3px var(--color-bg-highlight);
}

.bikeplot__plot-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	/*background-color: var(--color-bg);*/
	background-color: transparent;
	width: 100%;
	height: 3rem;
}


@media(max-width: 500px) {
	.bikeplot {
		height: 70vw;
	}

}
</style>