<template>
	<div v-if="isBikesLoaded" class="fill flex-column">
		<div class="geo__category-label">
			<div>Geometry Table</div>
			<button v-if="!isMobile" @click="geoModal = !geoModal" class="btn geo__btn__popout" aria-label="Open geometry table popout">
				<IconPopout />
			</button>
		</div>
		<div v-resize:debounce="onResize" class="padding--half flex-1 no-overflow" data-test="geo-sidebar-table-container">
			<InputTable tableName="geo-sidebar-table" :headerRow="pointTableHeader" :tableSetup="geoTableSetup('single')" labelWidth="14rem" :footer="true" @change="inputEvent" :compact="isCompact">
				<!-- Custom header with change size buttons -->
				<template v-slot:slot-1>
					<div class="geo__change-size-cont">
						<button class="btn geo__btn__change-size" @click="switchSize('back')">
							<IconArrow transform="rotate(180deg)" />
						</button>
						<span>{{'Size ' + bikeData.selectedSize}}</span>
						<button class="btn geo__btn__change-size" @click="switchSize('next')">
							<IconArrow transform="rotate(0deg)" />
						</button>
					</div>
				</template>
				<!-- Footer with add size buttons -->
				<template v-slot:footer>
					<div class="geo__change-size-cont">
						<button class="btn geo__btn__change-size" @click="addSize('before', bikeData.selectedSize)" data-test="geo-tab-add-side-before">
							<IconArrow transform="rotate(180deg)" />
						</button>
						<span class="span-table-header">Add Size</span>
						<button class="btn geo__btn__change-size" @click="addSize('after', bikeData.selectedSize)" data-test="geo-tab-add-side-after">
							<IconArrow transform="rotate(0deg)" />
						</button>
					</div>
				</template>
			</InputTable>
		</div>
		<!--  -->
		<!--  -->
		<!-- Modal for full geometry table -->
		<ModalWindow :show="geoModal" @close="geoModal = !geoModal" :maxWidth="Math.min(multiTableHeader.length*15, 120) + 'rem'" minWidth="50rem">
			<template v-slot:body>
				<InputTable tableName="geo-modal-table" :headerRow="multiTableHeader" :tableSetup="geoTableSetup('multi')" labelWidth="14rem" :footer="true" @change="inputEvent" :compact="isCompact">
					<!--  -->
					<!-- Header panel buttons -->
					<template v-for="(item, index) in multiTableHeader" v-slot:[slotNum(index)]>
						<button ref="headerbtns" v-if="index !== 0" class="geo__header-panel-btn" :key="'multi-header-cont-' + index">
							{{'Size ' + (index-1)}}
							<svg class="geo__drop-arrow" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
								<path d="M33.17 17.17l-9.17 9.17-9.17-9.17-2.83 2.83 12 12 12-12z" />
								<path d="M0 0h48v48h-48z" fill="none" /></svg>
							<div class="geo__header-panel">
								<div class="geo__panel-move-buttons">
									<button class="btn geo__btn__change-size" @click="moveSize(index - 1, 'left')">
										<IconArrow transform="rotate(180deg)" />
									</button>
									<span class="span-table-header">Move</span>
									<button class="btn geo__btn__change-size" @click="moveSize(index - 1, 'right')">
										<IconArrow transform="rotate(0deg)" />
									</button>
								</div>
								<ButtonStandard name="Delete" :error="true" :btnstyle="panelButton" @click="deleteSize(index - 1)" />
							</div>
						</button>
					</template>
					<!--  -->
					<!-- Add size buttons -->
					<template v-slot:footer>
						<div class="geo__change-size-cont">
							<button class="btn geo__btn__change-size" @click="addSize('before', 0)">
								<IconArrow transform="rotate(180deg)" />
							</button>
							<span class="span-table-header">Add Size</span>
							<button class="btn geo__btn__change-size" @click="addSize('after', bikeData.geometry.reach.length - 1)">
								<IconArrow transform="rotate(0deg)" />
							</button>
						</div>
					</template>
				</InputTable>
			</template>
		</ModalWindow>
	</div>
	<div v-else class="tab-link-no-load-btn-cont">
		<ButtonStandard :clear="true" name="Load or Create Bike" :flex="true" @click="$emit('focus-load')" />
	</div>
</template>
<script>
import IconPopout from "@/components/icons/IconPopout.vue";
import IconArrow from "@/components/icons/IconArrow.vue";
import InputTable from "@/components/functional/InputTable.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ModalWindow from "@/components/ModalWindow.vue";

import resize from "vue-resize-directive";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: "TabGeometry",
	components: {
		IconPopout,
		IconArrow,
		InputTable,
		ModalWindow,
		ButtonStandard,
	},

	directives: {
		resize,
	},

	data() {
		return {
			nameKeys: ['reach', 'hta', 'htl',
				'forkLength', 'offset', 'trail', 'lowerHeadset',
				'stack', 'htt', 'fc', 'rc', 'cs', 'wheelbase',
				'bbDrop', 'bbOffset', 'bbHeight',
				'sta_eff', 'sta_act', 'stl',
				'nsh', 'insertion',
				'upperHeadset', 'stemLength', 'stemSpacers', 'barHeight', 'seatedFit'
			],

			displayNames: ['Reach', 'Head Tube Angle', 'Head Tube Length',
				'Fork Length', 'Fork Offset', 'Trail', 'Lower Headset Stack',
				'Stack', 'Horz. Top Tube', 'Front Center', 'Rear Center', 'Chainstay Length', 'Wheelbase',
				'BB Drop', 'BB Horz. Offset', 'BB Height',
				'Seat Tube Angle Eff.', 'Seat Tube Angle Act.', 'Seat Tube Length',
				'Nom. Saddle Height', 'Seatpost Insertion',
				'Upper Headset Stack', 'Stem Length', 'Stem Spacers', 'Bar Height', 'Seated Fit',
			],

			//Sets enabled or disabled for the input
			activeState: [false, false, false,
				false, false, true, false,
				true, true, true, false, true, true,
				false, false, true,
				false, false, false,
				false, false,
				false, false, false, false, true,
			],

			pointTableHeader: ['', 'Size'],

			//Impacts how events are handled
			geoModal: false,

			panelButton: {
				width: 'calc(100% - 1rem)',
				margin: '.5rem'
			},

			isCompact: false,
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['isMobile', 'liveMode']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },

		multiTableHeader: function() {
			const header = [''];
			this.bikeData.geometry.reach.forEach((item, index) => { header.push('Size ' + index) });
			return header;
		},
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),
		...mapActions('stateViewLev', ['setError']),

		onResize: function(event) {
			if (event.clientHeight < 700) {
				this.isCompact = true;
			} else {
				this.isCompact = false;
			}
		},

		//Provide index number for slot object
		slotNum: function(idx) { return 'slot-' + idx; },

		inputEvent: function(row, column, value, prev) {
			//Prevent non-numeric values
			value = value === '' ? 0 : value; //Default value
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value; //Check for invalid number

			//Use the selected bike size unless in modal input then use the column
			column = (this.geoModal) ? parseInt(column) : this.bikeData.selectedSize;

			//Set the geometry item 
			this.setData({
				item: 'geometry',
				value: value,
				row: this.nameKeys[row], //Which geometry item is being changed
				column: column, //Which column of the geometry item array to put the value in
				commit: 'PUTBIKEPOINTDATA',
				// notReactive: true
			});

			//Only solve if value has changed
			if (this.liveMode && value != prev) { this.sendAndSolve(); }

		},

		geoTableSetup: function(tableType) {
			const geo = this.bikeData.geometry;
			const size = this.bikeData.selectedSize;
			const keys = this.nameKeys;
			const names = this.displayNames;
			const active = this.activeState;

			const arrOut = [];

			names.forEach((item, index) => {
				if (tableType === 'single') {
					let value = 0;
					if (keys[index] in geo) { value = Math.round(100 * geo[keys[index]][size]) / 100; }
					arrOut[index] = [item, [value], true, active[index]];
				} else {
					//Round the values for display
					let value = geo[keys[index]].map(item => Math.round(100 * item) / 100);
					arrOut[index] = [item, value, true, active[index]];
				}
			});
			return arrOut;

		},

		switchSize: function(direction) {
			const newSize = direction === 'next' ? this.bikeData.selectedSize + 1 : this.bikeData.selectedSize - 1;
			if (newSize >= 0 && newSize < this.bikeData.geometry.reach.length) {
				this.setData({
					index: this.currentBikeIndex,
					item: 'selectedSize',
					value: newSize,
					commit: 'PUTBIKEDATA',
				});
			} else { return; }
			if (this.liveMode) { this.sendAndSolve(); }
		},

		addSize: function(order, size) {
			//Prevent more than 10 sizes
			if (this.bikeData.geometry.reach.length > this.getData('maxNumberSizes')) {
				this.setError(`Each bike cannot have more than ${this.getData('maxNumberSizes')} sizes`);
				return;
			}

			//If the bike should be inserted after then add 1 to size
			let newSize = order === 'after' ? size + 1 : size;

			//Copy geo object
			const geo = this.copyGeo();

			//Set the new sizes geometry
			for (let nameKey in geo) {
				//Set new value equal to previously selected size
				let value = geo[nameKey][size];

				//Modify select geo params for before/after
				if (order === 'after') {
					value = nameKey === 'reach' ? value + 20 : value;
					value = nameKey === 'stl' ? value + 20 : value;
					value = nameKey === 'htl' ? value + 10 : value;
				}
				if (order === 'before') {
					value = nameKey === 'reach' ? value - 20 : value;
					value = nameKey === 'stl' ? value - 20 : value;
					value = nameKey === 'htl' ? value - 10 : value;
				}

				//Slice back into new geo object
				geo[nameKey].splice(newSize, 0, value);
			}

			//Set whole geo array at once
			this.setData({
				index: this.currentBikeIndex,
				item: 'geometry',
				value: geo,
				commit: 'PUTBIKEDATA',
			});

			//Set active size to new size
			this.setData({
				index: this.currentBikeIndex,
				item: 'selectedSize',
				value: newSize,
				commit: 'PUTBIKEDATA',
			});

			if (this.liveMode) { this.sendAndSolve(); }
		},

		moveSize: function(idx, dir) {
			const idx2 = dir === 'right' ? idx + 1 : idx - 1;

			//Exit if requested range is out of bounds
			if (idx2 >= this.bikeData.geometry.reach.length || idx2 < 0) { return; }

			//Copy geo object
			const geo = this.copyGeo();

			for (let key in geo) {
				[geo[key][idx], geo[key][idx2]] = [geo[key][idx2], geo[key][idx]];
			}

			//Set whole geo array at once
			this.setData({
				index: this.currentBikeIndex,
				item: 'geometry',
				value: geo,
				commit: 'PUTBIKEDATA',
			});

			this.$refs.headerbtns[idx2].focus()
		},

		deleteSize: function(idx) {
			//Prevent complete deletion
			if (this.bikeData.geometry.reach.length === 1) { return; }

			this.setData({
				index: this.currentBikeIndex,
				item: 'selectedSize',
				value: 0,
				commit: 'PUTBIKEDATA',
			});

			const geo = this.copyGeo();

			for (let key in geo) { geo[key].splice(idx, 1); }

			// Set whole geo array at once
			this.setData({
				index: this.currentBikeIndex,
				item: 'geometry',
				value: geo,
				commit: 'PUTBIKEDATA',
			});
		},

		//Deep copy of geometry
		copyGeo: function() {
			const copy = {};
			for (let key in this.bikeData.geometry) {
				copy[key] = [...this.bikeData.geometry[key]];
			}

			return copy;
		}
	},
};
</script>
<style>
.geo__change-size-cont {
	height: 2.5rem;
	display: flex;
	flex: 1;
	justify-content: space-between;

	background: transparent;

	line-height: 2rem;
}

.geo__header-panel-btn {
	position: relative;
	width: 100%;
	height: 2.4rem;
	bottom: .1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	border: none;
	border-radius: .3rem;

	font-weight: 700;

	transition: .2s;
}

.geo__drop-arrow {
	position: absolute;
	top: .2rem;
	right: 0;
	width: 2rem;
	height: 2rem;
	stroke: black;
	stroke-width: 0;
	border: none;
	background-color: transparent;
	fill: var(--color-font-bg);

	transition: .3s;
}

.geo__header-panel-btn:hover,
.geo__header-panel-btn:focus {
	background-color: var(--color-bg-highlight);
}

.geo__header-panel-btn:active {
	border: none;
	box-shadow: none;
}

.geo__header-panel {
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;

	border: .1rem solid var(--color-bg-secondary-light);
	border-radius: .3rem;
	background-color: var(--color-bg)
}

.geo__header-panel-btn:focus-within>.geo__header-panel {
	visibility: visible;
}

.geo__header-panel-btn:focus-within>.geo__drop-arrow {
	transform: scaleY(-1);
}

.geo__panel-move-buttons {
	height: 2.5rem;
	display: flex;
	flex: 1;
	justify-content: space-between;
	margin: .5rem 0;

	background: transparent;

	line-height: 2rem;
}

.geo__btn__change-size {
	height: 2rem;
	width: 2rem;
	padding: .1rem;
	margin: 0 .3rem;

	border: none;
	border-radius: .3rem;

	fill: var(--color-font-bg);
	stroke: var(--color-font-bg);
}

.geo__btn__popout {
	height: 2rem;
	width: 2rem;
	overflow: hidden;
	margin-top: .25rem;

	border: none;
	border-radius: .25rem;
	background-color: var(--color-bg);

	transition: .3s;

	fill: var(--color-font-bg);
}

.geo__category-label {
	width: 100%;
	height: 3rem;
	display: flex;
	justify-content: space-between;
	padding: .2rem 1rem;
	margin-bottom: .2rem;

	background-color: transparent;
	border: none;
	border-bottom: .1rem solid var(--color-bg-secondary-light);

	line-height: 2.5rem;
	text-align: left;
	font-weight: 700;
	font-size: 1.2rem;

	transition: .3s;
}
</style>