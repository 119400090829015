<template>
	<div class="padding--half">
		<div v-for="(group, groupIdx) in displayNames" :key="'info-panel-group-' + groupIdx" class="setup-info-panel__group">
			<template v-for="(item, key) in group">
				<ListSelectButton v-if="typeof item === 'string'" :label="item" :key="key" :buttonState="infoConfig[key]" @click="toggleItem($event, key, true)" />
				<ListSelector v-else :itemList="item.options" :label="item.name" :key="key" margin="2.5rem 0 0 0" :value="infoConfig[key]" @submit="toggleItem($event, key)" />
			</template>
		</div>
		<ButtonStandard name="Save Settings" :flex="true" @click="saveSettings">
			<template v-slot:icon-slot>
				<IconValidate :invalid="isSaved === 2" :valid="isSaved === 1" :loading="isSaving" />
			</template>
		</ButtonStandard>
	</div>
</template>
<script>
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import IconValidate from "@/components/icons/IconValidate.vue";
import ListSelectButton from "@/components/functional/ListSelectButton.vue";
import ListSelector from "@/components/functional/ListSelector.vue";

import { fs_save_userData } from "@/firebaseConfig.js";
import { mapState, mapActions } from "vuex";

export default {
	name: 'InfoPanelSetup',

	components: {
		ListSelectButton,
		ListSelector,
		ButtonStandard,
		IconValidate,
	},

	data() {
		return {
			displayNames: [{
					wheelTravel: 'Wheel Travel',
					shockTravel: 'Shock Travel',
				},
				{
					typeLeverage: {
						options: ['Vertical', 'Horizontal'],
						name: 'Leverage Type',
					},
					leverageStart: 'Leverage Starting',
					leveragePos: 'Leverage Active',
					leverageEnd: 'Leverage Ending',

				},
				{
					typeAntisquat: {
						options: ['Standard', 'Static'],
						name: 'Antisquat Type',
					},
					asStart: 'Antisquat Starting',
					asSag: 'Antisquat Sag',
					asPos: 'Antisquat Active',
					asEnd: 'Antisquat Ending',
				},
				{
					typeAntirise: {
						options: ['Standard', 'Static'],
						name: 'Antirise Type',
					},
					arStart: 'Antirise Start',
					arSag: 'Antirise Sag',
					arPos: 'Antirise Active',
					arEnd: 'Antirise Ending',
				},
				{
					chainGrowthTangent: 'Chain Growth Tangent (End)',
					chainGrowthTotal: 'Chain Growth Total (End)',
					pedalKickbackPos: 'Pedal Kickback Active',
					pedalKickbackEnd: 'Pedal Kickback Ending',
				},
				{
					progression: 'Progression',
					energy: 'Energy Absorbed',
				},
				{
					axlepathPos: 'Axle Path Active',
					axlepathMax: 'Axle Path Max Rear',
					axlePathEnd: 'Axle Path Ending',
				},
				{
					wheelForceStart: 'Wheel Force Starting',
					wheelForcePos: 'Wheel Force Active',
					wheelForceEnd: 'Wheel Force Ending',
				},
				{
					wheelRateStart: 'Wheel Rate Starting',
					wheelRatePos: 'Wheel Rate Active',
					wheelRateEnd: 'Wheel Rate Ending',
				}
			],

			isSaving: false,
			isSaved: false,
			saveTimer: '',
		}
	},

	computed: {
		...mapState('stateViewLev', ['userSettings']),

		infoConfig: function() { return this.userSettings.infoPanel; },
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData']),
		toggleItem: function(value, key, invert = false) {
			if (invert) { value = !value; }
			this.setViewData({
				item: 'userSettings',
				subItem: 'infoPanel',
				sub2Item: key,
				value: value,
			})
		},

		clearSaveButton: function() {
			this.isSaving = false;
			this.isSaved = 0;
			this.saveButtonState = 0;
			clearTimeout(this.btnTimer);
		},

		saveSettings: async function() {
			this.isSaving = true;
			this.isSaved = 0;
			clearTimeout(this.btnTimer);

			const res = await fs_save_userData({ x_settings: this.userSettings });

			if (res !== 'success') {
				this.saveMessage = 'Issues saving. Please try again.';
				this.isSaved = 2;
				this.saveTimer = setTimeout(this.clearSaveButton, 1500);
				return;
			}

			this.isSaved = 1;
			this.saveTimer = setTimeout(this.clearSaveButton, 1500);
		},
	},

}
</script>
<style>
.setup-info-panel__group {
	margin: 1rem 0;
	padding-bottom: .5rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
}
</style>