<template>
	<div ref="bikechip" class="bikechip noselect" aria-label="Bike Identifier Chip">
		<button ref="selectbutton" class="bikechip__color-change" :class="{'bikechip__selected' : bikeIndex === currentBikeIndex}" :style="{'background-color': bikeColor, 'border-color': bikeColor}" @click="selectBike" :data-test="'select-bike-btn-' + bikeIndex">
		</button>
		<button ref="contextbutton" class="bikechip__content" @click="togglePanel" :data-test="'bike-chip-panel-open-' + bikeIndex">
			<span @mouseenter="hoverBike" @mouseleave="unHoverBike" class="bikechip__label">{{ bikeData.modelName }}</span>
		</button>
		<transition name="scale">
			<div v-if="showPanel" ref="contextMenu" class="bikechip__context-menu" :style="contextStyle" v-click-outside="() => showPanel = false">
				<transition name="fade-fast" mode="out-in">
					<BikeSetup v-if="!loadSpring" @change-state="loadSpring = true" :bikeIndex="bikeIndex" />
					<LoadSpring v-else @change-state="loadSpring = false" :bikeIndex="bikeIndex" />
				</transition>
			</div>
		</transition>
		<button @click="chipDeleteBike" class="bikechip__closebtn" aria-label="delete bike">&times;</button>
	</div>
</template>
<script>
import BikeSetup from "@/components/levapp/BikeSetup.vue";
import LoadSpring from "@/components/levapp/LoadSpring.vue";

import { mapGetters, mapActions, mapState } from "vuex";
import vClickOutside from "v-click-outside";

export default {
	props: {
		bikeIndex: Number,
	},

	components: {
		BikeSetup,
		LoadSpring,
	},

	directives: {
		clickOutside: vClickOutside.directive
	},

	data() {
		return {
			leftOffset: 0,
			loadSpring: false,

			showPanel: false,
		}
	},

	mounted() {
		this.$refs.bikechip.style.setProperty("--color-primary", this.bikeColor);
		this.$refs.bikechip.style.setProperty("--color-primary-light", this.bikeColor + '33');
		// this.$refs.selectbutton.focus();

		// this.loadSpring = true; //Don't render until refs is available for listener
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['isMobile']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeData: function() { return this.getBikeData(this.bikeIndex) },
		bikeColor: function() { return this.getData('bikeViewArray')[this.bikeIndex].color },

		contextStyle: function() {
			const style = {
				borderColor: this.bikeColor,
				left: this.leftOffset,
			};
			if (this.isMobile) { style.width = (document.body.clientWidth - 20) + 'px'; }
			return style;
		}
	},

	methods: {
		...mapActions('stateBikeData', ['loadNewBike', 'deleteBike', 'setData']),

		chipDeleteBike: function() {
			this.deleteBike(this.bikeIndex);
		},

		selectBike: function() {
			this.setData({
				item: 'selectedBikeIndex',
				value: this.bikeIndex,
				commit: 'PUTDATA',
			});
		},

		hoverBike: function() {
			this.setData({
				index: this.bikeIndex,
				item: 'isHovered',
				value: true,
				commit: 'PUTBIKEVIEWDATA',
			});
		},

		unHoverBike: function() {
			this.setData({
				index: this.bikeIndex,
				item: 'isHovered',
				value: false,
				commit: 'PUTBIKEVIEWDATA',
			});
		},

		togglePanel: function() {
			this.showPanel = !this.showPanel;

			//Only calculate panel params on show
			if (this.showPanel) {
				this.$nextTick(() => {
					const boundingRect = this.$refs.bikechip.getBoundingClientRect();
					const leftSpace = document.body.clientWidth - boundingRect.left;

					const width = this.$refs.contextMenu.clientWidth;
					this.leftOffset = leftSpace < width + 15 ? -(width + 15 - leftSpace) + 'px' : 0;
				})
			}
		},
	}
}
</script>
<style>
.bikechip {
	height: 4rem;
	display: flex;
	margin: .25rem .75rem;
	position: relative;
}

.bikechip__color-change {
	height: 4rem;
	width: 4rem;
	z-index: 1;

	color: var(--color-bg);
	border: none;
	border-radius: 50%;
	cursor: pointer;

	transition: all .2s linear;
}

.bikechip__content {
	height: 3rem;
	margin-top: .5rem;
	margin-left: -2rem;
	flex: 1;
	top: .5rem;
	display: flex;

	border: .1rem solid var(--color-bg-secondary-light);
	border-radius: 1.5rem;
	background-color: transparent;
	cursor: pointer;
}

.bikechip__label {
	display: block;
	min-width: 7rem;
	flex: 1;

	padding-left: 2.5rem;
	margin-right: 2.5rem;

	font-size: 1.3rem;
	font-weight: 400;
	letter-spacing: .03rem;
	line-height: 2.8rem;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.bikechip__context-menu {
	width: 25rem;
	height: 32rem;
	display: flex;
	flex-direction: column;

	position: absolute;
	bottom: 5rem;
	left: 0;
	z-index: 1;

	background-color: var(--color-bg);
	border-radius: .5rem;
	border: .1rem solid;
	cursor: auto;
}

.bikechip__color-change:hover,
.bikechip__color-change:active,
.bikechip__color-change:focus {
	background-color: var(--color-bg) !important;
	border: .75rem solid;

	transition: all .2s linear;
}

.bikechip__content:focus-within,
.bikechip__color-change:focus {
	outline: none;
	box-shadow: 0 0 0 3px var(--color-primary-light);
	transition: .3s;
}

.bikechip__selected {
	background-color: var(--color-bg) !important;
	border: .75rem solid;

	transition: all .2s linear;
}

.bikechip__closebtn {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0 .7rem;
	width: 1.5rem;
	height: 4rem;

	border: none;
	background: transparent;
	cursor: pointer;

	line-height: 2.8rem;
	font-weight: bold;
	font-size: 2rem;

	transition: .2s;
}

.bikechip__closebtn:hover,
.bikechip__closebtn:focus {
	transform: scale(1.25);
}


/*@media(max-width: 500px) {
	.bikechip__menu-pos {
		position: absolute;
		left: 10px;
		width: calc(100% - 20px);
	}

	.bikechip__context-menu {
		width: 100%;
		left: 0;
	}
}*/
</style>