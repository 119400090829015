<template>
	<div role="list" class="TabPlotSelection">
		<CollapsibleContainer :sections="containerContent" containerName="linkage-input-tab" @event-cc-click="selectCCEvent">
			<template v-for="(item, index) in containerContent" v-slot:[slotInfo(index)]>
				<InfoIcon v-if="item.info" :msg="item.info" :key="'title-' + index" size="1.5rem" />
			</template>
			<template v-for="(value, name) in plotNames" v-slot:[slotNum(name)]>
				<div class="padding--thin" :key="'int-div-'+value+name">
					<ListSelectButton v-for="(item, index) in plotNames[name]" :key="'ls-key-'+name+'-'+index" :buttonState="selectState[name][index]" :label="plotPretty[name][index]" @click="btnClick($event, name , index)" role="listitem" :test="'show-plot-' + item" />
				</div>
			</template>
		</CollapsibleContainer>
	</div>
</template>
<script>
import InfoIcon from "@/components/icons/InfoIcon.vue";
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import ListSelectButton from "@/components/functional/ListSelectButton.vue";
import { mapActions } from "vuex";
export default {
	name: "TabPlotSelection",
	components: {
		ListSelectButton,
		CollapsibleContainer,
		InfoIcon,
	},

	data() {
		return {
			containerContent: [{
					name: 'Leverage',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Antisquat and Antirise',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Progression',
					checked: false,
					noHighlight: true,
				},
				{
					name: 'Wheel Properties',
					checked: true,
					noHighlight: true,
					info: 'Wheel data will only be set if a spring is configured for the bike. Springs can be setup by clicking on the name of the bike underneath the plot and using the Spring Setup panel.',
				},
				{
					name: 'Horizontal Axis',
					checked: true,
					noHighlight: true,
				},
			],

			// SETUP PLOT SELECTION BUTTONS 
			plotNames: {
				leverage: ['leverage', 'leverage_h', 'leverage_bump', 'leverage_diff'],
				anti: ['antisquat', 'antisquat_s', 'antirise', 'antirise_s'],
				prog: ['progression', 'progression_s'],
				wheel: ['wheel_force', 'wheel_rate', 'energy', 'chaingrowth_total', 'chaingrowth', 'pedal_kickback', 'pedal_kickback_s', 'axle_path'],
				horz: ['d_wheel_vert', 'd_wheel_path', 'd_shock'],
			},

			plotPretty: {
				leverage: ['Leverage', 'Leverage Horizontal', 'Leverage Square Edge', 'Leverage Difference'],
				anti: ['Antisquat', 'Antisquat Static', 'Antirise', 'Antirise Static'],
				prog: ['Progression', 'Progression Alt'],
				wheel: ['Wheel Force', 'Wheel Rate', 'Energy Absorbed', 'Chain Growth', 'Chain Growth Tangent', 'Pedal Kickback', 'Pedal Kickback LX3', 'Axle Path'],
				horz: ['Wheel Vertical Displacement', 'Wheel Path Displacement', 'Shock Displacement'],
			},

			plotUnits: {
				leverage: ['', '', '', ''],
				anti: ['%', '%', '%', '%'],
				prog: ['%', '%'],
				wheel: ['N', 'N/mm', 'J', 'mm', 'mm', 'deg', 'deg', 'mm'],
				horz: ['mm', 'mm', 'mm'],
			},

			plotRound: {
				leverage: [1000, 1000, 1000, 1000],
				anti: [10, 10, 10, 10],
				prog: [10, 10],
				wheel: [100, 100, 100, 100, 100, 100, 100, 100],
			},

			//Set Initial Selections
			selectState: {
				leverage: [true, false, false, false],
				anti: [false, false, false, false, false],
				prog: [false, false],
				wheel: [false, false, false, false, false, false, false, false, false],
				horz: [true, false, false],
			},
		}; //endReturn
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData']),

		//Provide index number for slot object
		slotNum: function(nameKey) {
			let idx = 0;
			for (let key in this.plotNames) {
				if (nameKey === key) { return 'slot-' + idx }
				idx++; //If not then iterate
			}
			console.log('Invalid nameKey for Object')
		},

		//Provide index number for slot title
		slotInfo: function(idx) { return 'title-' + idx; },

		//Manage collapsible container state
		selectCCEvent: function(index) {
			// this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		btnClick: function(value, heading, index) {
			if (heading !== 'horz') {
				let activeHeading = this.selectState[heading];

				//Wheel has no multi select
				if (heading === 'wheel') {
					activeHeading = new Array(activeHeading.length).fill(false)
				}

				//Must set list select rows to new Arrays for reactivity
				for (let item in this.selectState) {
					if (item !== 'horz') {
						this.selectState[item] = new Array(this.selectState[item].length).fill(false)
					}
				}

				activeHeading[index] = !activeHeading[index];
				this.selectState[heading] = activeHeading;

				const plotNames = this.plotNames[heading];
				const plotPretty = this.plotPretty[heading];
				const plotUnits = this.plotUnits[heading];
				const plotRound = this.plotRound[heading];
				const y_plots = [];
				const y_pretty = [];
				const y_units = [];
				const y_round = [];
				activeHeading.forEach(function(item, index) {
					if (item) {
						y_plots.push(plotNames[index]);
						y_pretty.push(plotPretty[index]);
						y_units.push(plotUnits[index]);
						y_round.push(plotRound[index]);
					}
				});
				this.setViewData({
					item: 'plotYAxis',
					value: y_plots,
				});
				this.setViewData({
					item: 'plotYPretty',
					value: y_pretty,
				});
				this.setViewData({
					item: 'plotYUnits',
					value: y_units,
				});
				this.setViewData({
					item: 'plotYRound',
					value: y_round,
				});

			} else {
				//Must set row to new array for reactivity
				const x_row = [false, false, false];
				x_row[index] = true;
				this.selectState.horz = x_row;
				this.setViewData({
					item: 'plotXAxis',
					value: this.plotNames.horz[index],
				})
			}
		}
	},
};
</script>
<style>
.push-right {
	margin-left: auto;
}
</style>