<template>
	<div ref="mainbutton" class="save-button-cont">
		<ButtonStandard @click="buttonClick" name="Save Bike" flex="true" />
		<transition name="btn-slide">
			<div v-if="buttonState === 1" class="save-button-slide__cont">
				<ButtonStandard name="Overwrite?" flex="true" outline="true" error="true" @click="buttonClick">
					<span class="flex-1">Overwrite?</span>
					<button class="save-button-slide__cancel" @click.stop="clear">
						<IconValidate :invalid="(isSaved === 0 && !isSaving) || isSaved === 2" :valid="isSaved === 1" :loading="isSaving" />
					</button>
				</ButtonStandard>
			</div>
			<div v-if="buttonState === 2" class="save-button-slide__cont">
				<ButtonStandard name="Save New?" flex="true" outline="true" error="true" @click="buttonClick">
					<span class="flex-1">Save New?</span>
					<button class="save-button-slide__cancel" @click.stop="clear">
						<IconValidate :invalid="(isSaved === 0 && !isSaving) || isSaved === 2" :valid="isSaved === 1" :loading="isSaving" />
					</button>
				</ButtonStandard>
			</div>
		</transition>
	</div>
</template>
<script>
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
// import IconClose from "@/components/icons/IconClose.vue";
import IconValidate from "@/components/icons/IconValidate.vue";

import { mapGetters, mapActions, mapState } from "vuex";
import { fs_checkBikeExist, fs_saveBike } from "@/firebaseConfig.js";
import { saveFile } from "@/modules_electron/fileEvents.js";

export default {
	name: "SaveBikeButton",
	components: {
		ButtonStandard,
		// IconClose,
		IconValidate,
	},

	data() {
		return {
			buttonState: 0,
			bikeID: '',
			packageBikeData: {},
			btnTimer: '',

			isSaving: false,
			isSaved: 0,
			saveTimer: '',
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapGetters('stateViewLev', ['get_fs_database']),
		...mapState('stateViewLev', ['claims']),

		bikeData: function() { return this.getBikeData(this.getData('selectedBikeIndex')); }
	},


	methods: {
		...mapActions('stateViewLev', ['setError']),
		...mapActions('stateBikeData', ['setData']),

		//Reset button state on clear button
		clear: function() {
			this.buttonState = 0;
			this.bikeID = '';
			this.packageBikeData = {};

			this.isSaving = false;
			this.isSaved = 0;
			clearTimeout(this.btnTimer)
		},

		// Handle Tri-state button
		buttonClick: async function() {
			//button mode 0 - Check DB for match
			let result = {};
			if (this.buttonState === 0) {
				if (process.env.IS_ELECTRON && this.bikeData.database > 2) { saveFile(); }
				try {
					result = await this.checkExist();
					this.exists = result.exists;
					if (result.exists) {
						this.bikeID = result.id;
						this.buttonState = 1;

						//Blur underbutton to remove box-shadow
						this.$refs.mainbutton.children[0].blur();

						//Set timeout on save-bike__button state
						this.btnTimer = setTimeout(this.clear, 3000);

						this.checkDatabase();

						//Hold bike data package in case button is left in this state
						this.packageBikeData = this.bikeData;

						//Set state and exit
						return;
					} else if (!result.error) {
						this.buttonState = 2;

						//Blur underbutton to remove box-shadow
						this.$refs.mainbutton.children[0].blur();

						//Set timeout on save-bike__button state
						this.btnTimer = setTimeout(this.clear, 3000);

						this.checkDatabase();

						//Hold bike data package in case button is left in this state 
						this.packageBikeData = this.bikeData;

						//Set state and exit
						return;
					} else {
						return; //DB error 
					}
				} catch {
					return;
				}
			}

			// If buttonState is 1 or 2 then save
			if (this.buttonState > 0) {
				clearTimeout(this.btnTimer)
				if (this.isSaving) { return; }
				this.isSaving = true;
				this.isSaved = 0;

				result = await fs_saveBike(this.packageBikeData, this.exists, this.bikeID,
					this.get_fs_database(this.packageBikeData.database));

				if (result.error) {
					if (result.errorCode == 'permission-denied') {
						this.setError('You do not have save permissions for this database.');
					} else {
						this.setError('Server error. Please check your connection and try again.');
					}
					this.isSaved = 2;
				} else {
					this.isSaved = 1;
				}

				setTimeout(this.clear, 1500);
			}
		},

		// Call to see if bike matches a DB entry
		checkExist: async function() {
			const result = {
				exists: false,
				id: '',
				error: true,
			}

			//Set data package for Axios call
			const bikeInfo = {
				brand: this.bikeData.brand,
				modelYear: this.bikeData.modelYear,
				modelName: this.bikeData.modelName,
				version: this.bikeData.version,
			};

			//Check that all fields have data
			for (let key in bikeInfo) {
				if (bikeInfo[key] === '') {
					this.setError('All bike info must be filled');
					return result;
				}
			}

			//Don't check public database except for app_admin
			const database = this.bikeData.database === 0 && !this.claims.app_admin ? 1 : this.bikeData.database;

			const response = await fs_checkBikeExist(this.bikeData, this.get_fs_database(database));

			if (response.error) {
				//Handle the case where bike database is an invalid object. 
				//	Default to private database (bike.database = 1)
				if (response.errorCode === 'no-db') {
					this.setError('You do not have permissions to save team bikes. The bike has been changed to your private database. Please try re-saving.');
					let payload = {
						index: this.getData('selectedBikeIndex'),
						item: 'database',
						subItem: '',
						value: 1,
						commit: 'PUTBIKEDATA',
					};
					this.setData(payload);
					return result;
				}

				this.setError('The server is unavailable or there was a server error. Please check connection');

				return result;
			}
			return response;
		},

		//Switch from public database if needed
		checkDatabase: function() {
			if (this.bikeData.database === 0 && !this.claims.app_admin) {
				let payload = {
					index: this.getData('selectedBikeIndex'),
					item: 'database',
					subItem: '',
					value: 1,
					commit: 'PUTBIKEDATA',
				};
				this.setData(payload);
			}
		}
	},
};
</script>
<style>
</style>