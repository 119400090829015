<template>
	<div class="bikeplot-panel dark-panel" :class="{'bikeplot-panel--closed' : panelClosed}">
		<div class="bikeplot-panel__title flex-row" @click="panelClosed = !panelClosed">
			<span class="flex-1">{{titleText}}</span>
			<IconOpenClose size="2.5rem" :flipped="panelClosed" />
		</div>
		<div v-if="isKinData" class="flex-row">
			<div class="bikeplot-panel__names">
				<template v-for="(section, sectionNum) in displayComplete">
					<template v-if="section">
						<div v-for="name in section.names" :key="sectionNum + name">{{name}}</div>
						<div class="bikeplot-panel__group-divider" :key="'divider-' + sectionNum"></div>
					</template>
				</template>
			</div>
			<div class="bikeplot-panel__values flex-1">
				<template v-for="(section, sectionNum) in displayComplete">
					<template v-if="section">
						<div v-for="(value, idx) in section.values" :key="sectionNum + 'value-' + idx">{{value}}</div>
						<div class="bikeplot-panel__group-divider" :key="'divider-' + sectionNum"></div>
					</template>
				</template>
			</div>
			<div v-if="!panelClosed" class="bikeplot-panel__info">
				<InfoIcon size="1.5rem" msg="Panel metrics can be configured through the settings tab." />
			</div>
		</div>
	</div>
</template>
<script>
import IconOpenClose from "@/components/icons/IconOpenClose.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";

import { round } from "@/modules/utility.js";
import { mapGetters, mapState } from "vuex";

export default {
	name: 'BikePlotInfoPanel',

	props: {
		pos: Number,

	},

	components: {
		IconOpenClose,
		InfoIcon,
	},

	data() {
		return {
			panelClosed: true,

			showModelYear: false,

			config: {
				wheelTravel: true,
				shockTravel: true,

				leverageStart: true,
				leveragePos: true,
				leverageEnd: true,
				typeLeverage: 0,

				asStart: false,
				asSag: true,
				asPos: true,
				asEnd: false,
				typeAntisquat: 0,

				arStart: false,
				arSag: true,
				arPos: true,
				arEnd: false,
				typeAntirise: 0,

				chainGrowthTangent: true,
				chainGrowthTotal: true,
				pedalKickbackPos: true,
				pedalKickbackEnd: true,

				progression: false,
				energy: false,

				axlepathPos: true,
				axlepathMax: false,
				axlePathEnd: false,

				wheelForceStart: false,
				wheelForcePos: true,
				wheelForceEnd: false,

				wheelRateStart: false,
				wheelRatePos: true,
				wheelRateEnd: false,
			}
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['isMetric', 'userSettings']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },
		isKinData: function() { return ('leverage' in this.getData('bikeViewArray')[this.currentBikeIndex].kinematicData); },
		kinData: function() { return this.getData('bikeViewArray')[this.currentBikeIndex].kinematicData },
		springSettings: function() { return this.getData('bikeViewArray')[this.currentBikeIndex].springSettings },
		infoConfig: function() { return this.userSettings.infoPanel; },

		titleText: function() {
			let title = this.bikeData.modelYear + ' ' + this.bikeData.modelName;
			if (title.length > 27 || !this.showModelYear) { title = this.bikeData.modelName; }
			return title;
		},

		endIdx: function() { return this.kinData.leverage.length - 1; },
		sagIdx: function() { return Math.round(this.springSettings.shockSag * this.endIdx); },
		posIdx: function() { return Math.round(this.pos * this.endIdx); },

		displayTravel: function() {
			const names = [];
			const values = [];

			if (this.infoConfig.wheelTravel) {
				names.push('Wheel Travel:')
				const posTravel = round(this.kinData.d_wheel_vert[this.posIdx], 1)
				const endTravel = round(this.kinData.d_wheel_vert[this.endIdx], 1)
				values.push(`${posTravel} / ${endTravel}mm`)
			}

			if (this.infoConfig.shockTravel) {
				names.push('Shock Travel:')
				const posTravel = round(this.kinData.d_shock[this.posIdx], 1)
				const endTravel = round(this.kinData.d_shock[this.endIdx], 1)
				values.push(`${posTravel} / ${endTravel}mm`)
			}

			return names.length === 0 ? false : { names, values }
		},

		displayLeverage: function() {
			const names = [];
			const values = [];

			const paramList = ['leverageStart', 'leveragePos', 'leverageEnd'];
			const nameList = ['Leverage Start', 'Leverage Current', 'Leverage End'];
			const idxList = [0, this.posIdx, this.endIdx];

			const kinKey = this.infoConfig.typeLeverage === 0 ? 'leverage' : 'leverage_h';

			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData[kinKey][idxList[idx]], 2));
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayDrivetrain: function() {
			const names = [];
			const values = [];

			const paramList = ['chainGrowthTangent', 'chainGrowthTotal', 'pedalKickbackPos', 'pedalKickbackEnd'];
			const nameList = ['Chain Growth', 'Chain Growth Total', 'Pedal Kickback', 'Pedal Kickback Total'];
			const idxList = [this.posIdx, this.endIdx, this.posIdx, this.endIdx];

			const unitList = [' mm', ' mm', ' deg', ' deg']

			const keyList = ['chaingrowth_total', 'chaingrowth_total', 'pedal_kickback', 'pedal_kickback'];

			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData[keyList[idx]][idxList[idx]], 1) + unitList[idx]);
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayAntisquat: function() {
			const names = [];
			const values = [];

			const paramList = ['asStart', 'asSag', 'asPos', 'asEnd'];
			const nameList = ['Antisquat Start', 'Antisquat Sag', 'Antisquat', 'Antisquat End'];
			const idxList = [0, this.sagIdx, this.posIdx, this.endIdx];

			const kinKey = this.infoConfig.typeAntisquat === 0 ? 'antisquat' : 'antisquat_s';
			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData[kinKey][idxList[idx]], 1) + '%');
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayAntirise: function() {
			const names = [];
			const values = [];

			const paramList = ['arStart', 'arSag', 'arPos', 'arEnd'];
			const nameList = ['Antirise Start', 'Antirise Sag', 'Antirise', 'Antirise End'];
			const idxList = [0, this.sagIdx, this.posIdx, this.endIdx];

			const kinKey = this.infoConfig.typeAntirise === 0 ? 'antirise' : 'antirise_s';
			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData[kinKey][idxList[idx]], 1) + '%');
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayProgression: function() {
			const names = [];
			const values = [];

			if (this.infoConfig.progression) {
				names.push('Progression:')
				values.push(round(this.kinData.progression[this.endIdx], 1) + '%')
			}

			if (this.infoConfig.energy) {
				names.push('Energy Absorbed:')
				values.push(round(this.kinData.energy[this.endIdx], 1) + 'J')
			}

			return names.length === 0 ? false : { names, values }
		},

		displayAxlepath: function() {
			const names = [];
			const values = [];

			//Get the value of the most rearward point
			const idxMin = this.kinData['axle_path'].indexOf(Math.min(...this.kinData['axle_path']));

			const paramList = ['axlepathPos', 'axlepathMax', 'axlePathEnd'];
			const nameList = ['Axle Path', 'Axle Path Max Rear', 'Axle Path End'];
			const idxList = [this.posIdx, idxMin, this.endIdx];

			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData['axle_path'][idxList[idx]], 1) + ' mm');
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayWheelProps: function() {
			const names = [];
			const values = [];

			let paramList = ['wheelForceStart', 'wheelForcePos', 'wheelForceEnd'];
			let nameList = ['Wheel Force Start', 'Wheel Force', 'Wheel Force End'];
			let idxList = [0, this.posIdx, this.endIdx];

			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData['wheel_force'][idxList[idx]], 1) + ' N');
				}
			});

			paramList = ['wheelRateStart', 'wheelRatePos', 'wheelRateEnd'];
			nameList = ['Wheel Rate Start', 'Wheel Rate', 'Wheel Rate End'];
			idxList = [0, this.posIdx, this.endIdx];

			paramList.forEach((param, idx) => {
				if (this.infoConfig[param]) {
					names.push(nameList[idx]);
					values.push(round(this.kinData['wheel_rate'][idxList[idx]], 1) + ' N/mm');
				}
			});

			return names.length === 0 ? false : { names, values }
		},

		displayComplete: function() {
			return [
				this.displayTravel,
				this.displayLeverage,
				this.displayAntirise,
				this.displayAntisquat,
				this.displayDrivetrain,
				this.displayProgression,
				this.displayAxlepath,
				this.displayWheelProps,
			];
		}

	},

	methods: {}
}
</script>
<style>
.bikeplot-panel {
	min-width: 27rem;
	max-height: calc(100% - 2rem);
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: .25rem 1rem;
	overflow: hidden;

	border-radius: 1rem;
	background-color: var(--color-bg);
	color: var(--color-font-bg);

	font-size: 1.3rem;
	text-align: left;

}

.bikeplot-panel--closed {
	max-height: 3rem;
}

.bikeplot-panel__title {
	align-items: center;

	font-family: var(--font-alt);
	font-weight: 600;

	fill: var(--color-font-bg);
	stroke: var(--color-font-bg);
}

.bikeplot-panel>* {
	background: transparent;
}

.bikeplot-panel__names {
	margin-right: 1rem;
	text-align: right;
}

.bikeplot-panel__values {
	text-align: left;
}

.bikeplot-panel__names>div,
.bikeplot-panel__values>div {
	padding: .1rem 0;
}

.bikeplot-panel__group-divider {
	height: 0rem;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	margin: .5rem 0;
}

.bikeplot-panel__group-divider:last-child {
	border: none;
	margin: .2rem;
}

.bikeplot-panel__info {
	position: absolute;
	bottom: .5rem;
	right: .5rem;
}
</style>