<template>
	<div role="list" class="TabBikePlotSettings">
		<CollapsibleContainer :sections="containerContent" containerName="bikeplot-input-tab" @event-cc-click="selectCCEvent">
			<template v-for="(name, nameIdx) in names" v-slot:[slotNum(nameIdx)]>
				<div class="padding--half" :key="'int-div-'+name+nameIdx">
					<ListSelectButton v-for="(item, index) in names[nameIdx]" :key="'ls-key-'+nameIdx+'-'+index" :buttonState="selectState[nameIdx][index]" :label="namesPretty[nameIdx][index]" @click="btnClick($event, nameIdx , index)" role="listitem" />
				</div>
			</template>
			<template v-slot:slot-2>
				<div class="padding--half">
					<!-- <label class="selector-label selector-label--first" for="is-live-mode">Show Image:</label> -->
					<RadioSelector :optionArray="bikeImageToggle" @click="setViewData({item: 'showBikeImage', value: !showBikeImage})" />
					<TextInputMD v-if="showBikeImage" name="Image Scale" :value="bikeImageScale" @input="setBikeImageProp($event, 'scale')" />
					<TextInputMD v-if="showBikeImage" name="Image Rotation" :value="bikeImageRotation" @input="setBikeImageProp($event, 'rotation')" />
					<TextInputMD v-if="showBikeImage" name="Image URL" :value="bikeImageURL" @input="setBikeImageProp($event, 'url')" />
				</div>
			</template>
			<template v-slot:slot-3>
				<div class="padding--half">
					<RadioSelector label="Force In Tooltips:" :optionArray="showForceSetup" @click="changeShowForce" />
				</div>
			</template>
		</CollapsibleContainer>
	</div>
</template>
<script>
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import ListSelectButton from "@/components/functional/ListSelectButton.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: "TabPlotSelection",
	components: {
		ListSelectButton,
		CollapsibleContainer,
		TextInputMD,
		RadioSelector,
	},

	data() {
		return {
			containerContent: [{
					name: 'Coordinate System',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Shock Model',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Reference Bike Image',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Plot Settings',
					checked: false,
					noHighlight: true,
				},
			],

			// SETUP PLOT SELECTION BUTTONS 
			names: {
				coordinate: ['cs_frame', 'cs_bb', 'cs_ground'],
				shock: ['shock_flip', 'shock_mirror'],
			},

			namesPretty: {
				coordinate: ['Frame Reference', 'BB Reference', 'Ground Reference'],
				shock: ['Flip Shock', 'Mirror Shock'],
			},

			//Set Initial Selections
			selectState: {
				coordinate: [true, false, false],
				shock: [false, false],
			},
		}; //endReturn
	},

	//Watch changes in bike data to set shock orientation
	watch: {
		bikeData: {
			immediate: true,
			handler(bike) {
				if (this.isBikesLoaded) {
					this.selectState.shock = [
						bike.solverInfo.shockFlip,
						bike.solverInfo.shockMirror,
					]
				}
			}
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['bikeImageScale', 'bikeImageURL', 'bikeImageRotation', 'showBikeImage', 'showForceTooltips']),

		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },

		bikeImageToggle: function() {
			return [
				['Off', !this.showBikeImage, true],
				['On', this.showBikeImage, true],
			];
		},

		boolTest: function() {
			return true;
		},

		showForceSetup: function() {
			return [
				['Off', !this.showForceTooltips, true],
				['On', this.showForceTooltips, true],
			];
		},
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData']),
		...mapActions('stateBikeData', ['setData']),

		//Provide index number for slot object
		slotNum: function(nameKey) {
			let idx = 0;
			for (let key in this.names) {
				if (nameKey === key) { return 'slot-' + idx }
				idx++; //If not then iterate
			}
		},

		//Manage collapsible container state
		selectCCEvent: function(index) {
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		btnClick: function(value, heading, index) {
			let currentHeading = this.selectState[heading];
			let newRow = [];

			currentHeading.forEach((item) => {
				if (heading === 'coordinate') {
					newRow.push(false);
				} else {
					newRow.push(item);
				}
			});

			newRow[index] = !newRow[index];
			this.selectState[heading] = newRow;

			//Handle Coordinate system change
			if (heading === 'coordinate') {
				this.setViewData({
					item: 'coordinateSystem',
					value: index,
				});
			}

			//Handle Shock Flip
			if (this.names[heading][index] === 'shock_flip') {
				if (this.isBikesLoaded) {
					let payload = {
						index: this.currentBikeIndex,
						item: 'solverInfo',
						subItem: 'shockFlip',
						value: !value,
						commit: 'PUTBIKEDATA',
					};
					this.setData(payload);
				}
			}

			//Handle Shock Mirror
			if (this.names[heading][index] === 'shock_mirror') {
				if (this.isBikesLoaded) {
					let payload = {
						index: this.currentBikeIndex,
						item: 'solverInfo',
						subItem: 'shockMirror',
						value: !value,
						commit: 'PUTBIKEDATA',
					};
					this.setData(payload);
				}
			}
		},

		setBikeImageProp: function(value, type) {
			let item = '';
			if (type === 'url') { item = 'bikeImageURL'; }

			if (type === 'scale') {
				item = 'bikeImageScale';
				value = parseFloat(value);
			}

			if (type === 'rotation') {
				item = 'bikeImageRotation';
				value = value === '' ? 0 : value;
				value = parseFloat(value);
			}

			if (value || value === '' || value === 0) {
				this.setViewData({
					item: item,
					value: value,
				});
			}
		},


		changeShowForce: function(result) {
			this.setViewData({
				item: 'showForceTooltips',
				value: result,
			});
		},
	}
}
</script>
<style>
</style>