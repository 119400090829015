<template>
	<div class="padding--half">
		<!-- Tab Panel Objects -->
		<RadioSelector :optionArray="radioToggle(optimizeEnabled)" label="Enable Optimize" @click="setOptValue($event, 'optimizeEnabled')" test="optimize-enabled" />
		<div v-if="optimizeEnabled" class="border--top padding--top" data-test="optimize-panel">
			<OptimizeInfo />
			<span class="optimize__heading">Select Target Curves:</span>
			<ListSelectButton v-for="(item, key) in targets" :key="'target-' + key" :buttonState="item.used" :label="item.name" @click="setTargetValue(!item.used, key, 'used')" role="listitem" :test="'select-target-' + key" />
			<br>
			<span v-if="isBikesLoaded && targetsExist" class="optimize__heading">Accuracy Level:</span>
			<RangeSlider v-if="isBikesLoaded && targetsExist" width="98%" margin="1.5rem 0 1rem 0" v-model="solverAccuracy" />
			<div v-if="isBikesLoaded && targetsExist" class="multi-button-vert-cont margin--top-bottom">
				<ButtonStandard name="Set Boundary Conditions" :flex="true" @click="showBoundary = true" test="open-modal-set-boundary" />
				<ButtonStandard name="Run Optimization" :flex="true" :outline="true" @click="sendOptimize" :spinner="isOptimizing" test="send-for-optimize" />
			</div>
			<transition name="slide-out">
				<p>{{ message }}</p>
			</transition>
		</div>
		<!--  -->
		<!--  -->
		<!--  -->
		<ModalWindow :show="showBoundary" @close="showBoundary = !showBoundary" maxWidth="50rem" minWidth="0rem" :scroll="true" test="modal-optimize">
			<template v-slot:body>
				<div class="optimize__table-heading">Displacement Limits</div>
				<div class="flex-row">
					<InputTable tableName="optimize-limits-table" :headerRow="pointTableHeader" :tableSetup="pointLimitArray" @change="pointLimitInput" labelWidth="11rem" :tableStyle="tableWidth('40rem')" />
					<div class="flex-column optimize__selector-cont">
						<SelectButton v-for="(item, index) in pointLimitArray" :hide="!item[2]" :key="item[0]" :buttonState="!item[3]" margin=".25rem 1rem" @click="pointStateInput($event, index)" :test="'optimize-enable-boundary-' + index" />
					</div>
				</div>
				<div class="optimize__lower-control">
					<div class="flex-row margin--bottom">
						<TextInputMD name="Reset Limit Value" margin="0" v-model="defaultLimit" :error="defaultLimitErr" test="optimize-reset-boundary-val" />
						<ButtonStandard name="Set All" :btnstyle="{margin: '1rem 0 0 1rem'}" @click="resetLimits" test="optmize-reset-boundary-btn" />
					</div>
					<InputTable v-if="fixShockLength" :headerRow="shockTableHeader" :tableSetup="shockAngleArray" labelWidth="13rem" @change="setAngleLimit" tableName="optimize-shock-angle-table" />
					<RadioSelector :optionArray="radioToggle(fixShockLength)" label="Fix Shock Length" @click="setOptValue($event, 'fixShockLength')" margin="1rem 0" test="optimize-fix-shock-length" />
				</div>
				<div class=" optimize__table-heading margin--top">Target Weighting
				</div>
				<div class="flex-row">
					<InputTable tableName="optimize-weights-table" :tableSetup="weightArray" :headerRow="weightTableHeader" labelWidth="11rem" @change="setWeight" cellWidth="7rem" :tableStyle="tableWidth('18rem')" @focus="focusPercent" />
					<div class="optimize-weights__percent flex-column">
						<div class="optimize-weights__percent-cell">Percentage</div>
						<div class="optimize-weights__percent-cell" v-for="(item, key) in weightPercent" :key="'percent-' + key" :class="{'bold' : activeSegment === key}">{{item === '-' ? '-' : item + '%'}}</div>
					</div>
					<svg viewBox="0 0 24 24" class="optimize__weights-svg">
						<circle class="optimize__weights-svg-item" r="7.5" cx="12" cy="12" v-for="(item, key) in weightPieChart" fill="none" :stroke="item[2]" :stroke-dasharray="item[0]" :transform="item[1]" :key="'circle-' + key" :class="{'optimize__weights-svg-item-active' : activeSegment === item[3]}" @mouseenter="activeSegment = item[3]" @mouseleave="activeSegment = ''" />
					</svg>
				</div>
			</template>
		</ModalWindow>
	</div>
</template>
<script>
import InputTable from "@/components/functional/InputTable.vue";
import ListSelectButton from "@/components/functional/ListSelectButton.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import RangeSlider from "@/components/functional/RangeSlider";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ModalWindow from "@/components/ModalWindow.vue";
import SelectButton from "@/components/functional/SelectButton.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import OptimizeInfo from "@/components/levapp/OptimizeInfo.vue";

import { mapGetters, mapActions, mapState } from "vuex";
import { sendRequest } from "@/modules/sendRequest.js";

import Spline from "cubic-spline";

export default {
	name: 'Optimize',
	components: {
		ListSelectButton,
		RadioSelector,
		ButtonStandard,
		ModalWindow,
		InputTable,
		SelectButton,
		TextInputMD,
		RangeSlider,
		OptimizeInfo
	},

	data() {
		return {
			isOptimizing: false,

			solverAccuracy: 50,

			defaultLimit: 1,
			defaultLimitErr: false,

			travelErr: false,

			showBoundary: false,
			pointTableHeader: ['', 'x lower', 'x upper', 'y lower', 'y upper'],

			weightTableHeader: ['', 'Weight'],

			shockTableHeader: ['', 'cw', 'ccw'],

			selectStyle: {
				margin: '.25rem 2rem',
				width: '3rem'
			},

			activeSegment: '',

			message: '',
			messageTimeout: {},

			// P2-P3 - Shocks
			// P4 - Single Pivot
			// P5-P6-P7 - Four bars
			// P8-P9-P10 - Six bars
			// P11 - Idlers
			// P12-P13 - Brake Arm

			buttonState: true,
		};
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateOptimize', ['targets', 'optimizeEnabled',
			'pointLimits', 'pointLimitStatus', 'shockAngleLimit',
			'fixShockStroke', 'fixShockLength'
		]),
		...mapState('stateViewLev', ['plotXAxis']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex'); },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length; },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false; },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex); },

		pointLimitArray: function() {
			const limits = this.pointLimitStatus.map((item, index) => {
				let row = [item[0], this.pointLimits[index], item[1], item[2]];
				if (item[2]) { row[1] = ['-', '-', '-', '-']; }
				return row;
			});

			//Do not show shock 2 limits if using angular limits
			if (this.fixShockLength) { limits[0][2] = false; }

			if (this.bikeData.solverInfo.linkageType == 1) {
				for (let i = 3; i < 6; i++) { limits[i][2] = true; }
			}
			if (this.bikeData.solverInfo.linkageType == 2) {
				for (let i = 3; i < 9; i++) { limits[i][2] = true; }
			}

			limits[9][2] = this.bikeData.solverInfo.usesIdler ? true : false;
			limits[10][2] = this.bikeData.solverInfo.usesBrakeArm ? true : false;
			limits[11][2] = this.bikeData.solverInfo.usesBrakeArm ? true : false;

			return limits;
		},

		shockAngleArray: function() {
			return [
				['Shock Angle Limits', this.shockAngleLimit, true, false]
			];
		},

		//Create inputTable array for settings weights
		weightArray: function() {
			const weightArray = {};
			for (let key in this.targets) {
				const item = this.targets[key];
				weightArray[key] = [item.name, [item.used ? item.weight : '-'], true, !item.used];
			}
			return weightArray;
		},

		//Sum total weight to get percents
		weightTotal: function() {
			let total = 0;
			for (let key in this.targets) {
				if (this.targets[key].used) { total += this.targets[key].weight; }
			}
			return total;
		},

		//Create percents object
		weightPercent: function() {
			const percents = {};
			for (let key in this.targets) {
				const item = this.targets[key];
				percents[key] = item.used ? Math.round(10000 * item.weight / this.weightTotal) / 100 : '-';
			}
			return percents;
		},

		//Create build object for pie chart
		weightPieChart: function() {
			const radius = 7.5;
			const circ = 2 * Math.PI * radius;
			let rotation = -180; //Starting rotation

			const svgProperties = {};
			for (let key in this.targets) {
				if (this.targets[key].used) {
					svgProperties[key] = [
						`${this.weightPercent[key]/100 * circ} ${circ}`, //Dash properties
						`rotate(${rotation})`, //Incremented rotation
						this.targets[key].color,
						key
					];
					rotation += this.weightPercent[key] / 100 * 360;
				}
			}
			return svgProperties
		},

		//Only show controls if at least one target exists
		targetsExist: function() {
			let res = false;
			for (let key in this.targets) { if (this.targets[key].used) { res = true; } }
			return res;
		}
	},

	methods: {
		...mapActions('stateOptimize', ['setOptData', 'setTargetData', 'setPointTable']),
		...mapActions('stateBikeData', ['loadDBBike']),

		//Set error messages
		setMessage: function(message) {
			clearTimeout(this.messageTimeout)
			this.message = message;
			this.messageTimeout = setTimeout(() => {
				this.message = '';
			}, 5000);
		},

		//Table width limiter
		tableWidth: function(width) { return { maxWidth: width }; },

		//On/off radio button setup
		radioToggle: function(value) {
			const optionArray = [
				['Off', false, true],
				['On', false, true],
			];

			if (value) { optionArray[1][1] = true; }
			if (!value) { optionArray[0][1] = true; }

			return optionArray;
		},

		//Set value in target object
		setTargetValue: function(value, item, subItem = '') {
			this.setTargetData({
				item: item,
				subItem: subItem,
				value: value,
			});
		},

		//Set top level value in optimize store
		setOptValue: function(value, item, subItem = '') {
			this.setOptData({
				item: item,
				subItem: subItem,
				value: value,
			});
		},

		//Highlight pie chart when percentage is selected
		focusPercent: function(indexRow) { this.activeSegment = indexRow; },

		//Set a relative weight value
		setWeight: function(indexRow, indexColumn, value) {
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value;

			this.setTargetData({
				item: indexRow,
				subItem: 'weight',
				value: value,
			});

			this.activeSegment = '';
		},

		//Set a point limit 
		pointLimitInput: function(indexRow, indexColumn, value) {
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value;

			//Only allow negative values for lower limits and positive values for upper limits
			value = [0, 2].includes(indexColumn) && value > 0 || [1, 3].includes(indexColumn) && value < 0 ? -value : value;

			this.setPointTable({
				commit: 'SETPOINTLIMIT',
				row: indexRow,
				column: indexColumn,
				value: value,
			});
		},

		//Set anglar shock limit
		setAngleLimit: function(indexRow, indexColumn, value) {
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value;

			value = indexColumn === 0 && value > 0 || indexColumn === 1 && value < 0 ? -value : value;

			this.setOptValue(value, 'shockAngleLimit', indexColumn);
		},

		//Set a point usage state
		pointStateInput: function(value, indexRow) {
			this.setPointTable({
				commit: 'SETPOINTSTATE',
				row: indexRow,
				value: value,
			});
		},

		//Set all point limits to same distance from initial
		resetLimits: function() {
			const lim = parseFloat(this.defaultLimit);
			this.defaultLimitErr = false;
			if (isNaN(lim)) {
				this.defaultLimitErr = true;
				return;
			}

			this.pointLimits.forEach((item, idx) => {
				this.setOptValue([-lim, lim, -lim, lim], 'pointLimits', idx);
			});
		},

		//Generate spline curves for optimizer
		createTargetSplines: function() {
			for (let key in this.targets) {
				const curve = {
					x: [],
					y: [],
				}

				const splineX = [];
				const splineY = [];
				const points = [...this.targets[key].points].sort((a, b) => { return a.x - b.x });

				if (points.length > 0 && this.targets[key].used) {
					points.forEach(point => {
						splineX.push(point.x);
						splineY.push(point.y);
					});

					const spline = new Spline(splineX, splineY);

					const max_x = Math.max(...splineX);
					for (let i = 0; i < max_x; i++) {
						curve.x.push(i);
						curve.y.push(spline.at(i));
					}

					curve.x.push(max_x);
					curve.y.push(spline.at(max_x));
				}

				this.setTargetData({
					item: key,
					subItem: 'x',
					value: curve.x,
				});

				this.setTargetData({
					item: key,
					subItem: 'y',
					value: curve.y,
				});
			}
		},

		//Correct shock stroke for desired leverage curve
		getTargetStroke: function(x, lev) {
			let shockTravel = 0;

			x.forEach((x_val, idx) => {
				if (idx === 0) { return; }
				shockTravel += (x_val - x[idx - 1]) / lev[idx]
			})

			return Math.round(10 * shockTravel) / 10;
		},

		//Wave wand. Make some magic happen. 
		sendOptimize: async function() {
			if (this.isOptimizing) { return; } //Prevent double study
			const bounds = {}
			this.pointLimitArray.forEach((item, idx) => {
				bounds[idx] = {
					name: item[0],
					limits: item[1],
					active: item[2] && !item[3] ? true : false,
				}
			});

			for (let key in this.targets) {
				this.targets[key].percent = this.weightPercent[key] === '-' ? 0 : this.weightPercent[key] / 100
			}

			this.createTargetSplines();

			let targetStroke = this.bikeData.solverInfo.shockStroke
			if (this.targets.lev.used) {
				targetStroke = this.getTargetStroke(this.targets['lev'].x, this.targets['lev'].y)
			}

			let isTargets = this.fixShockLength ? true : false;
			for (let key in bounds) {
				if (bounds[key].active) { isTargets = true; }
			}

			if (!isTargets) {
				this.setMessage('At least one point must have movement boundaries specified.')
				return;
			}

			const data = {
				bike: this.bikeData,
				targets: this.targets,
				max_iter: 100 * this.solverAccuracy + 100,
				bounds: bounds,
				x_axis: this.plotXAxis,
				target_stroke: targetStroke,
				angle_limits: this.fixShockLength ? this.shockAngleLimit : [],
			}

			this.isOptimizing = true;

			const res = await sendRequest(data, process.env.VUE_APP_UTILITY_URL + '/optimize', 3000)

			if ('status' in res && res.status === 'pass') {
				this.setMessage('Optimize study submitted')
			} else {
				this.setMessage('Error submitting study. Please check your connection and try again.')
			}

			this.isOptimizing = false;
		},
	}
}
</script>
<style>
.optimize__heading {
	display: block;
	text-align: left;
	font-size: 1.2rem;
	font-weight: 700;
	margin-left: .5rem;
}

.optimize__selector-cont {
	padding-top: 2.5rem;
}

.optimize__table-heading {
	max-width: 29rem;
	height: 2rem;
	margin-left: 11rem;
	margin-bottom: 1rem;

	border-bottom: .1rem solid var(--color-bg-secondary-light);

	font-weight: 700;
}

.optimize__lower-control {
	width: 29rem;
	margin-left: 11rem;
	margin-top: 1rem;
}

.optimize-weights__percent {
	margin-left: .3rem;
}

.optimize-weights__percent-cell {
	height: 2.5rem;
	width: 7rem;

	background-color: var(--color-bg-cell-highlight);
	border-bottom: .1rem solid var(--color-bg-secondary-light);
	line-height: 2.4rem;
}

.optimize-weights__percent-cell:first-child {
	font-weight: 700;
	background-color: var(--color-bg);
}

.optimize__weights-svg {
	height: 12rem;
	width: 12rem;
	margin: auto;
}

.optimize__weights-svg-item {
	transform-origin: center center;
	stroke-width: 5;
	transition: .3s;
}

.optimize__weights-svg-item:hover,
.optimize__weights-svg-item-active {
	stroke-width: 6;
}
</style>