<template>
	<div class="mainplot" :class="{'flex-column': plotShape === 2}">
		<div v-show="plotShape > 0 || mainPlotState === 0" class="mainplot__canvas-cont" :class="{'fill' : plotShape === 0, 'mainplot__split': plotShape === 1, 'mainplot__stacked': plotShape === 2}" data-test="main-plot-canvas">
			<canvas @dblclick="canvasDblClick" @mousedown="canvasDown" @mouseup="canvasUp" @mouseout="canvasUp" v-on="targetPointIndex !== null ? { mousemove: canvasMove } : {}" ref="maincanvas">
			</canvas>
		</div>
		<BikePlot v-if="plotShape > 0 || mainPlotState === 1" :class="{'fill' : plotShape === 0, 'mainplot__split': plotShape === 1, 'mainplot__stacked': plotShape === 2}" />
		<span id="main-plot-font-size" :style="{fontSize: '1rem', height: 0, display: 'none'}"></span>
	</div>
</template>
<script>
import BikePlot from "@/components/levapp/BikePlot.vue";
import { splineInterp } from "@/modules/spline.js";
import { trapIntegral, applySpringForce } from "@/modules/optimizeSpringSetup.js";
import { round } from "@/modules/utility.js";

import { mapActions, mapGetters, mapState } from "vuex";
import Chart from "chart.js";
import Spline from "cubic-spline";
// import bezier from "bezier";

export default {
	name: "MainPlot",
	components: {
		BikePlot,
	},

	data() {
		return {
			remSize: 10,

			lineStyles: [
				[0, 0],
				[2, 2],
				[7, 10],
				[10, 2]
			],

			//Initialized chart object
			chart: { update: () => {}, },
			datasets: [],

			targetPointIndex: null,
			targetNumber: null,
		};
	},

	//Bind collection of bikeInfo's to variable
	watch: {
		bikeViewArray: { deep: true, handler() { this.updateChartData(); }},
		bikeData: { deep: true, handler() { this.updateChartData(); } },
		plotYAxis: { handler() { this.updateChartData(); } },
		plotXAxis: { handler() { this.updateChartData(); } },
		optimizeEnabled: { handler() { this.updateChartData(); } },

		//Watch each target curve and set on change
		'targets.lev.used'(val) { this.setTargetPoints('lev', val); },
		'targets.as.used'(val) { this.setTargetPoints('as', val); },
		'targets.ar.used'(val) { this.setTargetPoints('ar', val); },
		'targets.pk.used'(val) { this.setTargetPoints('pk', val); },
	},

	mounted() {
		//Get font size on mounting
		this.remSize = parseInt(window.getComputedStyle(document.getElementById('main-plot-font-size')).fontSize);

		//Initialize chart data containers
		for (let i = 0; i < this.maxNumberBikes * 5; i++) {
			this.datasets.push({ data: [], });
		}
		this.updateChartData();

		//Initialize chart object
		this.chart = new Chart(this.$refs.maincanvas, {
			type: 'line',
			data: { datasets: this.datasets, },
			options: this.chartOptions,
		});
	},

	computed: {
		...mapGetters('stateBikeData', ['getBikeData']),
		...mapState('stateBikeData', ['bikeDataArray', 'selectedBikeIndex', 'bikeViewArray', 'maxNumberBikes']),
		...mapState('stateViewLev', ['isMobile', 'mainPlotState', 'splitPlot', 'userInfo',
			'plotYAxis', 'plotXAxis', 'plotYUnits', 'plotYPretty', 'plotYRound',
		]),
		...mapState('stateOptimize', ['targets', 'optimizeEnabled']),

		// Currently selected bike
		bikeData: function() { return this.getBikeData(this.selectedBikeIndex); },

		// Current bike view data
		bikeViewData: function() { return this.bikeViewArray[this.selectedBikeIndex]; },

		//Determine layout of plot containers
		plotShape: function() { return this.isMobile ? 0 : this.splitPlot },

		//Main options for chart.js
		chartOptions: function() {
			return {
				responsive: true,
				responsiveAnimationDuration: 0,
				maintainAspectRatio: false,
				// fill: false,
				elements: {
					line: {
						cubicInterpolationMode: 'monotone',
						fill: false,
					},
					point: { radius: 0, hitRadius: 7 },
				},
				legend: { display: false },
				hover: {
					mode: 'nearest',
					animationDuration: 200,
				},
				tooltips: {
					mode: 'nearest',
					intersect: true,
					titleFontSize: this.remSize * 1.2,
					titleFontFamily: 'Roboto',
					bodyFontSize: this.remSize * 1.3,
					bodyFontFamily: 'Roboto',
					callbacks: {
						title: function(tooltipItem, data) {
							return data.datasets[tooltipItem[0].datasetIndex].altLabel
						},
						label: function(tooltipItem, data) {
							tooltipItem.title = 'Some Item';
							const rounder = data.datasets[tooltipItem.datasetIndex].unitRounding;
							return Math.round(100 * tooltipItem.xLabel) / 100 + 'mm   ' +
								Math.round(rounder * tooltipItem.yLabel) / rounder +
								data.datasets[tooltipItem.datasetIndex].altUnits;
						},
					},
				},
				scales: {
					xAxes: [{
						type: 'linear', //Required to show x/y data
						ticks: {
							fontSize: this.remSize * 1.3,
							autoSkip: true,
							stepSize: 5,
							minor: { display: true }
						}
					}],
					yAxes: [{
						display: true,
						ticks: {
							fontSize: this.remSize * 1.3,
						},
					}],
				},
			}
		},

		//Set targets depending on what plot is shown
		plotTargets: function() {
			if (this.plotYAxis.length === 0) { return []; }
			if (this.plotYAxis[0].startsWith('leverage')) {
				return [
					{ key: 'lev', name: 'Leverage Target', pointDsIdx: 0, splineDsIdx: 1, ylim: [0, 5] },
				]
			}
			//
			if (this.plotYAxis.includes('antisquat') && this.plotYAxis.includes('antirise')) {
				return [
					{ key: 'as', name: 'Antisquat Target', pointDsIdx: 0, splineDsIdx: 1, ylim: [-300, 300] },
					{ key: 'ar', name: 'Antirise Target', pointDsIdx: 2, splineDsIdx: 3, ylim: [-300, 300] },
				]
			}
			if (this.plotYAxis.includes('antisquat')) {
				return [
					{ key: 'as', name: 'Antisquat Target', pointDsIdx: 0, splineDsIdx: 1, ylim: [-300, 300] },
				]
			}
			if (this.plotYAxis.includes('antirise')) {
				return [
					{ key: 'ar', name: 'Antirise Target', pointDsIdx: 2, splineDsIdx: 3, ylim: [-300, 300] },
				]
			}
			//
			if (this.plotYAxis[0] === 'pedal_kickback') {
				return [
					{ key: 'pk', name: 'Pedal Kickback Target', pointDsIdx: 0, splineDsIdx: 1, ylim: [-100, 100] },
				]
			}
			return []; //default return
		},

		plotReadOnlyTargets: function() {
			if (this.plotYAxis.length === 0) { return null; }
			
			if (this.plotYAxis[0] === 'energy') {
				return { key: 'energy', name: 'Energy Target', splineDsIdx: 9 }
			}

			if (this.plotYAxis[0] === 'wheel_force') {
				return { key: 'wheel_force', name: 'Wheel Force Target', splineDsIdx: 9 }
			}

			if (this.plotYAxis[0] === 'wheel_rate') {
				return { key: 'wheel_rate', name: 'Wheel Rate Target', splineDsIdx: 9 }
			}

			return null
		}
	}, //endComputed

	methods: {
		...mapActions('stateOptimize', ['setTargetData', 'setOptData']),

		//Get vuex store data and assign into chart.js datasets
		updateChartData: function() {
			//Reset all datasets to clear any deleted item
			this.datasets.forEach(item => {
				Object.assign(item, { data: [], });
			});

			if (this.optimizeEnabled && this.plotXAxis !== 'd_shock') {
				this.syntheticTargetSpline()
				//Draw targets if enabled
				this.plotTargets.forEach((target, index) => {
					Object.assign(this.datasets[target.pointDsIdx], this.targetDataset(target.key, target.name));
					Object.assign(this.datasets[target.splineDsIdx], this.targetSplines(target));
				});
			}

			if(this.plotReadOnlyTargets){
				const target = this.plotReadOnlyTargets
				Object.assign(this.datasets[target.splineDsIdx], this.syntheticTargetSpline(target));
			}
			let dsIdx = 10; //Start at 10 to prevent shifting datasets as targets change

			//Convert bike data into dataset objects
			this.bikeViewArray.forEach((bike) => {
				if (Object.keys(bike.kinematicData).length > 0) {
					//For each y-axis specified create dataset
					this.plotYAxis.forEach((y_nameKey, index) => {
						const dataset = {
							borderWidth: bike.isHovered ? 4 : 2,
							backgroundColor: bike.color,
							borderColor: bike.color,
							borderDash: this.lineStyles[index],
							showLine: true,
							fill: false,
							pointRadius: 0,
							pointHitRadius: 10,
							pointHoverRadius: 3,
							altLabel: this.plotYPretty[index],
							altUnits: this.plotYUnits[index],
							unitRounding: this.plotYRound[index],
							data: this.parseData(bike.kinematicData, this.plotXAxis, y_nameKey),
						}
						// Move data into datasets object
						Object.assign(this.datasets[dsIdx], dataset)
						dsIdx++;
					});
				}
			});

			//Update chart object
			this.chart.update()
		},

		//Parse store data arrays into x-y object data for chart.js
		parseData: function(data, xDataIdx, yDataIdx) {
			//Check that results have been loaded
			if (Object.keys(data).length < 1) { return [] }
			return data[yDataIdx].map((item, index) => {
				if (this.userInfo.useMR && yDataIdx === 'leverage_diff') {
					return {
						x: data[xDataIdx][index],
						y: 1 / data['leverage_bump'][index] - 1 / data['leverage'][index],
					}
				}
				if (this.userInfo.useMR && yDataIdx.startsWith('leverage')) {
					return {
						x: data[xDataIdx][index],
						y: 1 / item,
					}
				}
				return {
					x: data[xDataIdx][index],
					y: item,
				}
			});
		},

		//--------------------------------------------------------------------

		//Create and delete point sets on target used toggle
		setTargetPoints: function(key, is_create) {
			const points = [];
			const y_initial = {
				'lev': 2.7,
				'as': 100,
				'ar': 90,
				'pk': 0,
			}

			if (is_create) {
				const numPoints = 4;
				const xRange = 140;
				const xInc = xRange / (numPoints - 1);

				let slope = 0;
				slope = ['as', 'ar'].includes(key) ? -10 / xRange : slope;
				slope = ['lev'].includes(key) ? -.3 / xRange : slope;
				slope = ['pk'].includes(key) ? 20 / xRange : slope;

				for (let i = 0; i < numPoints; i++) {
					points.push({
						x: i * xInc,
						y: y_initial[key] + slope * i * xInc
					});
				}
			}

			this.setTargetData({
				item: key,
				subItem: 'points',
				value: points
			});

			this.updateChartData();
		},

		//Create chart.js dataset object for target points
		targetDataset: function(key, name) {
			return {
				backgroundColor: 'rgba(0, 0, 0, .7)',
				borderColor: 'rgba(0, 0, 0, .7)',
				pointStyle: 'rectRot',
				showLine: false,
				pointRadius: 5,
				pointHoverRadius: 9,
				pointHitRadius: 15,
				unitRounding: 1000,
				altUnits: this.plotYUnits[0],
				altLabel: name,
				data: this.targets[key].points,
			}
		},

		//Compute chart.js dataset for target point splines
		targetSplines: function(target) {
			const splinePoints = [];
			const splineX = [];
			const splineY = [];
			const points = [...this.targets[target.key].points].sort((a, b) => { return a.x - b.x });

			if (points.length > 0) {
				points.forEach(point => {
					splineX.push(point.x);
					splineY.push(point.y);
				});

				const spline = new Spline(splineX, splineY);

				const max_x = Math.max(...splineX);
				for (let i = 0; i < max_x; i++) {
					let y = spline.at(i);
					y = y > target.ylim[1] ? NaN : y
					y = y < target.ylim[0] ? NaN : y
					splinePoints.push({ x: i, y: y });
				}

				splinePoints.push({ x: max_x, y: spline.at(max_x) });
			}

			return {
				backgroundColor: 'rgba(0, 0, 0, .5)',
				borderColor: 'rgba(0, 0, 0, .5)',
				borderDash: this.lineStyles[target.pointDsIdx === 2 ? 1: 0],
				borderWidth: 2,
				pointStyle: 'round',
				showLine: true,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointHitRadius: 7,
				unitRounding: this.plotYRound[0],
				altUnits: this.plotYUnits[0],
				altLabel: target.name,
				data: splinePoints,
			}
		},

		// Create synthetic data curves for some plots
		syntheticTargetSpline: function (target = { key: 'lev', name: 'Leverage Target', pointDsIdx: 0, splineDsIdx: 1 }){
			if(!this.targets['lev'].used){ 
				this.setOptData({
					item: 'springSettings',
					value: {},
				})
				return {} 
			} // Ignore for no leverage spec

			// Create leverage spline
			const points = [...this.targets['lev'].points].sort((a, b) => { return a.x - b.x });
			
			const dWheelRaw = []
			const leverageRaw = []
			if (points.length > 0) {
				const spline = new Spline(points.map(p => p.x), points.map(p => p.y));
				const max_x = Math.max(...points.map(p => p.x));
				for (let i = 0; i < max_x; i++) {
					let y = spline.at(i);
					dWheelRaw.push(i);
					leverageRaw.push(y);
				}
				dWheelRaw.push(max_x);
				leverageRaw.push(spline.at(max_x));
			}

			const kinData = {}; // Placeholder kinematic data
			const RESOLUTION = .1;
			const dShockRaw = trapIntegral(dWheelRaw, leverageRaw.map(i => 1/i), RESOLUTION);

			// Interpolate wheel, shock, and leverage to .1mm shock increments for spring settings
			const wheelFromShock = new Spline(dShockRaw, dWheelRaw);
			const curve = splineInterp(dShockRaw, leverageRaw, RESOLUTION);

			// Assign fake result data with proper rounding
			kinData.d_shock = curve.x.map((i) => isNaN(i) ? null : round(i, 1))
			kinData.d_shockRaw = curve.x.map((i) => isNaN(i) ? null : round(i, 1))
			kinData.leverage = curve.y.map((i) => isNaN(i) ? null : i)
			kinData.d_wheel_vert = kinData.d_shock.map(i => wheelFromShock.at(i));
			kinData.d_wheel_path = kinData.d_wheel_vert

			kinData.wheel_rate = curve.y.map((i) => isNaN(i) ? null : i)
			kinData.wheel_force = curve.y.map((i) => isNaN(i) ? null : i)
			kinData.energy = curve.y.map((i) => isNaN(i) ? null : i)

			const { springSettings, kinematicData } = applySpringForce(this.bikeData, this.bikeViewData, kinData)
			this.setOptData({
				item: 'springSettings',
				value: springSettings,
			})

			if(!kinematicData || target.key === 'lev') { return {} }
			return {
				backgroundColor: 'rgba(0, 0, 0, .5)',
				borderColor: 'rgba(0, 0, 0, .5)',
				borderDash: this.lineStyles[0],
				borderWidth: 2,
				pointStyle: 'round',
				showLine: true,
				pointRadius: 0,
				pointHoverRadius: 3,
				pointHitRadius: 7,
				unitRounding: this.plotYRound[0],
				altUnits: this.plotYUnits[0],
				altLabel: target.name,
				data: kinematicData.d_wheel_vert.map((x, idx) => { return { x, y: kinematicData[target.key][idx] }	}),
			}
		},

		//--------------------------------------------------------------------

		//Create or delete target points on dblclick
		canvasDblClick: function(event) {
			if (!this.optimizeEnabled) { return; }
			const maxNumPoints = 9;
			const minNumPoints = 2;
			const elem = this.chart.getElementAtEvent(event);
			if (elem.length > 0) {
				const dsIdx = elem[0]._datasetIndex;

				this.plotTargets.forEach((target, index) => {
					//Check first if click is on a spline point. If so delete
					//	If not then check if click is on spline it. If so add point
					let pointArr = this.targets[target.key].points;
					if (dsIdx === target.pointDsIdx) {
						if (pointArr.length > minNumPoints) {
							pointArr.splice(elem[0]._index, 1);
							this.setTargetData({
								item: target.key,
								subItem: 'points',
								value: pointArr
							});
							Object.assign(this.datasets[target.splineDsIdx], this.targetSplines(target))
							this.chart.update({ duration: 300 });
						}
					} else if (dsIdx === target.splineDsIdx) {
						if (pointArr.length < maxNumPoints) {
							pointArr.push(this.getPlotPos(event));
							Object.assign(this.datasets[target.splineDsIdx], this.targetSplines(target))
							this.chart.update({ duration: 0 });
						}
					}
				});
			}
		},

		//Remove move listener on mouse release
		canvasUp: function() {
			this.targetPointIndex = null;
			this.targetNumber = null;
		},

		//Handle click in chart area
		canvasDown: function(event) {
			if (!this.optimizeEnabled) { return; }
			this.targetPointsIndex = null;
			this.targetNumber = null;

			const elem = this.chart.getElementAtEvent(event);
			if (elem.length > 0) {
				const dsIdx = elem[0]._datasetIndex;

				//Search target list to see if element selected is in the pointDsIdx
				this.plotTargets.forEach((target, index) => {
					if (dsIdx === target.pointDsIdx) {
						this.targetPointIndex = elem[0]._index;
						this.targetNumber = index;
					}
				});
			}
		},

		//Move target point 
		canvasMove: function(event) {
			const idx = this.targetPointIndex
			const target = this.plotTargets[this.targetNumber];
			let pointArr = this.targets[target.key].points;
			const delta = this.getPlotDelta(event);

			pointArr[idx] = {
				x: pointArr[idx].x + delta.dx,
				y: pointArr[idx].y + delta.dy,
			}

			pointArr[idx].x = pointArr[idx].x < 0 ? 0 : pointArr[idx].x;

			this.setTargetData({
				item: target.key,
				subItem: 'points',
				value: pointArr
			});

			// Update spring if lev is changed
			if(target.key === 'lev') {this.syntheticTargetSpline()}

			Object.assign(this.datasets[target.splineDsIdx], this.targetSplines(target))

			this.chart.update({ duration: 0, lazy: true });
		},

		//Convert mouse movement amount into plot units
		getPlotDelta: function(event) {
			let dx = 0;
			let dy = 0;
			for (let scaleName in this.chart.scales) {
				const scale = this.chart.scales[scaleName];
				if (scale.isHorizontal()) {
					dx = scale.getValueForPixel(event.offsetX) - scale.getValueForPixel(event.offsetX - event.movementX / window.devicePixelRatio);
				} else {
					dy = scale.getValueForPixel(event.offsetY) - scale.getValueForPixel(event.offsetY - event.movementY / window.devicePixelRatio);
				}
			}
			return { dx, dy }
		},

		//Convert mouse position into plot units
		getPlotPos: function(event) {
			let valueX = null;
			let valueY = null;
			for (let scaleName in this.chart.scales) {
				const scale = this.chart.scales[scaleName];
				if (scale.isHorizontal()) {
					valueX = scale.getValueForPixel(event.offsetX);
				} else {
					valueY = scale.getValueForPixel(event.offsetY);
				}
			}
			return { x: valueX, y: valueY }
		},
	},
};
</script>
<style>
.mainplot {
	flex: 1;
	padding: 1rem;

	overflow: hidden;
	display: flex;
	justify-content: space-between;
}

.mainplot__canvas-cont {
	position: relative;
	height: 100%;
}

.mainplot__stacked {
	width: 100%;
	height: 49%;
}

.mainplot__split {
	height: 100%;
	width: 49%
}

@media(max-width: 500px) {
	.mainplot {
		height: 50vh;
		padding: 1rem 3px;
	}
}
</style>