<template>
	<div class="scroll-y">
		<CollapsibleContainer :sections="containerContent" containerName="chipconfig-modal" @event-cc-click="selectCCEvent">
			<template v-slot:slot-0>
				<TextInputMD name="Chip Name" :value="chipData['name']" @submit="changeChipData($event, 'name')" :disabled="isDefault" margin="1.5rem 1rem 1rem 1rem" />
				<div class="flex-row margin--bottom">
					<div class="flex-1 padding--half border--right">
						<TextInputMD v-for="(item, index) in suspModKeys" :name="suspModNames[index]" :value="chipData[item]" @submit="changeChipData($event, item)" :disabled="isDefault" :key="item" />
					</div>
					<div class="flex-1 padding--half">
						<TextInputMD v-for="(item, index) in geoModKeys" :name="geoModNames[index]" :value="chipData[item]" @submit="changeChipData($event, item)" :disabled="isDefault" :key="item" />
					</div>
				</div>
			</template>
			<template v-slot:slot-1>
				<template v-for="item in linkTables" style="max-width: 30rem">
					<InputTable v-if="item.table.length > 0" :tableStyle="tableStyle" :headerRow="[item.name, 'x', 'y']" :tableName="item.name" :tableSetup="item.table" :key="item.name" @change="pointInput(item.refs, ...arguments)" />
				</template>
			</template>
		</CollapsibleContainer>
	</div>
</template>
<script>
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import InputTable from "@/components/functional/InputTable.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: "FlipChipAdjust",
	components: {
		CollapsibleContainer,
		InputTable,
		TextInputMD,
	},

	data() {
		return {
			containerContent: [{
					name: 'Geometry Changes',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Point Offsets',
					checked: false,
					noHighlight: true,
					style: 'padding: 1rem'
				}
			],

			tableStyle: {
				paddingRight: '.5rem',
				paddingBottom: '1rem',
			},

			geoModNames: [
				'Stem Spacer Change', 'Stem Length Change', 'Bar Height Change',
				'Lower Headset', 'Head Angle Change', 'Reach Adjust',
			],

			geoModKeys: [
				'stemSpacers', 'stemLength', 'barHeight',
				'lowerHeadset', 'hta', 'reach',
			],

			suspModNames: [
				'Fork Length Change', 'Fork Offset Change', 'Shock Length Change',
				'Shock Stroke Change', 'Rear Tire Diameter', 'Front Tire Diameter',
			],
			suspModKeys: [
				'forkLength', 'offset', 'shockLength',
				'shockStroke', 'rearWheel', 'frontWheel',
			]

		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateBikeData', ['pointNames']),
		...mapState('stateViewLev', ['isMobile', 'liveMode']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },
		chipConfigs: function() { return this.bikeData.solverInfo.chipConfigs; },
		currentChipIndex: function() { return this.bikeData.solverInfo.selectedChip; },
		chipData: function() { return this.chipConfigs[this.currentChipIndex]; },

		//Disable changes for default config
		isDefault: function() { return this.currentChipIndex === 0 ? true : false; },

		//Determine what points can be moved per linkage
		linkagePointConfig: function() {
			// Most points have a primary and secondary link and can be moved on either
			// Two complete point arrays are required to specify possible chip configurations 

			// BB, Rear Wheel, Shock 1, Shock 2, Idler
			// p0, p1, p2, p3, p11 - Primary Only

			// p4 - Frame Primary, L1 Secondary
			// p5 - L1 Primary, L2 Secondary
			// p6 - L2 Primary, L3 Secondary
			// p7 - L3 Primary, Frame Secondary
			// p8 - Alt Primary, L4 Secondary
			// p9 - L4 Primary, L5 Secondary
			// p10 - L5 Primary, Alt Secondary
			// p12 - Brake 1 Primary, Brake 2 Secondary
			// p13 - Brake 2 Primary, Alt Secondary


			const linkNames = ['Frame', 'Link 1', 'Link 2', 'Link 3', 'Link 4', 'Link 5', 'Brake Arm 1', 'Brake Arm 2'];

			const solverInfo = this.bikeData.solverInfo;

			//-------------------------------------------------------------------------
			// Linkage points with both primary and secondary offsets

			const links = [
				[{ point: 4, ref: 'primary' }], //Frame
				[{ point: 4, ref: 'secondary' }], //Link 1
				[], //Link 2
				[], //Link 3
				[], //Link 4
				[], //Link 5
				[], //Brake Arm 1
				[], //Brake Arm 2
			]

			// If the bike is not a single pivot add points
			if (solverInfo.linkageType > 0) {
				links[0].push({ point: 7, ref: 'secondary' })
				links[1].push({ point: 5, ref: 'primary' })
				links[2].push({ point: 5, ref: 'secondary' }, { point: 6, ref: 'primary' })
				links[3].push({ point: 6, ref: 'secondary' }, { point: 7, ref: 'primary' })
			}

			// For 6 bar solver attach points to link arrays
			if (solverInfo.linkageType === 2) {
				links[solverInfo.link4AttachedTo].push({ point: 8, ref: 'primary' })
				links[solverInfo.link5AttachedTo].push({ point: 10, ref: 'secondary' })

				links[4].push({ point: 8, ref: 'secondary' }, { point: 9, ref: 'primary' })
				links[5].push({ point: 9, ref: 'secondary' }, { point: 10, ref: 'primary' })
			}

			// For additonal brake arm links push points
			if (solverInfo.usesBrakeArm) {
				links[6].push({ point: 12, ref: 'primary' }) //Add brake arm joint
				links[7].push({ point: 12, ref: 'secondary' }, { point: 13, ref: 'primary' })
				links[solverInfo.brakeAttachedTo].push({ point: 13, ref: 'secondary' })

			}

			//-------------------------------------------------------------------------
			// Attachements without secondary offsets
			links[solverInfo.bbAttachedTo].push({ point: 0, ref: 'primary' })
			links[solverInfo.wheelAttachedTo].push({ point: 1, ref: 'primary' })
			links[solverInfo.shock1AttachedTo].push({ point: 2, ref: 'primary' })
			links[solverInfo.shock2AttachedTo].push({ point: 3, ref: 'primary' })

			if (solverInfo.usesIdler) {
				links[solverInfo.idlerAttachedTo].push({ point: 11, ref: 'primary' })
			}

			links.forEach(item => item.sort())

			return {
				links,
				linkNames,
			}
		},

		//Create list of link objects to create input tables
		linkTables: function() {
			const links = this.linkagePointConfig.links;

			const tables = links.map((link, idx) => {
				//Create formatted array for input table
				// ['row name', 'value array for row', 'show/hide row', 'disable input']
				const inputArray = link.map(pivot => {
					const inputRow = [
						this.pointNames[pivot.point],
						[
							this.chipConfigs[this.currentChipIndex][pivot.ref][pivot.point][0],
							this.chipConfigs[this.currentChipIndex][pivot.ref][pivot.point][1],
						],
						true, //Show row
						this.isDefault, //Enable/Disable
					];

					if (this.bikeData.solverInfo.sliderRef === 1) {
						if (pivot.point === 6) { inputRow[2] = false; }
						if (pivot.point === 7) { inputRow[2] = false; }
					}
					if (this.bikeData.solverInfo.sliderRef === 2) {
						if (pivot.point === 6 && idx !== 2) { inputRow[2] = false; }
						if (pivot.point === 7) { inputRow[2] = false; }
					}

					return inputRow
				});

				//Group link name, table configuration, and link reference for handling inputs
				return {
					name: this.linkagePointConfig.linkNames[idx],
					table: inputArray,
					refs: link,
				}
			});

			return tables;
		}
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),

		//Manage collapsible container state
		selectCCEvent: function(index) {
			this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		//Send chip changes to store
		setChipData: function(item, value, point = false, x_y = false) {
			//Extra check to prevent changing default config
			if (this.currentChipIndex === 0) { return; }
			let payload = {
				chipIndex: this.currentChipIndex,
				item: item,
				value: value,
				commit: 'PUTCHIPDATA'
			}

			if (point !== false) {
				payload.point = point;
				payload.x_y = x_y;
			}

			this.setData(payload);
			if (this.liveMode) { this.sendAndSolve(); }
		},

		//Handle point offset inputs
		pointInput: function(link, row, col, value, prev) {
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value;

			//No updates if the value has not changed
			if (value === prev) { return; }
			this.setChipData(link[row].ref, value, link[row].point, col);
		},

		//Handle chip geo param inputes
		changeChipData: function(value, key) {
			if (key === 'name') {
				value = value.trim();
			} else {
				value = parseFloat(value);
				value = isNaN(value) ? 0 : value;
			}

			//No updates if the value has not changed
			if (value === this.chipData[key]) { return; }
			this.setChipData(key, value);
		},
	},
}
</script>
<style>
</style>