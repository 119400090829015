<template>
	<div class="fill">
		<CollapsibleContainer :sections="containerContent" containerName="bike-info-tab" @event-cc-click="selectCCEvent">
			<template v-slot:slot-0>
				<div class="padding">
					<RadioSelector label="Live Update:" :optionArray="liveModeSetup" @click="changeItem($event, 'liveMode')" />
					<RadioSelector v-if="!isMobile" label="Screen Style:" :optionArray="screenSetup" @click="changeItem($event, 'splitPlot')" />
					<RadioSelector label="Spring Units:" :optionArray="unitModeSetup" @click="changeItem($event, 'isMetric')" />
					<RadioSelector label="Leverage Style:" :optionArray="leverageModeSetup" @click="saveUserSetting($event, 'useMR')" />
					<RadioSelector label="Block Bike Sending from External Email:" :optionArray="emailPref" @click="saveUserSetting($event, 'block_ext',)" />
				</div>
			</template>
			<template v-slot:slot-1>
				<InfoPanelSetup />
			</template>
		</CollapsibleContainer>
	</div>
</template>
<script>
import InfoPanelSetup from "@/components/levapp/InfoPanelSetup.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import { mapActions, mapState } from "vuex";
import { fs_save_userData } from "@/firebaseConfig.js";

export default {
	name: "TabSettings",
	components: {
		InfoPanelSetup,
		CollapsibleContainer,
		RadioSelector,
	},

	data() {
		return {
			containerContent: [{
					name: 'General Settings',
					checked: true,
					noHighlight: true,
				},
				{
					name: 'Info Panel Configuration',
					checked: false,
					noHighlight: true,
				},
			],
		}
	},

	computed: {
		...mapState('stateViewLev', ['isMobile', 'liveMode', 'splitPlot', 'isMetric', 'userInfo']),

		liveModeSetup: function() {
			return [
				['Off', !this.liveMode, true],
				['On', this.liveMode, true],
			];
		},

		screenSetup: function() {
			return [
				['Single', this.splitPlot === 0, true],
				['Split', this.splitPlot === 1, true],
				['Stacked', this.splitPlot === 2, true],
			];
		},

		unitModeSetup: function() {
			return [
				['US Std.', !this.isMetric, true],
				['Metric', this.isMetric, true],
			];
		},

		leverageModeSetup: function() {
			const state = this.userInfo.useMR ? true : false;
			return [
				['Leverage', !state, true],
				['Motion Ratio', state, true],
			];
		},

		emailPref: function() {
			const state = this.userInfo.block_ext ? true : false;
			return [
				['Off', !state, true],
				['On', state, true],
			];
		},
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'loadNewBike', 'loadDBBike', 'sendAndSolve']),
		...mapActions('stateViewLev', ['setViewData', 'setError']),

		selectCCEvent: function(index) {
			this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		changeItem: function(result, key) {
			this.setViewData({
				item: key,
				value: result,
			});
		},


		saveUserSetting: async function(state, item) {
			const data = {};
			data[item] = state;
			const res = await fs_save_userData(data);
			if (res !== 'success') {
				this.setError('Issues saving. Please check your connection and try again.');
			}
		},
	}
};
</script>
<style>
</style>