<template>
	<div class="error-container card-shadow" :style="{right: ballPosX + 'px', bottom: ballPosY + 'px'}">
		<transition name="scale">
			<div v-if="showMessage" :style="messageBoxStyle" class="error-ball__msg-box card-shadow dark-panel">
				<div v-for="(item,index) in errorMessages" :key="'error-ball-item-' + index" class="error-ball__msg-line">{{index + 1}}. {{ item }}
					<div class="error-list-item-icon" @mouseup.stop="removeError(index)">
						<IconClose />
					</div>
				</div>
				<div v-if="errorMessages.length < 1" class="error-ball__msg-line">No errors to display</div>
				<MessageList v-if="messageList.length > 0" />
			</div>
		</transition>
		<div ref="errBall" class="error-ball bg-gradient" :class="{'error-ball--error' : activeError}" @mousedown="beginDrag">
			<div class="error-ball__svg-cont">
				<svg class="error-ball__svg" :class="{'error-ball__icon-is-invalid' : activeError}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
					<!-- <path d="m 0 60 l 30 30 l 70 -80" class="error-ball__svg-path error-ball__svg-check-mark" /> -->
					<path d="m 50 50 l 40 -40" class="error-ball__svg-path error-ball__svg-x-mark" />
					<path d="m 50 50 l 40 40" class="error-ball__svg-path error-ball__svg-x-mark" />
					<path d="m 50 50 l -40 -40" class="error-ball__svg-path error-ball__svg-x-mark" />
					<path d="m 50 50 l -40 40" class="error-ball__svg-path error-ball__svg-x-mark" />
				</svg>
			</div>
		</div>
	</div>
</template>
<script>
import IconClose from "@/components/icons/IconClose.vue";
import MessageList from "@/components/MessageList.vue";
import { mapState, mapActions } from 'vuex';

export default {
	name: 'ErrorBall',

	components: {
		IconClose,
		MessageList,
	},

	props: {},

	data() {
		return {
			//Error ball parameters
			ballPosX: 30,
			ballPosY: 20,

			initBallPosX: 0,
			initBallPosY: 0,

			ballSize: 5,

			mousePosX: 0,
			mousePosY: 0,

			//Only run event when clicked on ball
			isDragging: false,

			//Error message parameters
			showMessage: false,
			hideOnDrop: false,
			isClearing: false,
			activeError: false,
			errorTimeout: '', //Allow reseting of error timeout
		};
	},

	//Document listeners for window resize to maintain bounds
	mounted() { window.addEventListener("resize", this.manageResize); },
	beforeDestroy() { window.removeEventListener("resize", this.manageResize); },

	watch: {
		//Update whenever bikes are added/removed from the database
		errorMessages: {
			handler() {
				if (!this.isClearing) {
					clearTimeout(this.errorTimeout)
					this.activeError = true;
					this.showMessage = true;
					this.errorTimeout = setTimeout(() => {
						this.activeError = false;
						this.showMessage = false;
					}, 4000)
				}
				this.isClearing = false;
			}
		},

		messageList: {
			handler() {
				if (!this.isClearing) {
					clearTimeout(this.errorTimeout)
					this.activeError = false;
					this.showMessage = true;
					this.errorTimeout = setTimeout(() => {
						this.showMessage = false;
					}, 7000)
				}
				this.isClearing = false;
			}
		}
	},

	computed: {
		...mapState('stateViewLev', ['errorMessages', 'messageList']),

		//Dynamic style to handle different display directions
		messageBoxStyle: function() {
			let cornerVert = 'top';
			let cornerHorz = 'right';
			const style = {
				right: '5.25rem',
				left: '',
				top: '1.5rem',
				bottom: '',
			}
			if (this.ballPosX > document.body.clientWidth / 2) {
				style.right = '';
				style.left = '5.25rem';
				cornerHorz = 'left';
			}
			if (this.ballPosY < document.body.clientHeight / 2) {
				style.bottom = '1.5rem';
				style.top = '';
				cornerVert = 'bottom';
			}

			style.transformOrigin = cornerVert + ' ' + cornerHorz;
			return style
		}
	},

	methods: {
		...mapActions('stateViewLev', ['clearError', 'setError']),

		//On resize insure error ball is in bounds
		manageResize: function() {
			this.ballPosX = 30;
			this.ballPosY = 20;
		},

		//Add window listeners on mouse and start drag function
		beginDrag: function(val) {
			clearTimeout(this.errorTimeout);
			setTimeout(() => { this.activeError = false; }, 500);

			window.addEventListener('selectstart', this.disableSelect);
			document.addEventListener('mousemove', this.dragging);
			document.addEventListener('mouseup', this.endDrag);
			this.hideOnDrop = false;
			this.isDragging = true;
			this.initBallPosX = this.ballPosX;
			this.initBallPosY = this.ballPosY;
			this.mousePosX = val.clientX;
			this.mousePosY = val.clientY;
		},

		//Remove window listeners and handled viewbox
		endDrag: function() {
			window.removeEventListener('selectstart', this.disableSelect);
			document.removeEventListener('mousemove', this.dragging);
			document.removeEventListener('mouseup', this.endDrag);
			this.showMessage = this.hideOnDrop ? false : !this.showMessage;
			this.hideOnDrop = false;
			this.isDragging = false;
		},

		//Handle movement while dragging and maintain inside bounds
		dragging: function(val) {
			if (this.isDragging) {
				this.showMessage = false;
				const dia = 10 * this.ballSize + 10;
				const deltaMouseX = -val.clientX + this.mousePosX;
				const deltaMouseY = -val.clientY + this.mousePosY;

				let xpos = this.initBallPosX + deltaMouseX;
				let ypos = this.initBallPosY + deltaMouseY;

				xpos = xpos < 10 ? 10 : xpos;
				xpos = xpos > document.body.clientWidth - dia ? document.body.clientWidth - dia : xpos;
				this.ballPosX = xpos;

				ypos = ypos < 10 ? 10 : ypos;
				ypos = ypos > document.body.clientHeight - dia ? document.body.clientHeight - dia : ypos;
				this.ballPosY = ypos;

				if (deltaMouseX > 0 || deltaMouseY > 0) { this.hideOnDrop = true; }
			}
		},

		//Disable all selecting while dragging
		disableSelect: function(event) {
			event.preventDefault();
		},

		//Clear error from the store and list
		removeError: function(index) {
			this.clearError(index);
			this.isClearing = true;
		},

		pauseTimeout: function() {
			this.activeError = false;
			clearTimeout(this.errorTimeout);
		}
	}
}
</script>
<style>
.error-container {
	position: absolute;
	width: 5rem;
	height: 5rem;
	border-radius: 2.5rem;
	background-color: var(--color-bg);
	z-index: 9;
}

.error-ball {
	position: absolute;
	top: 0;
	left: 0;
	width: 5rem;
	height: 5rem;

	border-radius: 2.5rem;
	cursor: grab;
}

.error-ball__msg-box {
	position: absolute;
	max-height: 37rem;
	width: 25rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;

	border-radius: .5rem;
	color: var(--color-font-bg);
	background-color: var(--color-bg);
	cursor: default;

	text-align: left;
	word-wrap: break-word;
	overflow-y: auto;

	transition: .5s
}

.error-ball__msg-line {
	display: flex;
	justify-content: space-between;

	background-color: transparent;
	padding: .7rem 0;
	border-bottom: .1rem solid var(--color-bg-secondary-light);
}

.error-ball__msg-line:first-child {
	padding-top: 0;
}

.error-list-item-icon {
	display: block;
	min-width: 1.5rem;
	width: 1.5rem;
	height: 1..5rem;
	margin-left: .25rem;
	border-radius: .3rem;
	fill: var(--color-font-bg);
	transition: .3s;
}

.error-list-item-icon:hover {
	fill: var(--color-bg-highlight);
	cursor: pointer;
}

/*-----------------------*/
.error-ball__svg-cont {
	width: 100%;
	height: 100%;
	padding: 1.25rem;

	stroke: var(--color-font-primary);
	stroke-linecap: round;
	stroke-linejoin: round;
	border-radius: 2.5rem;
}

.error-ball--error {
	background-color: var(--color-error);
}

.error-ball__svg-path {
	fill: none;
	stroke-width: 17;

	transition: .3s;
}

.error-ball__svg-x-mark {
	stroke-dasharray: 57;
	stroke-dashoffset: 57;
}

.error-ball__icon-is-invalid .error-ball__svg-x-mark {
	stroke-dashoffset: 0;
}
</style>