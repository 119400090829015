<template>
	<div class="flex-1 margin--full">
		<RadioSelector :optionArray="dbSelectOptions" @click="changeDatabase" test="load-bike-panel-select-db" />
		<AutocompleteList test="load-bike-brand" v-bind="propsBikeSearch('brand', 'List of Brands' )" @input="setBikeInfoEvent($event, 'brand')" @submit="brandListSubmitEvent($event, 'brand')" :autoselect="autoselect" :maxNumItems="6" />
		<!--  -->
		<AutocompleteList test="load-bike-year" v-bind="propsBikeSearch('modelYear', 'List of Model Years')" @input="setBikeInfoEvent($event, 'modelYear')" @submit="yearListSubmitEvent($event, 'modelYear' )" :autoselect="autoselect" :maxNumItems="6" />
		<!--  -->
		<AutocompleteList test="load-bike-name" v-bind="propsBikeSearch('modelName', 'List of Model Names')" @input="setBikeInfoEvent($event, 'modelName')" @submit="modelNameListSubmitEvent($event, 'modelName' )" :autoselect="autoselect" :maxNumItems="6" />
		<!--  -->
		<AutocompleteList test="load-bike-version" v-bind="propsBikeSearch('version', 'List of Versions')" @input="setBikeInfoEvent($event, 'version')" @submit="versionListSubmitEvent($event, 'version' )" :autoselect="autoselect" :maxNumItems="3" />
		<div class="multi-button-cont">
			<ButtonStandard test="load-bike-new" @click="createNewBike" :flex="true" :name="'Create New'" clear="true" />
			<ButtonStandard test="load-bike-load" @click="loadBike" :flex="true" :name="'Load'" />
		</div>
	</div>
</template>
<script>
import AutocompleteList from "@/components/levapp/AutocompleteList.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import { fs_loadBike } from "@/firebaseConfig.js";

const debug = false;
export default {
	name: "TabLoadObjects",
	components: {
		AutocompleteList,
		ButtonStandard,
		RadioSelector,
	},

	data() {
		return {

			//Control variables for bike search-----------
			searchLists: {
				brand: [],
				modelYear: [],
				modelName: [],
				version: [],
			},

			bikeInputLabels: {
				brand: "Brand",
				modelYear: "Model Year",
				modelName: "Model Name",
				version: "Version",
			},

			// completeBikeList: [],
			//--------------------------------------------

			//Keep local copy of all search variables
			localParams: {
				brand: "",
				modelYear: "",
				modelName: "",
				version: "",
			},
			autoselect: true,

			bikePrivateKey: '', //Key of bike to request
		}
	},

	//Bind collection of bikeInfo's to variable
	watch: {
		completeBikeList: {
			immediate: true,
			handler() {
				this.filterLocalList();
			}
		},
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapGetters('stateViewLev', ['get_fs_database']),
		...mapState('stateViewLev', ['user', 'claims', 'loadBikeDB',
			'publicBikeList', 'privateBikeList', 'teamBikeList',
		]),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex'); },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length; },
		maxNumberBikes: function() { return this.getData('maxNumberBikes'); },

		completeBikeList: function() {
			if (this.loadBikeDB === 0) { return this.publicBikeList; }
			if (this.loadBikeDB === 1) { return this.privateBikeList; }
			if (this.loadBikeDB === 2) { return this.teamBikeList; }

			return this.privateBikeList; //Default. Should not be needed.
		},

		fs_location: function() { return this.get_fs_database(this.loadBikeDB); },

		dbSelectOptions: function() {
			const optionArray = [
				['Public', false, true],
				['Private', false, true],
				['Team', false, false],
				// ['Local', false, false],
			];
			if (this.claims.team_id) { optionArray[2][2] = true; }
			// if (process.env.IS_ELECTRON) { optionArray[3][2] = true; }

			optionArray[this.loadBikeDB][1] = true;

			return optionArray;
		},
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'loadNewBike', 'loadDBBike']),
		...mapActions('stateViewLev', ['setError', 'setShort']),

		selectCCEvent: function(index) {
			// this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = !this.containerContent[index]['checked'];
		},

		changeDatabase: function(value) {
			this.setShort(['loadBikeDB', value]);
			this.clearInfo();
			this.bikePrivateKey = '';
		},

		//Clear all fields and reset local params
		clearInfo: function() {
			this.localParams = {
				brand: "",
				modelYear: "",
				modelName: "",
				version: "",
			};
			this.searchLists.modelName = [];
			this.searchLists.version = [];
			this.filterLocalList();
			// this.bikePrivateKey = '';
		},

		//Load bike template
		createNewBike: function() {
			if (this.bikeArrayLength < this.maxNumberBikes) {
				this.loadNewBike(); //Trigger action to load new bike into store
			} else {
				this.setError('Too many bikes loaded');
			}
		},

		//Call db for specific bike
		loadBike: async function() {
			if (this.bikePrivateKey !== '') {
				if (this.bikeArrayLength < this.maxNumberBikes) {
					if (debug) { console.log('lets load bike ' + this.bikePrivateKey); }
					this.localParams = {
						brand: "",
						modelYear: "",
						modelName: "",
						version: "",
					};

					//LOAD BIKE HERE USING KEY
					const response = await fs_loadBike(this.bikePrivateKey, this.fs_location)

					if (!response.error) {
						this.loadDBBike(response.bikeObj)
						this.clearInfo();
					} else {
						this.setError('Server Error')
					}
				} else { this.setError('Too many bikes loaded') }
			} else {
				this.setError('Invalid search parameters')
			}

		},

		//Generate props for autocorrect input
		propsBikeSearch: function(nameKey, aria) {
			return {
				itemList: this.searchLists[nameKey],
				inputLabel: this.bikeInputLabels[nameKey],
				ariaLabel: aria,
				value: this.localParams[nameKey],
			};
		},

		//Set field input as local search string
		setBikeInfoEvent: function(value, nameKey) { this.localParams[nameKey] = value; },

		//On submitting brand clear fields and filter
		brandListSubmitEvent: function(value, nameKey) {
			this.$emit('require-focus');
			this.setBikeInfoEvent(value, nameKey);
			this.setBikeInfoEvent("", 'modelYear');
			this.setBikeInfoEvent("", 'modelName');
			this.setBikeInfoEvent("", 'version');
			this.filterLocalList();
		},

		//On submitting year clear fields and filter
		yearListSubmitEvent: function(value, nameKey) {
			this.$emit('require-focus');
			this.setBikeInfoEvent(value, nameKey);
			this.setBikeInfoEvent("", 'modelName');
			this.setBikeInfoEvent("", 'version');
			this.filterLocalList();
		},

		//On submitting bike split name if needed
		modelNameListSubmitEvent: function(value, nameKey) {
			this.$emit('require-focus');
			this.setBikeInfoEvent("", 'version');
			let splitName = undefined; //initialize

			//When submitted value is not in list value = undefined
			if (typeof(value) !== 'undefined') {
				splitName = value.split(' | '); //Split combined names
				if (splitName.length > 1) {
					value = splitName[2]; //Provide third value (name) from split for combined names
					this.setBikeInfoEvent(splitName[0], 'brand'); //If selected then brand and year can be populated
					this.setBikeInfoEvent(splitName[1], 'modelYear');
				}
			}

			this.setBikeInfoEvent(value, nameKey); //Set local value regardless
			this.filterLocalList(splitName); //Give filter function the full bike/brand/year 
		},

		//Will always be final selection
		versionListSubmitEvent: function(value, nameKey) {
			this.$emit('require-focus');
			this.setBikeInfoEvent(value, nameKey);
			this.filterLocalList();
		},

		filterLocalList: function(selectedDup) {
			//Copy reduced bike list so that no changes are made to global list
			//	Global list is synced with firebase
			let reducedList = [...this.completeBikeList];

			//Flag will be set when temp list is filtered to one bike
			let singleBikeFlag = false;

			//Define equality filters for inputs
			function filterBrand(item) { return item['brand'] === this.brand; }

			function filterYear(item) { return item['modelYear'] === this.modelYear; }

			function filterName(item) { return item['modelName'] === this.modelName; }

			function filterVersion(item) { return item['version'] === this.version; }

			//Brand list will always show complete set. Map gets distinct values
			this.searchLists['brand'] = [...new Set(reducedList.map(e => e['brand']))];

			//Filter out all bikes that don't match inputed brand
			if (this.localParams.brand !== "") {
				reducedList = reducedList.filter(filterBrand, this.localParams);
			}

			//Find distinct years using brand to filter initially
			this.searchLists['modelYear'] = [...new Set(reducedList.map(e => e['modelYear']))];

			//Filter out all bikes that don't match inputed model year
			if (this.localParams.modelYear !== "") {
				reducedList = reducedList.filter(filterYear, this.localParams);
			}

			//ReducedList has all possible bikes that match current brand and model year. 

			let nameArray = []; //Array containing all modelNames
			let combinedNameArray = []; //Array containing modelYear/brand strings to add if needed

			//For each item in the reduced list both a combined name and the base name
			reducedList.forEach(function(e) {
				nameArray.push(e['modelName']);
				combinedNameArray.push(e['brand'] + ' | ' +
					e['modelYear'] + ' | ' +
					e['modelName'])
			})

			//Remove duplicates from both arrays
			nameArray = [...new Set(nameArray)]; //Is a more optimized method here using filter
			combinedNameArray = [...new Set(combinedNameArray)];

			//If the arrays are not the same length then there is a bike with 
			//	the same model name but different brand/year
			//		use combined name in this case
			if (nameArray.length !== combinedNameArray.length) {
				nameArray = combinedNameArray;
			}

			//Set distinct names or name/brand/year sets
			this.searchLists['modelName'] = nameArray;

			//-------------------------------------------------------
			//Name and version must be filtered after name checks to insure
			//the populated list will include options

			// //Filter out all bikes that don't match inputed model name
			if (this.localParams.modelName !== "") {
				reducedList = reducedList.filter(filterName, this.localParams);
			}

			//Use copy of reduced list for version filtering only
			let versionReducedList = [...reducedList]
			//Filter out all bikes that don't match inputed version
			if (this.localParams.version !== "") {
				versionReducedList = reducedList.filter(filterVersion, this.localParams);
			}
			//-------------------------------------------------------

			//Check to see if there is only 1 bike remaining in list (ie. filtering complete)
			if (versionReducedList.length === 1) {
				this.localParams = { ...versionReducedList[0] };
				singleBikeFlag = true;
				if (debug) { console.log('wooo one bike'); }
			}

			//Default to empty version list assuming other params have not been fully selected
			this.searchLists['version'] = [];

			function combineName(item) {
				return item['brand'] + item['modelYear'] + item['modelName'];
			}

			//Check if name exists in the list
			const nameInList = reducedList.find(e => e['modelName'] === this.localParams['modelName']);

			//If selectedDup has 3 values then a bike has been selected including all info 
			// due to combined name including all relevant info
			if (typeof(selectedDup) !== 'undefined' && selectedDup.length > 1) {
				//Check if there is a bike in the list that matches the brand/year/name
				const comboName = selectedDup[0] + selectedDup[1] + selectedDup[2];
				const comboNameInList = reducedList.find(e => combineName(e) === comboName);
				if (typeof(comboNameInList) !== 'undefined') {
					//Set the version search list to all values possible for the combined name
					this.searchLists['version'] = reducedList.map((item) => {
						if (combineName(item) === comboName) { return item['version']; }
					})

					//If there is only one option then flag
					if (this.searchLists['version'].length === 1) {
						this.localParams['version'] = this.searchLists['version'][0]; //Set value 
						singleBikeFlag = true;
					}
				}
			} else if (typeof(nameInList) !== 'undefined') {
				//else there is not a combined name and we need to check versions normally
				this.searchLists['version'] = reducedList.map((item) => {
					if (item['modelName'] === this.localParams['modelName']) {
						return item['version'];
					}
				});
			}

			//Filter reduced list by version after version list has been set
			if (this.localParams.version !== "") {
				reducedList = reducedList.filter(filterVersion, this.localParams);
			}

			if (singleBikeFlag) {
				if (debug) { console.log(reducedList); }
				this.bikePrivateKey = reducedList[0].id;
				if (debug) { console.log('Search Key is: ' + this.bikePrivateKey); }
			}
		} //endfunc filterLocalList
	}
};
</script>
<style>
</style>