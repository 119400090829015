<template>
	<div class="fill">
		<CollapsibleContainer v-if="isBikesLoaded" :sections="containerContent" containerName="linkage-input-tab" @event-cc-click="selectCCEvent">
			<!--  -->
			<!-- Bike Info -->
			<template v-slot:slot-0>
				<div class="padding--half">
					<TextInputMD v-for="(item, index) in bikeInfo.key" :key="'text-md-key-bikeinfo-' + index + keymod" :name="bikeInfo.label[index]" :value="bikeInfoValue(item, bikeInfo.subkey[index])" @submit="validateData($event, bikeInfo.validator[index], item, bikeInfo.subkey[index])" :test="item + '-' + bikeInfo.subkey[index]" />
					<RadioSelector test="select-bike-database" v-if="showDatabase" :optionArray="databaseOptions" label="Database:" @click="setDatabase($event)" />
				</div>
			</template>
			<!--  -->
			<!-- Drivetrain -->
			<template v-slot:slot-1>
				<div class="padding--half">
					<template v-for="(item, index) in driveInfo.key">
						<TextInputMD v-if="!(driveInfo.subkey[index] === 'idlerSize' && !usesIdler)" :key="'text-md-key-drivetraininfo-' + index" :name="driveInfo.label[index]" :value="bikeInfoValue(item, driveInfo.subkey[index])" @submit="validateData($event, driveInfo.validator[index], item, driveInfo.subkey[index])" :test="item + '-' + driveInfo.subkey[index]" />
					</template>
					<!--  -->
					<RadioSelector test="select-usesIdler" :optionArray="selectorArray('usesIdler')" label="Uses Idler:" @click="setDataEvent($event, 'solverInfo', 'usesIdler')" />
					<!--  -->
					<RadioSelector test="select-idlerAttachedTo" v-if="usesIdler" :optionArray="selectorArray('idlerAttachedTo')" label="Idler Attached To:" @click="setDataEvent($event, 'solverInfo', 'idlerAttachedTo')" />
					<!--  -->
					<RadioSelector test="select-bbAttachedTo" :optionArray="selectorArray('bbAttachedTo')" label="BB Attached To:" @click="setDataEvent($event, 'solverInfo', 'bbAttachedTo')" />
				</div>
			</template>
			<!--  -->
			<!-- Kinematic Points -->
			<template v-slot:slot-2>
				<div class="padding--half">
					<InputTable tableName="points-table" :headerRow="pointTableHeader" :tableSetup="pointTableSetup" @change="pointInputEvent" />
				</div>
			</template>
			<!--  -->
			<!-- Shock and Fork Settings -->
			<template v-slot:slot-3>
				<div class="padding--half">
					<TextInputMD v-for="(item, index) in damper.key" :key="'text-md-key-damperinfo-' + index" :name="damper.label[index]" :value="bikeInfoValue(item, damper.subkey[index])" @submit="validateData($event, damper.validator[index], item, damper.subkey[index])" :test="item + '-' + damper.subkey[index]" />
					<TextInputMD test="solverInfo-bumpHeight" name="Bump Height (mm)" :value="bikeInfoValue('solverInfo', 'bumpHeight')" @submit="validateData($event, 'bumpValidate', 'solverInfo', 'bumpHeight')" />
					<!--  -->
					<RadioSelector test="select-usesPullShock" :optionArray="selectorArray('usesPullShock')" label="Uses Pull Shock:" @click="setDataEvent($event, 'solverInfo', 'usesPullShock')" />
					<RadioSelector test="select-shockLengthFixed" :optionArray="selectorArray('shockLengthFixed')" label="Fix Shock Length:" @click="setDataEvent($event, 'solverInfo', 'shockLengthFixed')" />
				</div>
			</template>
			<!--  -->
			<!-- Linkage Config -->
			<template v-slot:slot-4>
				<div class="padding">
					<RadioSelector test="select-linkageType" :optionArray="linkageSelector()" label="Linkage Type:" @click="setDataEvent($event, 'solverInfo', 'linkageType')" />
					<!--  -->
					<RadioSelector test="select-shock1AttachedTo" :optionArray="selectorArray('shock1AttachedTo')" label="Shock End 1 Attached To:" @click="setDataEvent($event, 'solverInfo', 'shock1AttachedTo')" />
					<!--  -->
					<RadioSelector test="select-shock2AttachedTo" :optionArray="selectorArray('shock2AttachedTo')" label="Shock End 2 Attached To:" @click="setDataEvent($event, 'solverInfo', 'shock2AttachedTo')" />
					<!--  -->
					<RadioSelector test="select-link4AttachedTo" v-if="isSixBar" :optionArray="selectorArray('link4AttachedTo')" label="Link 4 Attached To:" @click="setDataEvent($event, 'solverInfo', 'link4AttachedTo')" />
					<!--  -->
					<RadioSelector test="select-link5AttachedTo" v-if="isSixBar" :optionArray="selectorArray('link5AttachedTo')" label="Link 5 Attached To:" @click="setDataEvent($event, 'solverInfo', 'link5AttachedTo')" />
					<!--  -->
					<RadioSelector test="select-wheelAttachedTo" :optionArray="selectorArray('wheelAttachedTo')" label="Wheel Attached To:" @click="setDataEvent($event + 1, 'solverInfo', 'wheelAttachedTo')" />
					<!--  -->
					<!--  -->
					<RadioSelector test="select-usesBrakeArm" :optionArray="selectorArray('usesBrakeArm')" label="Uses Brake Arm:" @click="setDataEvent($event, 'solverInfo', 'usesBrakeArm')" />
					<!--  -->
					<RadioSelector test="select-brakeAttachedTo" v-if="usesBrakeArm" :optionArray="selectorArray('brakeAttachedTo')" label="Brake Arm Attached To:" @click="setDataEvent($event, 'solverInfo', 'brakeAttachedTo')" />
					<!--  -->
					<RadioSelector test="select-sliderRef" v-if="linkageType > 0" :optionArray="selectorArray('sliderRef')" label="Slider Element:" @click="setDataEvent($event, 'solverInfo', 'sliderRef')" />
				</div>
			</template>
		</CollapsibleContainer>
		<div class="multi-button-cont margin--full" v-if="isBikesLoaded">
			<ButtonStandard test="solve-button" name="Solve" :btnstyle="{width:'50%'}" :outline="true" @click="solveBike">
				<template v-slot:icon-slot>
					<IconValidate :valid="isSolved===1" :invalid="isSolved===2" :loading="isSolving" />
				</template>
			</ButtonStandard>
			<SaveBikeButton test="left-sidebar-save-button" :style="{width: '50%'}" />
		</div>
		<div v-else class="tab-link-no-load-btn-cont">
			<ButtonStandard test="no-bike-load-button" :clear="true" name="Load or Create Bike" :flex="true" @click="$emit('focus-load')" />
		</div>
	</div>
</template>
<script>
import CollapsibleContainer from "@/components/functional/CollapsibleContainer.vue";
import InputTable from "@/components/functional/InputTable.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import TextInputMD from "@/components/functional/TextInputMD.vue";
import SaveBikeButton from "@/components/levapp/SaveBikeButton.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import IconValidate from "@/components/icons/IconValidate.vue";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: "TabLinkage",
	components: {
		CollapsibleContainer,
		InputTable,
		RadioSelector,
		TextInputMD,
		SaveBikeButton,
		ButtonStandard,
		IconValidate,
	},

	data() {
		return {
			//Initialization for collapsible container
			containerContent: [{
					name: 'Bike Info',
					checked: false,
					noHighlight: true,

				},
				{
					name: 'Drivetrain Info',
					checked: false,
					noHighlight: true,

				},
				{
					name: 'Kinematic Points',
					checked: true,
					noHighlight: true,

				},
				{
					name: 'Fork and Shock Info',
					checked: false,
					noHighlight: true,

				},
				{
					name: 'Linkage Setup',
					checked: false,
					noHighlight: true,

				},
			],

			bikeInfo: {
				key: ['brand', 'modelYear', 'modelName', 'version', 'solverInfo'],
				subkey: ['', '', '', '', 'resolution'],
				label: ['Brand', 'Model Year', 'Model Name', 'Version', 'Solver Resolution (mm)'],
				validator: ['length35', 'yearValidate', 'length35', 'length10', 'floatValidate'],
			},
			driveInfo: {
				key: ['drivetrainInfo', 'drivetrainInfo', 'drivetrainInfo', 'drivetrainInfo', 'drivetrainInfo'],
				subkey: ['chainringSize', 'cassetteSize', 'idlerSize', 'tireDiameter', 'tireDiameter_front'],
				label: ['Chainring Size', 'Cassette Size', 'Idler Size', 'Rear Tire Diameter (mm)', 'Front Tire Diameter (mm)'],
				validator: ['gearValidate', 'cassetteValidate', 'gearValidate', 'tireValidate', 'tireValidate'],
			},
			damper: {
				key: ['solverInfo', 'solverInfo', 'solverInfo', 'solverInfo'],
				subkey: ['forkTravel', 'shockStroke', 'shockLength', 'shockExtLength'],
				label: ['Fork Travel', 'Shock Stroke', 'Shock Length', 'Shock Extension Length'],
				validator: ['floatValidate', 'floatValidate', 'floatValidate', 'floatValidate'],
			},

			pointTableHeader: ['', 'x', 'y'],

			//Keymod will be used to trigger a forced refresh of inputs
			//	after validation. This insures that even if the validated
			// 	value is the same as previous store value the input cell
			//	value will be reset
			keymod: 0,

			isSolving: false,
			isSolved: 0, //0 for nothing, 1 for success, 2 for failure
			solveTimer: '',
		}
	},

	mounted() {
		if (this.isMobile) {
			this.containerContent.forEach(function(item) { item.checked = false; });
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['user', 'claims', 'isMobile', 'liveMode']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },

		linkageType: function() { return this.bikeData.solverInfo.linkageType; },
		isSixBar: function() { return this.linkageType === 2 ? true : false; },
		usesIdler: function() { return this.bikeData.solverInfo.usesIdler ? true : false; },
		usesBrakeArm: function() { return this.bikeData.solverInfo.usesBrakeArm ? true : false; },

		//Initialization for point table
		pointTableSetup: function() {
			const tableArray = [];
			if (this.isBikesLoaded) {
				const names = this.getData('pointNames');

				const style = [true, true, true, true, true, false, false, false, false, false, false, false, false, false, false, false, false, false, true, true];

				if (this.linkageType == 1) {
					for (let i = 5; i < 8; i++) { style[i] = true; }
				}
				if (this.linkageType == 2) {
					for (let i = 5; i < 11; i++) { style[i] = true; }
				}

				style[11] = this.usesIdler ? true : false;
				style[12] = this.usesBrakeArm ? true : false;
				style[13] = this.usesBrakeArm ? true : false;

				for (let i = 0; i < this.bikeData.points.length; i++) {
					let row = [style[i]];
					row.unshift([Math.round(1000 * this.bikeData.points[i][0]) / 1000,
						Math.round(1000 * this.bikeData.points[i][1]) / 1000
					]);
					row.unshift(names[i]);
					if (i === 19 || i === 1 || i === 0) { row.push(true) } //Disable FC/RC/BB Input
					tableArray.push(row);
				}
			}
			return tableArray;
		},

		databaseOptions: function() {
			const options = [
				['Public', false, false],
				['Private', false, true],
				['Team', false, false],
				['Local', false, false],
			];

			//Enable public saving for admin
			if (this.claims.app_admin) {
				options[0][2] = true;
				options[2][2] = this.claims.team_id ? true : false;
			}

			//Enable team when valid
			if (this.claims.team_id) { options[2][2] = true; }
			if (this.claims.team_perm < 1) { options[2][0] = 'Team (View Only)'; }

			//Allow local for electron
			if (process.env.IS_ELECTRON) { options[3][2] = true; }

			options[this.bikeData.database][1] = true; //Set current bike active

			return options;
		},

		showDatabase: function() {
			return this.claims.app_admin || this.claims.team_id || process.env.IS_ELECTRON;
		}
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),
		...mapActions('stateViewLev', ['setError']),

		//Manage collapsible container headings
		selectCCEvent: function(index) {
			let status = !this.containerContent[index]['checked'];
			this.containerContent.forEach(function(item) { item.checked = false; });
			this.containerContent[index]['checked'] = status;
		},

		//Send store values to inputs
		bikeInfoValue: function(nameKey, subItem = '') {
			return this.getBikeData(this.currentBikeIndex, nameKey, subItem);
		},

		//Send input to store
		setDataEvent: function(value, nameKey, subItem = '') {
			let payload = {
				index: this.currentBikeIndex,
				item: nameKey,
				subItem: subItem,
				value: value,
				commit: 'PUTBIKEDATA',
			};
			this.setData(payload);
			if (this.liveMode) { this.sendAndSolve(); }
		},

		setDatabase: function(value) {
			if (value > 1 && this.claims.team_perm < 1) { return; }
			let payload = {
				index: this.currentBikeIndex,
				item: 'database',
				subItem: '',
				value: value,
				commit: 'PUTBIKEDATA',
			};
			this.setData(payload);
		},

		//Validate and send point input to store
		pointInputEvent: function(indexRow, indexColumn, value, prev) {
			if (value !== '') {
				value = parseFloat(value);
				value = isNaN(value) ? 0 : value;
				this.setData({
					item: 'points',
					value: value,
					row: indexRow,
					column: indexColumn,
					commit: 'PUTBIKEPOINTDATA',
					// notReactive: true
				});
				if (this.liveMode && value != prev) { this.sendAndSolve(); }
			}
		},

		//Initialization for linkage selector
		linkageSelector: function() {
			const selectArray = [
				['Single', false, true],
				['Four Bar', false, true],
				['Six Bar', false, true],
			];

			//Get store value
			selectArray[this.bikeData.solverInfo['linkageType']][1] = true;

			return selectArray;
		},

		//Initialization for connection selectors
		selectorArray: function(item) {
			//New functionality for non existant array
			const selectArray = [
				['Frame', false, true],
				['Link 1', false, true],
				['Link 2', false, true],
				['Link 3', false, true],
				['Link 4', false, true],
				['Link 5', false, true],
			];

			//Set selected value from store if there is a bike loaded
			selectArray[this.bikeData.solverInfo[item]][1] = 1;

			switch (item) {
				case 'shock1AttachedTo':
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false;
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false;
						}
					}
					break;

				case 'shock2AttachedTo':
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false;
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false;
						}
					}
					break;

				case 'link4AttachedTo':
					selectArray[4][2] = false; //Eliminate self select
					selectArray[5][2] = false; //Already linked to 5
					break;

				case 'link5AttachedTo':
					selectArray[4][2] = false; //Already linked to 4
					selectArray[5][2] = false; //Eliminate self select
					break;

				case 'wheelAttachedTo':
					selectArray[0][2] = false; //No frame attachement
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false; //Remove all options except link 1
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false; //Remove link4/5 as options
						}
					}
					selectArray.shift();
					break;

				case 'idlerAttachedTo':
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false;
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false;
						}
					}
					break;

				case 'bbAttachedTo':
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false;
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false;
						}
					}
					break;

				case 'usesIdler':
					selectArray[0][0] = 'No';
					selectArray[1][0] = 'Yes';
					for (let i = 2; i < 6; i++) {
						selectArray[i][2] = false;
					}
					break;

				case 'usesBrakeArm':
					selectArray[0][0] = 'No';
					selectArray[1][0] = 'Yes';
					for (let i = 2; i < 6; i++) {
						selectArray[i][2] = false;
					}
					break;

				case 'brakeAttachedTo':
					if (this.linkageType == 0) {
						for (let i = 2; i < 6; i++) {
							selectArray[i][2] = false;
						}
					} else if (this.linkageType == 1) {
						for (let i = 4; i < 6; i++) {
							selectArray[i][2] = false;
						}
					}
					break;

				case 'sliderRef':
					selectArray[0][0] = 'None';
					selectArray[1][0] = 'Link3';
					selectArray[2][0] = 'Frame';
					for (let i = 3; i < 6; i++) {
						selectArray[i][2] = false;
					}
					break;

				case 'usesPullShock':
					selectArray[0][0] = 'No';
					selectArray[1][0] = 'Yes';
					for (let i = 2; i < 6; i++) {
						selectArray[i][2] = false;
					}
					break;

				case 'shockLengthFixed':
					selectArray[0][0] = 'No';
					selectArray[1][0] = 'Yes';
					for (let i = 2; i < 6; i++) {
						selectArray[i][2] = false;
					}
					break;

				default:
					break;
			}

			return selectArray.filter(item => item[2]);
		},

		//Validators for inputs 
		validateData: function(value, check, nameKey, subItem = '') {
			if (check === 'intValidate') {
				if (value !== '') {
					value = parseInt(value)
					value = isNaN(value) ? 0 : value
				}
			}

			if (check === 'floatValidate') {
				if (value !== '') {
					value = parseFloat(value)
					value = isNaN(value) ? 0.0 : value
				}
			}

			if (check === 'gearValidate') {
				value = parseInt(value);
				value = isNaN(value) ? 0 : value;
				value = value > 70 ? 70 : value;
				value = value < 9 ? 9 : value;
			}

			if (check === 'cassetteValidate') {
				if (value !== '') {
					value = value.toString();
					let itemArr = value.split(',')
					if (itemArr.length > 10) { itemArr = itemArr.slice(0, 10) }
					value = itemArr.map(function(item) {
						item = parseInt(item)
						item = isNaN(item) ? 9 : item;
						item = item > 70 ? 70 : item;
						item = item < 9 ? 9 : item;
						return item;
					})

				}
			}

			if (check === 'tireValidate') {
				if (value !== '') {
					value = parseInt(value);
					value = isNaN(value) ? 700 : value;
					value = value > 1000 ? 1000 : value;
					value = value < 200 ? 200 : value;
				}
			}

			if (check === 'bumpValidate') {
				if (value !== '') {
					value = parseInt(value);
					value = isNaN(value) ? 100 : value;
					value = value > 250 ? 250 : value;
					value = value < 0 ? 0 : value;
				}
			}

			if (check === 'yearValidate') {
				if (value !== '') {
					value = parseInt(value);
					if (isNaN(value) || value > 2500 || value < 1900) {
						value = '2000'
					}
					value = value.toString();
				}
			}

			if (check === 'length35') {
				value = value.length > 35 ? value.substring(0, 35) : value;
			}

			if (check === 'length10') {
				value = value.length > 10 ? value.substring(0, 10) : value;
			}
			//Set value to store via function
			this.setDataEvent(value, nameKey, subItem);

			//Force refresh of input cell
			this.keymod = this.keymod === 1 ? 0 : 1;
		},

		solveBike: async function() {
			if (this.isSolving) { return; }

			this.isSolved = 0;
			clearTimeout(this.solveTimer);
			this.isSolving = true;

			const res = await this.sendAndSolve(true);

			if ('error' in res) {
				//Handle general connection issues
				if (res.status === 500) { this.setError(res.error); }

				//Handle unauthorized
				if (res.status === 401) { this.setError('You do not have authorization to access the solver resource.'); }
				this.isSolved = 2;
			} else { this.isSolved = 1; }

			const delayTime = this.isSolved === 1 ? 750 : 1500;
			this.solveTimer = setTimeout(() => {
				this.isSolving = false;
				this.isSolved = 0;
			}, delayTime);
		},
	},

}
</script>
<style>
.tab-link-no-load-btn-cont {
	width: 100%;

	padding: 5rem 1rem;
	display: flex;
}
</style>