<template>
	<div class="container-main">
		<ErrorBall v-if="!isMobile && !isElectron" />
		<aside class="sidebar" :class="{'sidebar--active' : showSidebarLeft}">
			<div class="flex-row sidebar__content">
				<LeftSidebar @focus-load="focusLoad" />
			</div>
		</aside>
		<div class="plot-container flex-1 flex-column-rev">
			<div ref="bikechips" class="bike-chip-cont">
				<LoadBikeChip />
				<template v-for="(bike, index) in bikeDataArray">
					<!-- Need UID not based on index for add/remove function -->
					<BikeChip :key="create_UUID()+index" :bikeIndex="index" />
				</template>
			</div>
			<MainPlot />
		</div>
		<aside class="sidebar" :class="{'sidebar--active' : showSidebarRight}">
			<div class="flex-row sidebar__content">
				<div class="container flex-1">
					<RightSidebar />
				</div>
			</div>
		</aside>
	</div>
</template>
<script>
import MainPlot from "@/components/levapp/MainPlot.vue";
import LeftSidebar from "@/components/levapp/LeftSidebar.vue";
import RightSidebar from "@/components/levapp/RightSidebar.vue";
import ErrorBall from "@/components/levapp/ErrorBall.vue";
import BikeChip from "@/components/levapp/BikeChip.vue";
import LoadBikeChip from "@/components/levapp/LoadBikeChip.vue";

import { mapActions, mapState } from "vuex";

export default {
	name: "Lev",

	components: {
		MainPlot,
		BikeChip,
		LoadBikeChip,
		LeftSidebar,
		RightSidebar,
		ErrorBall,
	},

	mounted() {
		//Assign API URLs only when Lev is loaded
		const payload = {
			item: 'solverURL',
			value: process.env.VUE_APP_SOLVER_URL,
			commit: 'PUTDATA',
		}
		this.$store.dispatch('stateBikeData/setData', payload);

		document.addEventListener('keyup', this.handleKeyup);
		document.addEventListener('keydown', this.handleKeydown);
	},

	beforeDestroy() {
		document.removeEventListener('keyup', this.handleKeyup);
		document.removeEventListener('keydown', this.handleKeydown);
	},

	computed: {
		...mapState('stateViewLev', ['isMobile', 'showSidebarRight', 'showSidebarLeft']),
		...mapState('stateBikeData', ['bikeDataArray']),
		isElectron: function() { return process.env.IS_ELECTRON; }
	},

	methods: {
		...mapActions('stateBikeData', ['undo', 'redo']),

		create_UUID: function() {
			var dt = new Date().getTime();
			var uuid = 'xxxxxxxx-xxxx-4x'.replace(/[xy]/g, function(c) {
				var r = (dt + Math.random() * 16) % 16 | 0;
				dt = Math.floor(dt / 16);
				return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});
			return uuid;
		},

		focusLoad: function() {
			this.$refs.bikechips.children[0].children[0].click();
		},

		handleKeyup: function(event) {
			event.preventDefault();
			if (event.ctrlKey && event.code === 'KeyZ') { this.undo(); }
			if (event.ctrlKey && event.code === 'KeyY') { this.redo(); }
		},

		handleKeydown: function() {
			if (event.ctrlKey && event.code === 'KeyZ') { event.preventDefault(); }
			if (event.ctrlKey && event.code === 'KeyY') { event.preventDefault(); }
		},
	},

};
</script>
<style>
.bike-chip-cont {
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
	min-height: 6.2rem;
}

.sidebar {
	display: block;
	width: 0rem;
	overflow: hidden;

	border: none;

	transition: .25s;
}

.sidebar--active {
	width: 30rem;
}

.sidebar__content {
	height: 100%;
	width: 30rem;
}

.plot-container {
	min-width: 25rem;
}

@media(max-width: 500px) {
	.sidebar {
		width: 100%;
	}

	.sidebar--active {
		width: 100%;
	}

	.sidebar__content {
		width: 100%;
	}
}
</style>