import { simpleDeriv } from "@/modules/fornbergDerivative.js";
import { copy } from "@/modules/utility.js"
/**
 * 
 * @param {object} _bike 
 * @param {object} _bikeView 
 * @param {object} _kinematicData 
 * @returns 
 */
export function applySpringForce(_bike, _bikeView, _kinematicData) {
    const bike = copy(_bike);
    const bikeView = copy(_bikeView);
    const kinData = copy(_kinematicData);

	const springData = bikeView.springData;
	const simpleCoil = bikeView.simpleCoil;

	const springSettings = bikeView.springSettings;
	const springType = springSettings.springType;
	const rateType = springSettings.rateType;

	//Get base spring curve
	let spring = springType === 0 ? springData : simpleCoil;

	//Do nothing for empty spring or kinematic data object
	if (!('d_shock' in kinData) || !('data' in spring)) {
		//Set default display values for when spring or kinData is invalid
		springSettings.displaySag = '-';
		springSettings.airRate = '-';
		springSettings.coilRate = '-';
		springSettings.curveTravel = 0;
		springSettings.wheelTravel = 'd_shock' in kinData ? kinData.d_wheel_vert[kinData.d_shock.length - 1] : '-';
		springSettings.shockTravel = 'd_shock' in kinData ? kinData.d_shock[kinData.d_shock.length - 1] : '-';
		return {
            springSettings,
            kinematicData: null,
        };
	}

	let isCoil = false;
	if ('basePressure' in springData && springData.basePressure === 0 ||
		springType === 1) { isCoil = true; }

	//Calculate required rear support for rider weight------------------------
	//	Based on wheelbase length and COG position----------------------------
	const geo = bike.geometry;
	const size = bike.selectedSize;
	const wheelbase = geo.fc[size] + geo.rc[size];

	const rearWeight = (1 - (geo.cogX[size] + geo.rc[size]) / wheelbase) * geo.riderWeight[size];

	//User the shortest value of springTravel or Kinemtatic travel
	let shockTravel = kinData.d_shock[kinData.d_shock.length - 1];
	springSettings.curveTravel = shockTravel;
	if (shockTravel > spring.maxTravel) { shockTravel = spring.maxTravel; }

	//Only get shock and wheel travel for the reduced shockTravel amount
	const d_shock = kinData.d_shock.filter(val => val <= shockTravel);
	const d_wheel = kinData.d_wheel_vert.slice(0, d_shock.length);

	springSettings.wheelTravel = d_wheel[d_shock.length - 1];
	springSettings.shockTravel = shockTravel;

	let sagIdx = 0; //Initialize

	//Compute spring rates----------------------------------------------------
	// Sag based calculation--------------------------------------------------
	if (rateType <= 1) {
		// Can set shock sag directly using even increment spacing
		sagIdx = Math.round(springSettings.shockSag * (d_shock.length - 1));

		//Get shock sag index for a given wheel sag percent
		if (rateType === 1) {
			//Get percentage of the max wheel travel for the found stroke length
			const sagTarget = d_wheel[d_wheel.length - 1] * springSettings.wheelSag;

			d_wheel.reduce((prev, curr, index) => {
				if (Math.abs(curr - sagTarget) < Math.abs(prev - sagTarget)) {
					sagIdx = index;
					return curr;
				} else { return prev; }
			});
		}

		const d_shock_sag = d_shock[sagIdx]; //shock displacement at sag
		const leverageSag = kinData.leverage[sagIdx]; //leverage at sag
		const reqForce = rearWeight * leverageSag; //require spring force at sag
		const baseForce = spring.data[String(d_shock_sag)][spring.spacerIndex]; //measure spring force at sag

		//Get rates for the given sag. 
		//	For coil the measured rate is without any spring so we add a rate in N/mm
		// 	For air we assume the rate is linearly proportional to the pressure
		if (isCoil) {
			const reqSpringForce = reqForce - baseForce;
			const rate = Math.round(100 * reqSpringForce / d_shock_sag) / 100;
            springSettings.coilRate = rate;
			// Vue.set(springSettings, 'coilRate', rate);
		} else {
			const forceMultiplier = reqForce / baseForce;
			const rate = Math.round(1000 * forceMultiplier * spring.basePressure) / 1000;
            springSettings.airRate = rate;
            // Vue.set(springSettings, 'airRate', rate);
		}
	}

	//CRUNCH SOME NUMBERS FOR SCIENCE---------------------------------------------
	kinData.spring_force = [];
	kinData.energy = [];
	kinData.energy[0] = 0;
	kinData.wheel_force = [];
	kinData.wheel_rate = [];

	d_shock.forEach((xIdx, index) => {
		//Calculate spring force
		if (isCoil) {
			kinData.spring_force[index] = kinData.d_shockRaw[index] * springSettings.coilRate +
				spring.data[String(xIdx)][spring.spacerIndex];
		} else {
			const multiplier = springSettings.airRate / spring.basePressure;
			kinData.spring_force[index] = multiplier * spring.data[String(xIdx)][spring.spacerIndex];
		}

		if (index !== 0) {
			let dx = kinData.d_shockRaw[index] - kinData.d_shockRaw[index - 1];

			//Energy => integral of spring force. Displacement converted from mm to meters 
			kinData.energy[index] = kinData.spring_force[index] * dx / 1000 + kinData.energy[index - 1];

			//Correction factor for spring rounding. 
			if (!isCoil) {
				let slope = (kinData.spring_force[index] - kinData.spring_force[index - 1]) / dx;
				kinData.spring_force[index] += slope * (kinData.d_shockRaw[index] - xIdx);
			}
		}

		//Calculate wheel force
		kinData.wheel_force[index] = kinData.spring_force[index] / kinData.leverage[index];
	});

	// Wheel rate => first derivative of wheel force
	kinData.wheel_rate = simpleDeriv(d_wheel, kinData.wheel_force)

	// -------------------------------------------------------------------------------


	//Get the sag and travel info for display---------------------------------
	//	Sag will be where the wheel force is closest to the rear weight-------
	kinData.wheel_force.reduce((prev, curr, index) => {
		if (Math.abs(curr - rearWeight) < Math.abs(prev - rearWeight)) {
			sagIdx = index;
			return curr;
		} else { return prev; }
	});

	//For set wheel sag or set rate display shock sag
	if (rateType !== 0) {
		springSettings.displaySag = Math.round(100 * sagIdx / kinData.wheel_force.length) / 100;
	} else {
		springSettings.displaySag = Math.round(100 * d_wheel[sagIdx] / d_wheel[kinData.wheel_force.length - 1]) / 100;
	}

    return {
        kinematicData: kinData,
        springSettings,
    }
}

/**
 * Trapezoidal integral from two vectors
 *
 * @param   {number[]} x vector of x values
 * @param   {number[]} y vector of y values
 * @returns {number[]}   integral over range
 */
 export function trapIntegral(x, y){
    // Area can be given by (a+b)/2 * h
    // Where a and b are the side lengths and h is the distance between them
    const total = [0]
    for(let i = 1; i < x.length; i++){  
        const dy = (x[i]-x[i-1])*(y[i] + y[i-1])/2 
        total[i] = total[i - 1] + dy
    }
    return total
}