<template>
	<div ref="icon" v-tooltip="tooltip" class="info-icon-cont" :style="{height: size, width: size}" @click.stop>
		<svg class="info-icon-svg" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
			<circle class="info-icon-circle" cx="250" cy="250" r="225" bx:origin="0.5 0.5"></circle>
			<circle cx="250" cy="155" r="45"></circle>
			<path d="M 251.417 202.377"></path>
			<rect x="220" y="230" width="60" height="150" rx="5" ry="5" bx:origin="0.5 0.472222"></rect>
		</svg>
	</div>
</template>
<script>
export default {
	name: 'InfoIcon',
	props: {
		size: String,
		msg: String,
	},

	data() {
		return {
			tooltip: {
				content: this.msg,
				placement: 'auto',
				boundariesElement: '.spa-cont',
			}
		}
	},
}
</script>
<style>
.info-icon-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: .35rem;

	background: transparent;

}

.info-icon-svg:hover {
	transform: scale(1.1);
}

.info-icon-svg {
	width: 100%;
	height: 100%;
	fill: var(--color-font-bg);
	transition: .3s ease;
}

.info-icon-circle {
	fill: none;
	stroke: var(--color-font-bg);
	stroke-width: 40;
}

.info-icon-bg {
	fill: var(--color-bg);
}
</style>