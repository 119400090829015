<template>
	<div ref="loadspring" class="load-spring">
		<RadioSelector :optionArray="dbSelectOptions" @click="setShort(['loadSpringDB', $event])" />
		<AutocompleteList inputLabel="Brand" :itemList="lists['brand']" @submit="filterSearch" :maxNumItems="5" v-model="brand" test="bike-chip-spring-brand"/>
		<AutocompleteList inputLabel="Model" :itemList="lists['modelName']" @submit="filterSearch" v-model="modelName" :maxNumItems="5" test="bike-chip-spring-name"/>
		<AutocompleteList inputLabel="Year" :itemList="lists['modelYear']" @submit="filterSearch" v-model="modelYear" :maxNumItems="5" test="bike-chip-spring-year"/>
		<div class="flex-row">
			<AutocompleteList inputLabel="Shock Length" :itemList="lists['shockLength']" v-model="shockLength" margin="0rem .5rem 1.5rem .2rem" @submit="filterSearch" :maxNumItems="4" test="bike-chip-spring-length" />
			<AutocompleteList inputLabel="Shock Stroke" :itemList="lists['shockStroke']" margin="0rem .2rem 1.5rem .5rem" v-model="shockStroke" @submit="filterSearch" :maxNumItems="4" test="bike-chip-spring-stroke"/>
		</div>
		<!-- <span class="load-spring__load-message">Lorem ipsum dolor sit amet.</span> -->
		<div class="multi-button-cont">
			<ButtonStandard flex="true" name="Cancel" clear="true" @click="changeState" test="bike-chip-spring-cancel"/>
			<ButtonStandard flex="true" name="Load" @click="loadSpring" test="bike-chip-spring-load">
				<template v-slot:icon-slot>
					<IconValidate :valid="searchValid && !isLoading || isLoaded === 1" :invalid="searchInvalid && !isLoading || isLoaded === 2" :loading="isLoading" />
				</template>
			</ButtonStandard>
		</div>
	</div>
</template>
<script>
import AutocompleteList from "@/components/levapp/AutocompleteList.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import RadioSelector from "@/components/functional/RadioSelector.vue";
import IconValidate from "@/components/icons/IconValidate.vue";

import { fs_loadSpring } from "@/firebaseConfig.js";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
	name: 'LoadSpring',

	props: {
		bikeIndex: Number,
	},

	components: {
		AutocompleteList,
		ButtonStandard,
		IconValidate,
		RadioSelector,
	},

	data() {
		return {
			brand: '',
			modelYear: '',
			modelName: '',
			shockStroke: '',
			shockLength: '',

			lists: {
				brand: [],
				modelYear: [],
				modelName: [],
				shockStroke: [],
				shockLength: [],
			},

			invalidLoad: false,
			isLoading: false,
			isLoaded: 0,
			loadingTimer: '',
		}
	},

	watch: {
		completeSpringList: {
			immediate: true,
			handler() {
				Object.keys(this.lists).forEach((key) => {
					this.setList(key);
				});
			}
		},
	},

	computed: {
		...mapGetters('stateViewLev', ['get_fs_database']),
		...mapState('stateViewLev', ['user', 'claims', 'loadSpringDB',
			'publicSpringList', 'privateSpringList', 'teamSpringList',
		]),

		completeSpringList: function() {
			if (this.loadSpringDB === 0) { return this.publicSpringList; }
			if (this.loadSpringDB === 1) { return this.privateSpringList; }
			if (this.loadSpringDB === 2) { return this.teamSpringList; }

			return this.privateSpringList; //Default. Should not be needed.
		},

		fs_location: function() { return this.get_fs_database(this.loadSpringDB); },

		dbSelectOptions: function() {
			const optionArray = [
				['Public', false, true],
				['Private', false, true],
				['Team', false, false],
			];

			if (this.claims.team_id) { optionArray[2][2] = true; }

			optionArray[this.loadSpringDB][1] = true;

			return optionArray;
		},

		searchValid: function() { return this.reducedSpringList.length === 1 ? true : false; },

		searchInvalid: function() {
			return this.reducedSpringList.length < 1 || this.invalidLoad ? true : false;
		},

		reducedSpringList: function() {
			let reducedList = [...this.completeSpringList];
			const localParams = {
				brand: this.brand,
				modelName: this.modelName,
				modelYear: this.modelYear,
				shockLength: this.shockLength,
				shockStroke: this.shockStroke,
			}

			Object.keys(this.lists).forEach((key) => {
				reducedList = reducedList.filter((item) => {
					if (localParams[key] === '' || String(item[key]) === localParams[key]) {
						return item;
					}
				});
			});

			return reducedList;
		},
	},

	methods: {
		...mapActions('stateViewLev', ['setError', 'setShort']),
		...mapActions('stateBikeData', ['setData']),

		setList: function(key) {
			this.lists[key] = [...new Set(this.reducedSpringList.map(e => String(e[key])))];
		},

		filterSearch: function() {
			this.invalidLoad = false;

			Object.keys(this.lists).forEach((key) => {
				this.setList(key);
			});
		},

		loadSpring: async function() {
			if (this.isLoading) { return; }
			// clearTimeout(this.loadingTimer);

			this.invalidLoad = false;
			if (this.reducedSpringList.length !== 1) {
				this.$nextTick(() => {
					this.invalidLoad = true;
				});
				return;
			}

			this.isLoading = true;
			await new Promise(r => setTimeout(r, 200)); //Slight delay for SVG animation

			const searchId = this.reducedSpringList[0].id;

			const loadRes = await fs_loadSpring(searchId, this.fs_location);

			if (loadRes.error) {
				this.setError('Server Error. Please check connection.');
				this.isLoaded = 2;
				this.loadingTimer = setTimeout(this.clearLoadButton, 1500);
				return;
			}

			//Store spring with bike view data and close panel
			this.setData({
				commit: 'PUTBIKEVIEWDATA',
				index: this.bikeIndex,
				item: 'springData',
				value: loadRes.springObj,
			});

			this.isLoaded = 1;
			setTimeout(this.clearLoadButton, 1000);
			setTimeout(this.changeState, 1000);
		},

		changeState: function() {
			this.$emit('change-state');
		},

		clearLoadButton: function() {
			this.isLoading = false;
			this.isLoaded = 0;
		}
	}
}
</script>
<style>
.load-spring {
	flex: 1;
	margin: 1rem;
}

.load-spring__load-message {
	display: block;
	margin: 0 .25rem 1rem .25rem;
	text-align: left;
}

.load-spring__validate-icon {
	position: absolute;
	right: 0rem;
	/*bottom: -.1rem;*/
	display: flex;
	height: 1rem;
	width: 1rem;
	margin: 1rem;
	stroke: var(--color-font-primary);

	transform: scale(1);
	transition-delay: .5s;

	transition: .5s;
}

.invalid-load {
	animation: invalid .5s;
	animation-delay: .3s;
}

@keyframes invalid {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.3) rotate(15deg);
	}

	100% {
		transform: scale(1);
	}
}
</style>