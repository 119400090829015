<template>
	<TabContainer :tabs="tabSetup" :name="'right-sidebar'" @event-tab-click="selectTabEvent" :border="border">
		<template v-slot:slot-0>
			<TabPlotSelection />
		</template>
		<template v-slot:slot-1>
			<TabBikePlotSettings />
		</template>
		<template v-slot:slot-2>
			<TabDataTools />
		</template>
	</TabContainer>
</template>
<script>
import TabContainer from "@/components/functional/TabContainer.vue";
import TabPlotSelection from "@/components/levapp/TabPlotSelection.vue";
import TabDataTools from "@/components/levapp/TabDataTools.vue";
import TabBikePlotSettings from "@/components/levapp/TabBikePlotSettings.vue";
import { mapActions, mapState } from 'vuex';

export default {
	name: "RightSidebar",
	components: {
		TabContainer,
		TabPlotSelection,
		TabBikePlotSettings,
		TabDataTools,
	},

	data() {
		return {
			tabIdx: 0,

			border: {
				borderLeft: '.1rem solid var(--color-bg-hover)'
			}
		}; //endReturn
	},

	computed: {
		...mapState('stateViewLev', ['isMobile']),

		tabSetup: function() {
			if (this.isMobile) {
				const tabs = [{
						name: 'Kinematics',
						checked: false,
					},
					{
						name: 'Bike Plot',
						checked: false,
					},
				];
				const index = this.isMobile && this.tabIdx === 2 ? 0 : this.tabIdx;
				tabs[index].checked = true;
				return tabs;
			} else {
				const tabs = [{
						name: 'Kinematics',
						checked: false,
					},
					{
						name: 'Bike Plot',
						checked: false,
					},
					{
						name: 'Data Tools',
						checked: false,
					},
				];
				tabs[this.tabIdx].checked = true;
				return tabs;
			}
		}
	},

	methods: {
		...mapActions('stateViewLev', ['setViewData']),

		selectTabEvent: function(index) {
			this.tabIdx = index;

			//Set main plot according to selection
			if (index === 0 || index === 1) {
				this.setViewData({
					item: 'mainPlotState',
					value: index,
				});
			}
		},
	},
};
</script>
<style>
.li-header {
	width: 100%;
	padding: .2rem 1rem;
	transition: .3s;
	font-family: var(--font-alt);
	font-weight: 700;
	font-size: 1.1rem;
	height: 3rem;
	line-height: 2.5rem;
	text-align: left;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid var(--color-bg-secondary-light);
	color: var(--color-font-bg);
}
</style>