import { circleIntersect, rotMovePoint } from "@/modules/computeLinkage";

function degToRad(deg) {
	return deg * Math.PI / 180;
}

export function computeBodyLengths(height) {

	//Segment lengths from Drillis and Contini (1966)
	return {
		ankle: .039 * height,
		lowerLeg: .246 * height, //.285h - .039h
		upperLeg: .245 * height, //.530h - .285h
		torso: .288 * height, //.818h - .530h 
		neck: .052 * height, //.870h - .818h
		head: .130 * height,
		upperArm: .186 * height,
		lowerArm: .146 * height,
		hand: .5 * .108 * height, // Use half of hand length for bar wrap
		foot: .075 * height,

	}
}

export function bodyPoints(height, handPos) {
	const body = computeBodyLengths(height);

	const l_crank = 170;

	const foot_f = [l_crank - body.foot, 10 + body.ankle];
	const foot_r = [-l_crank - body.foot, -10 + body.ankle];

	console.log('OK1')
	//Create line segment along x-axis length of lower leg then rotate up and move to foot
	const knee_f = rotMovePoint([body.lowerLeg, 0], [0, 0], degToRad(79), foot_f);
	const knee_r = rotMovePoint([body.lowerLeg, 0], [0, 0], degToRad(67), foot_r);

	console.log('OK')
	//Get hip from two knee points and circle top reference
	const hip = circleIntersect(knee_f, knee_r, body.upperLeg, body.upperLeg, [knee_r[0], knee_r[1] + body.upperLeg]);

	//Consider arm bend in total side length
	const elbowBend = degToRad(15);
	const totalArm = body.upperArm * Math.cos(elbowBend) + body.lowerArm + body.hand;
	const shoulder = circleIntersect(hip, handPos, body.torso, totalArm, [hip[0], hip[1] + body.torso]);

	const elbow = circleIntersect(shoulder, handPos, body.upperArm,
		body.lowerArm + body.hand, [shoulder[0], shoulder[1] - body.upperArm]);

	const neck = [0, 0];
	const head_center = [0, 0];

	return {
		foot_f,
		foot_r,
		knee_f,
		knee_r,
		hip,
		shoulder,
		elbow,
		neck,
		head_center,
	}
}


// export function computeCOG(height, weight, handpos) {

// 	segmentWeights = {
// 		headNeck: .081,
// 		torso: .497,
// 		upperArm: .028,
// 		lowerArm: .016,
// 		hand: .006,
// 		upperLeg: .1,
// 		lowerLeg: .0465,
// 		foot: .0145,
// 	}
// }