<template>
	<div v-if="isBikesLoaded" class="fill no-overflow flex-column">
		<div class="padding">
			<ListSelector :itemList="chipNameList" :value="currentChipIndex" @input="setChip" />
			<div class="multi-button-cont">
				<ButtonStandard name="New" @click="newChip" :flex="true" />
				<ButtonStandard v-if="!isMobile" name="Geo Analysis" @click="openModal" :flex="true" />
			</div>
			<p v-if="chipSolutionError" class="tab-flipchip-err-msg margin--top">Invalid Chip Configuration</p>
		</div>
		<FlipChipAdjust />
		<div class="padding flex-row" style="padding-top: 3rem">
			<ButtonStandard name="Delete Chip" @click="deleteChip" :error="true" :outline="true" :flex="true" />
		</div>
		<!--  -->
		<!--  -->
		<!-- Modal to create new flip chip configuration -->
		<ModalWindow :show="showModal" @close="showModal = !showModal" :headerStyle="modalHeader" :maxWidth="bikeData.geometry.reach.length*30 + 44 + 'rem'" minWidth="50rem">
			<template v-slot:body>
				<div class="flex-row fill no-overflow">
					<div style="max-width: 30rem; min-width: 30rem;">
						<div class="flex-row">
							<ListSelector :itemList="chipNameList" :label="'Selected Chip'" :value="currentChipIndex" @input="setChip" margin="1.5rem 1rem 0 1rem" />
							<ButtonStandard name="New" @click="newChip" :btnstyle="selectorBtn" />
						</div>
						<p v-if="chipSolutionError" class="tab-flipchip-err-msg">Invalid Chip Configuration</p>
						<FlipChipAdjust />
					</div>
					<div class="flex-1 flex-row no-overflow" style="min-height: 50rem; margin-left: 1rem">
						<ChipGeoTable />
					</div>
				</div>
			</template>
		</ModalWindow>
	</div>
	<div v-else class="tab-link-no-load-btn-cont">
		<ButtonStandard :clear="true" name="Load or Create Bike" :flex="true" @click="$emit('focus-load')" />
	</div>
</template>
<script>
import FlipChipAdjust from "@/components/levapp/FlipChipAdjust.vue";
import ChipGeoTable from "@/components/levapp/ChipGeoTable.vue";
import ButtonStandard from "@/components/functional/ButtonStandard.vue";
import ListSelector from "@/components/functional/ListSelector.vue";
import ModalWindow from "@/components/ModalWindow.vue";

import { ChipConfig } from "@/modules/defaultBike.js";
import { copy } from "@/modules/utility.js";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: "TabFlipChip",
	components: {
		FlipChipAdjust,
		ChipGeoTable,
		ModalWindow,
		ButtonStandard,
		ListSelector,
	},

	data() {
		return {
			showModal: false,
			modalHeader: {
				padding: 0,
				zIndex: '2',
			},

			selectorBtn: {
				maxWidth: '7rem',
				marginTop: '1.5rem',
				marginRight: '1rem'
			},
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateBikeData', ['pointNames']),
		...mapState('stateViewLev', ['isMobile', 'liveMode']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },
		chipConfigs: function() { return this.bikeData.solverInfo.chipConfigs; },
		currentChipIndex: function() { return this.bikeData.solverInfo.selectedChip; },
		chipData: function() { return this.chipConfigs[this.currentChipIndex]; },

		chipNameList: function() { return this.chipConfigs.map(item => item.name); },
		chipSolutionError: function() { return this.bikeData.solverInfo.chipSolution.error; },
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),
		...mapActions('stateViewLev', ['setError']),

		openModal: function() { this.showModal = this.isMobile ? false : true; },

		//Set the selected chip number in store to new selection and solve
		setChip: function(chipNumber, reactive = true) {
			let payload = {
				index: this.currentBikeIndex,
				item: 'solverInfo',
				subItem: 'selectedChip',
				value: chipNumber,
				commit: 'PUTBIKEDATA',
				noRecord: !reactive, //Option to disable for chipDeletion
			};

			this.setData(payload);
			if (this.liveMode && reactive) { this.sendAndSolve(); } //Don't solve for chip delete
		},

		newChip: function() {
			const chipNumber = this.chipConfigs.length;
			this.chipConfigs.push(new ChipConfig('Chip - ' + chipNumber))
			this.setChipArray(this.chipConfigs)
			this.setChip(chipNumber)
		},

		//Set entire chip array (create new chip)
		setChipArray: function(chipArray) {
			let payload = {
				index: this.currentBikeIndex,
				item: 'solverInfo',
				subItem: 'chipConfigs',
				value: chipArray,
				commit: 'PUTBIKEDATA',
			};
			this.setData(payload);
			if (this.liveMode) { this.sendAndSolve(); }
		},

		//Splice item from chip array and set to bike
		deleteChip: function() {
			console.log('deleting chip')
			if (this.currentChipIndex === 0) {
				this.setError('Cannot delete the default chip');
				return;
			}
			const newChips = copy(this.chipConfigs)
			newChips.splice(this.currentChipIndex, 1);
			const newIndex = this.currentChipIndex === 0 ? 0 : this.currentChipIndex - 1;

			this.setChip(newIndex, false);
			this.setChipArray(newChips);
		}
	}
}
</script>
<style>
.tab-flipchip-err-msg {
	font-weight: 700;
	color: var(--color-error);

	text-align: center;
}
</style>