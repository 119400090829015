<template>
	<div class="fill no-overflow">
		<InputTable :tableSetup="tableSetup" :headerRow="headerConfig" labelWidth="14rem" @change="inputEvent" />
	</div>
</template>
<script>
import InputTable from "@/components/functional/InputTable.vue";

import { mapGetters, mapActions, mapState } from "vuex";

export default {
	name: 'ChipGeoTable',
	components: {
		InputTable,
	},

	data() {
		return {
			geoRounding: 10,

			nameKeys: ['reach', 'hta', 'htl',
				'forkLength', 'offset', 'trail', 'lowerHeadset',
				'stack', 'htt', 'fc', 'rc', 'cs', 'wheelbase',
				'bbDrop', 'bbOffset', 'bbHeight',
				'sta_eff', 'sta_act', 'stl',
				'nsh', 'insertion',
				'upperHeadset', 'stemLength', 'stemSpacers', 'barHeight', 'seatedFit'
			],

			displayNames: ['Reach', 'Head Tube Angle', 'Head Tube Length',
				'Fork Length', 'Fork Offset', 'Trail', 'Lower Headset Stack',
				'Stack', 'Horz. Top Tube', 'Front Center', 'Rear Center', 'Chainstay Length', 'Wheelbase',
				'BB Drop', 'BB Horz. Offset', 'BB Height',
				'Seat Tube Angle Eff.', 'Seat Tube Angle Act.', 'Seat Tube Length',
				'Nom. Saddle Height', 'Seatpost Insertion',
				'Upper Headset Stack', 'Stem Length', 'Stem Spacers', 'Bar Height', 'Seated Fit',
			],

			//Sets enabled or disabled for the input
			activeState: [false, false, false,
				false, false, true, false,
				true, true, true, false, true, true,
				false, false, true,
				false, false, false,
				false, false,
				false, false, false, false, true,
			],

			setup: {
				name: 'bike-db-table',
				liveHeader: false,
				useSelectors: false,
				altColor: true,
				headerState: true,
				labelColumn: true,
			},
		}
	},

	computed: {
		...mapGetters('stateBikeData', ['getData', 'getBikeData']),
		...mapState('stateViewLev', ['isMobile', 'liveMode']),

		currentBikeIndex: function() { return this.getData('selectedBikeIndex') },
		bikeArrayLength: function() { return this.getData('bikeDataArray').length },
		isBikesLoaded: function() { return this.bikeArrayLength > 0 ? true : false },
		bikeData: function() { return this.getBikeData(this.currentBikeIndex) },
		chipSolution: function() { return this.bikeData.solverInfo.chipSolution; },

		tableSetup: function() {
			// ['row name', 'value array for row', 'show/hide row', 'disable input']
			const geo = this.bikeData.geometry;

			return this.nameKeys.map((key, keyIdx) => {
				const value = [];
				const enable = [];
				geo[key].forEach((geo_value, size) => {
					value.push(Math.round(this.geoRounding * geo_value) / this.geoRounding);
					value.push(Math.round(this.geoRounding * this.chipSolution.geometry[key][size]) / this.geoRounding);
					enable.push(false)
					enable.push(true)
				});
				const row = [this.displayNames[keyIdx], value, true, this.activeState[keyIdx], enable];
				return row;
			});
		},

		headerConfig: function() {
			const header = [''];
			this.bikeData.geometry.reach.forEach((reach, size) => {
				header.push('S' + size + ' Nom.');
				header.push('S' + size + ' Chip');
			});

			return header;
		}
	},

	methods: {
		...mapActions('stateBikeData', ['setData', 'sendAndSolve']),

		inputEvent: function(row, column, value, prev) {
			//Prevent non-numeric values
			value = value === '' ? 0 : value; //Default value
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value; //Check for invalid number

			const size = Math.floor(parseInt(column) / 2);

			//Set the geometry item 
			this.setData({
				item: 'geometry',
				value: value,
				row: this.nameKeys[row], //Which geometry item is being changed
				column: size, //Which column of the geometry item array to put the value in
				commit: 'PUTBIKEPOINTDATA',
				// notReactive: true
			});

			//Only solve if value has changed
			if (this.liveMode && value != prev) { this.sendAndSolve(); }
		},
	},
}
</script>
<style>
</style>